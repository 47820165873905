import React, { useState, useEffect } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLoginCheck } from "../../hooks/useLoginCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes, faCheck } from "@fortawesome/pro-solid-svg-icons";
import Breadcrumbs from "../../components/Breadcrumbs";
import "../../styles/scss/managementFreeCustomerList.scss";

function FreeCustomerListPage() {
  useLoginCheck();

  const week = ["日", "月", "火", "水", "木", "金", "土"];

  const [customers, setCustomers] = useState([]); // 体験者リスト
  const [weekdayStudios, setWeekdayStudios] = useState([]); // 曜日ごとのスタジオ
  const [todayLessons, setTodayLessons] = useState([]); // 本日日付かつ体験者が居るレッスンを持つスタジオ名の配列
  // const [cancelLessons, setCancelLessons] = useState([]);
  // const [overlayOpen, setOverlayOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null); // 選択したユーザー
  const [selectedStudios, setSelectedStudios] = useState([]); // 選択した曜日ごとのスタジオ
  // const [newDate, setNewDate] = useState("");
  const [todayWeekday, setTodayWeekday] = useState(null); // 今日の曜日
  const [changeDates, setChangeDates] = useState(null); // 体験変更できる候補日
  const [selectedDate, setSelectedDate] = useState(null); // 選択した変更候補日

  const breadcrumbs = [
    { title: "体験申込者一覧", path: `/management/free-customer-list` },
  ];

  // 体験顧客の取得
  const fetchCustomersData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_free_customers/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studios: selectedStudios,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();

      setCustomers(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  useEffect(() => {
    fetchCustomersData();
  }, [selectedStudios]);

  // 絞り込みのボタン取得
  useEffect(() => {
    const fetchGetButton = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/get_weekday_studios/`);
        if (!response.ok) {
          throw new Error("ネットワークレスポンスが異常です。");
        }
        const data = await response.json();

        setWeekdayStudios(data.studios);
        setTodayLessons(data.today_lesson);
        // setCancelLessons(data.cancel_lesson);
        setTodayWeekday(data.today_weekday);
      } catch (error) {
        console.error("データの取得中にエラーが発生しました:", error);
      }
    };

    fetchGetButton();
  }, []);

  // 体験を変更できる候補日を作成
  useEffect(() => {
    if (selectedCustomer === null) return;

    // 体験を変更できる候補日を作成
    const generateWeeklyDates = (startDate, weeks) => {
      const dates = [];
      let currentDate = new Date(startDate);

      // 日付のフォーマット
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      // 5週目かどうかチェック
      const isFifthWeek = (date) => {
        const weekOfMonth = Math.ceil(date.getDate() / 7);
        return weekOfMonth > 4;
      };

      // 一つ目は当日
      dates.push(formatDate(currentDate));
      setSelectedDate(formatDate(currentDate));

      for (let i = 1; i < weeks; i++) {
        currentDate.setDate(currentDate.getDate() + 7);
        // 5週目でなければ追加
        if (!isFifthWeek(currentDate)) {
          dates.push(formatDate(currentDate));
        }
      }

      return dates;
    };

    const startDate = new Date(selectedCustomer.date);
    const weeklyDates = generateWeeklyDates(startDate, 8); // 2ヶ月分の週ごとの日付を生成

    setChangeDates(weeklyDates);
  }, [selectedCustomer]);

  // 予約日の変更
  const handleDateChange = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/change_trial_date/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: selectedCustomer.id,
          new_date: selectedDate,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const updatedCustomer = await response.json(); // APIからのレスポンスを取得

      // customersステートを更新
      setCustomers(
        customers.map((customer) =>
          customer.id === updatedCustomer.id ? updatedCustomer : customer
        )
      );

      alert(`${selectedCustomer.name}様の予約日を変更しました`);
      // setOverlayOpen(false);
    } catch (error) {
      console.error("データの送信中にエラーが発生しました:", error);
    }
  };

  // 体験のキャンセル
  const handleDelete = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/cancel_trial_customer/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: selectedCustomer.id,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json(); // APIからのレスポンスを取得

      // customersステートから削除した顧客を削除
      setCustomers(
        customers.filter((customer) => customer.id !== selectedCustomer.id)
      );

      setSelectedCustomer(null);

      alert(`${selectedCustomer.name}様の予約をキャンセルしました`);
      // setOverlayOpen(false);
    } catch (error) {
      console.error("データの送信中にエラーが発生しました:", error);
    }
  };

  // /////////////////////////////////
  // ↑旧
  // ↓新
  /////////////////////////////

  // レイアウト
  // レイアウトを設定するためのstate
  const [layout, setLayout] = React.useState("1");

  // レイアウト変更の関数
  const changeLayout = (newLayout) => {
    setLayout(newLayout);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // alert("クリップボードにコピーしました: " + text);
      })
      .catch((err) => {
        // console.error("クリップボードへのコピーに失敗しました: ", err);
      });
  };

  // 日付をフォーマットする関数
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}年${month}月${day}日`;
  };

  const FreecustomerList = () => {
    return (
      <div className="studentList">
        {/* 検索 */}
        <div className="serchForm">
          <label className="form icon" htmlFor="">
            <i>
              <FontAwesomeIcon icon={faSearch} />
            </i>
            <input type="search" />
          </label>
        </div>

        {/* 生徒一覧 */}
        <div className="List">
          <div className="gridList">
            <ul className="ttl">
              <li>ID</li>
              <li>名前</li>
              <li>年齢</li>
              <li>予約日</li>
              <li>店舗</li>
              <li>レッスン</li>
              <li>申込日</li>
              <li>メール</li>
              <li>郵便番号</li>
              <li>電話</li>
              <li>交通手段</li>
              <li>メッセージ</li>
            </ul>
            {customers.map((customer) => (
              <ul
                className="listContents"
                key={customer.id}
                onClick={() => {
                  setSelectedCustomer(customer);
                }}
              >
                <>
                  <li onClick={() => handleCopyToClipboard(customer.id)}>
                    {customer.id}
                  </li>
                  <li onClick={() => handleCopyToClipboard(customer.name)}>
                    {customer.name}
                  </li>
                  <li onClick={() => handleCopyToClipboard(customer.age)}>
                    {customer.age}
                  </li>
                  <li onClick={() => handleCopyToClipboard(customer.date)}>
                    {customer.date}
                  </li>
                  <li onClick={() => handleCopyToClipboard(customer.studio)}>
                    {customer.studio}
                  </li>
                  <li onClick={() => handleCopyToClipboard(customer.lesson)}>
                    {customer.lesson}
                  </li>
                  <li
                    onClick={() => handleCopyToClipboard(customer.created_at)}
                  >
                    {customer.created_at}
                  </li>
                  <li onClick={() => handleCopyToClipboard(customer.mail)}>
                    {customer.mail}
                  </li>
                  <li onClick={() => handleCopyToClipboard(customer.postcode)}>
                    {customer.postcode}
                  </li>
                  <li onClick={() => handleCopyToClipboard(customer.tel)}>
                    {customer.tel}
                  </li>
                  <li onClick={() => handleCopyToClipboard(customer.access)}>
                    {customer.access}
                  </li>
                  <li
                    id="anchor"
                    onClick={() => handleCopyToClipboard(customer.message)}
                  >
                    {customer.message}
                  </li>
                </>
              </ul>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const StudioList = () => {
    return (
      <div className="studioList">
        {Object.entries(weekdayStudios).map(([key, studios]) => (
          <React.Fragment key={key}>
            <p>{key}</p>
            <ul>
              {studios.map((studio) => (
                <li key={studio}>
                  {/* .onで予約あり / .activeで開いてるスタジオ */}
                  <button
                    className={`type-1 ${
                      selectedStudios?.some(
                        (item) => item.weekday === key && item.studio === studio
                      )
                        ? "active"
                        : ""
                    } ${
                      todayWeekday === key && todayLessons.includes(studio)
                        ? "on"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedStudios((prevSelectedStudios) => {
                        const newSelection = { weekday: key, studio: studio };
                        const exists = prevSelectedStudios.some(
                          (item) =>
                            item.weekday === key && item.studio === studio
                        );
                        if (exists) {
                          return prevSelectedStudios.filter(
                            (item) =>
                              item.weekday !== key || item.studio !== studio
                          );
                        } else {
                          return [...prevSelectedStudios, newSelection];
                        }
                      });
                    }}
                  >
                    {studio}校
                  </button>
                </li>
              ))}
            </ul>
          </React.Fragment>
        ))}
      </div>
    );
  };

  const FreeCustomerEdit = () => {
    return (
      <>
        <div className="FreecustomerEdit">
          <button
            className="closeBtn"
            onClick={() => {
              setSelectedCustomer(null);
            }}
          >
            <i>
              <FontAwesomeIcon icon={faTimes} />
            </i>
          </button>
          <p className="contentTtl">体験者情報</p>
          <ul className="FreeCusList">
            <li>ID</li>
            <li>{selectedCustomer.id}</li>
            <li>名前</li>
            <li>{selectedCustomer.name}</li>
            <li>年齢</li>
            <li>{selectedCustomer.age}</li>
            <li>予約日</li>
            <li>{selectedCustomer.date}</li>
            <li>店舗</li>
            <li>{selectedCustomer.studio}</li>
            <li>レッスン</li>
            <li>{selectedCustomer.lesson}</li>
            <li>申込日</li>
            <li>{selectedCustomer.created_at}</li>
            <li>メール</li>
            <li>{selectedCustomer.mail}</li>
            <li>郵便番号</li>
            <li>{selectedCustomer.postcode}</li>
            <li>電話</li>
            <li>{selectedCustomer.tel}</li>
            <li>交通手段</li>
            <li>{selectedCustomer.access}</li>
            <li>メッセージ</li>
            <li>{selectedCustomer.message}</li>
          </ul>
          <p className="contentTtl">日時の編集</p>
          <ul className="dateList">
            {changeDates?.map((changeDate) => {
              const formattedDate = formatDate(new Date(changeDate));
              return (
                <li
                  key={changeDate}
                  className={`type-2 ${
                    selectedDate === changeDate ? "on" : ""
                  }`}
                >
                  <button
                    className="type-2"
                    onClick={() => setSelectedDate(changeDate)}
                  >
                    {formattedDate}
                    {selectedDate === changeDate && (
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                    )}
                  </button>
                </li>
              );
            })}
            <li className="type-2 submit">
              <button
                type="submit"
                className="type-2"
                onClick={() => {
                  handleDateChange();
                }}
              >
                予約日を更新する
              </button>
            </li>
          </ul>
          <p className="contentTtl">予約の削除</p>
          <ul className="delete">
            <li className="type-2 deleteBtn">
              <button
                className="type-2 b"
                onClick={() => {
                  handleDelete();
                }}
              >
                {formatDate(selectedCustomer.date)}
              </button>
            </li>
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout}`}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <FreecustomerList />
      </div>
      <div
        className={`content-grid grid-3 layout-type-${layout} FreeCustomerEdit-wrap edit`}
      >
        {selectedCustomer === null ? <StudioList /> : <FreeCustomerEdit />}
      </div>
    </>
  );
}

export default FreeCustomerListPage;
