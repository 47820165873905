import React, { useState, useEffect, useCallback, memo } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Overlay from "../../../components/overlay";
import "../../../styles/scss/website/faqEdit.scss";
import LibraryWindow from "../../../components/website/LibraryWindow";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemType = "IMAGE";

const Image = ({ id, src, index, moveImage }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { id, index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item) => {
      if (item.index !== index) {
        moveImage(item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <li ref={(node) => ref(drop(node))} style={{ cursor: "move" }}>
      <img
        src={src}
        alt="Studio Image"
        height="125"
        width="125"
        onClick={() => {
          moveImage(index, -1); // -1を指定して削除を表現
        }}
      />
    </li>
  );
};

const FaqEditContent = memo(
  ({
    handleInputChange,
    message,
    newMultipleImages,
    setNewMultipleImages,
    uploadImages,
    setUploadImages,
    setIsUploaded,
    categories,
    commonQuestion,
    setIsOpen,
  }) => {
    useEffect(() => {
      handleImageSubmit();
    }, [uploadImages]);

    // 画像をAPIに送信（アップロード）
    const handleImageSubmit = async () => {
      if (uploadImages.length === 0) {
        return;
      }
      setIsOpen(1);
      const formData = new FormData();

      uploadImages.forEach((image) => {
        formData.append("images", image);
      });

      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/image_upload/`, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);

        setNewMultipleImages((prevImages) => [...prevImages, ...data.images]);

        setIsUploaded(true);
        alert("アップロードが完了しました");
        setIsOpen(0);
      } catch (error) {
        alert("登録に失敗しました");
        setIsOpen(0);
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    // stateに画像をセット
    const handleImageChange = (event) => {
      setUploadImages([...event.target.files]);
    };

    const moveImage = (fromIndex, toIndex) => {
      setNewMultipleImages((prevImages) => {
        const updatedImages = [...prevImages];
        if (toIndex === -1) {
          // 画像を削除
          updatedImages.splice(fromIndex, 1);
        } else {
          const [movedImage] = updatedImages.splice(fromIndex, 1);
          updatedImages.splice(toIndex, 0, movedImage);
        }
        return updatedImages;
      });
    };

    return (
      <>
        <div className="List faqEditList">
          {/* フォーム */}
          <ul className="form1">
            {/*  */}
            <li>
              <span>カテゴリー</span>
              <label className="form">
                <select
                  name="category_id"
                  value={commonQuestion?.category_id}
                  onChange={handleInputChange}
                >
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.text}
                    </option>
                  ))}
                </select>
              </label>
            </li>
            {/*  */}
            <li>
              <span>質問</span>
              <label className="form">
                <input
                  type="text"
                  name="question"
                  value={commonQuestion?.question}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            {/*  */}
            <li className="textarea">
              <span>回答</span>
              <label className="form">
                <textarea
                  name="answer"
                  value={commonQuestion?.answer}
                  onChange={handleInputChange}
                ></textarea>
              </label>
            </li>
          </ul>

          <div className="tabArea" style={{ gridColumn: "span 6" }}>
            {/* 画像 */}
            <div className="tab-content tab1">
              <p className="ttl">画像</p>
              <DndProvider backend={HTML5Backend}>
                <ul className="imgList">
                  {newMultipleImages &&
                    newMultipleImages.map((image, index) => (
                      <Image
                        key={image.id}
                        id={image.id}
                        src={image.url}
                        index={index}
                        moveImage={moveImage}
                      />
                    ))}
                </ul>
              </DndProvider>

              {/* アップロード */}
              <ul className="btnList">
                <li>
                  <button
                    className="type-2"
                    onClick={() =>
                      document.getElementById("uploadInput").click()
                    }
                  >
                    画像をアップロード
                  </button>
                  <input
                    id="uploadInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    multiple={true}
                  />
                </li>
              </ul>
            </div>
          </div>

          {/* メッセージ */}
          {message && (
            <div className="message success">
              <p>{message}</p>
              <Link to={`https://rudofactory.jp/qa`} target="_blank">
                <button className="type-2">ページを見る</button>
              </Link>
            </div>
          )}
        </div>
      </>
    );
  }
);

function FaqEditPage() {
  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [newMultipleImages, setNewMultipleImages] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [layout, setLayout] = React.useState("2");

  const [categories, setCategories] = useState([]);
  const [commonQuestion, setCommonQuestion] = useState(null);
  const [isOpen, setIsOpen] = useState(0); // オーバーレイの表示/非表示

  // よくある質問の取得
  const fetchCommonQuestionData = useCallback(async () => {
    if (!id) return;
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_common_question/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setCommonQuestion(data);
      setNewMultipleImages(data.images);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [id]);

  // よくある質問の取得
  useEffect(() => {
    fetchCommonQuestionData();
  }, [fetchCommonQuestionData]);

  // カテゴリーリストの取得
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(
          `${apiUrl}/api/get_question_category_list/`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("カテゴリーの取得に失敗しました", error);
      }
    };

    fetchCategories();
  }, []);

  const breadcrumbs = [
    { title: "よくある質問一覧", path: `/website/faq-list` },
    {
      title: "よくある質問詳細",
      path: `/website/faq-edit/${id}`,
    },
  ];

  const tabs = [
    // { text: "よくある質問画像", type: "single" },
    { text: "VISUAL", type: "multiple" },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  // 入力フォーム変更
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCommonQuestion((prevCommonQuestion) => ({
      ...prevCommonQuestion,
      [name]: value,
    }));
  };

  // よくある質問情報を更新
  const handleFaqDataSubmit = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const imageIds = newMultipleImages
        ? newMultipleImages.map((image) => image.id)
        : null;
      const response = await fetch(`${apiUrl}/api/update_common_question/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          faq_id: id,
          question: commonQuestion.question,
          answer: commonQuestion.answer,
          category_id: commonQuestion.category_id,
          image_ids: imageIds,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      setMessage(data.message);
      fetchCommonQuestionData();
      setIsUploaded(true);
    } catch (error) {
      setMessage(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <div className="add">
          <button className="type-1 type-grad" onClick={handleFaqDataSubmit}>
            この内容で更新
          </button>
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <FaqEditContent
          handleInputChange={handleInputChange}
          message={message}
          newMultipleImages={newMultipleImages}
          setNewMultipleImages={setNewMultipleImages}
          uploadImages={uploadImages}
          setUploadImages={setUploadImages}
          setIsUploaded={setIsUploaded}
          categories={categories}
          commonQuestion={commonQuestion}
          setIsOpen={setIsOpen}
        />
      </div>
      <div className={`content-grid grid-3 layout-type-${layout}`}>
        <LibraryWindow
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          newMultipleImages={newMultipleImages}
          setNewMultipleImages={setNewMultipleImages}
          isUploaded={isUploaded}
          setIsUploaded={setIsUploaded}
        />
      </div>
      <Overlay isOpen={isOpen} children={"アップロード中"} />
    </>
  );
}

export default FaqEditPage;
