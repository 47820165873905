import React, { useState, useEffect } from "react";
import ImageUploader from "../../../components/website/ImageUploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus, faSquarePlus } from "@fortawesome/free-solid-svg-icons";

const ConceptDescription = ({
  conceptDescription,
  updateConceptDescription,
  setImageSelectIsOpen,
  setConceptDescription,
}) => {
  const [localDescriptions, setLocalDescriptions] =
    useState(conceptDescription);

  useEffect(() => {
    setLocalDescriptions(conceptDescription);
  }, [conceptDescription]);

  // iframe反映用
  useEffect(() => {
    setConceptDescription(localDescriptions);
  }, [localDescriptions]);

  // 項目を追加
  const handleAdd = () => {
    const newConceptDescription = [
      ...localDescriptions,
      { title: "", content: "", image: {} },
    ];
    setLocalDescriptions(newConceptDescription);
    updateConceptDescription(newConceptDescription);
  };

  // 項目を削除
  const handleRemove = (index) => {
    const filteredConceptDescription = localDescriptions.filter(
      (_, i) => i !== index
    );
    setLocalDescriptions(filteredConceptDescription);
    updateConceptDescription(filteredConceptDescription);
  };

  const handleBlur = () => {
    updateConceptDescription(localDescriptions);
  };

  const handleInputChange = (index, field, value) => {
    const newDescriptions = [...localDescriptions];
    newDescriptions[index][field] = value;
    setLocalDescriptions(newDescriptions);
  };

  return (
    <>
      {localDescriptions.map((description, index) => (
        <React.Fragment key={index}>
          <li>
            <p>タイトル{index + 1}</p>
            <label className="form">
              <input
                type="text"
                value={description.title}
                onChange={(e) =>
                  handleInputChange(index, "title", e.target.value)
                }
                onBlur={handleBlur}
              />
            </label>
          </li>
          <li>
            <p>説明{index + 1}</p>
            <label className="form">
              <textarea
                value={description.content}
                onChange={(e) =>
                  handleInputChange(index, "content", e.target.value)
                }
                onBlur={handleBlur}
              />
            </label>
          </li>
          <li className="heroheader">
            <p>画像{index + 1}</p>
            <ImageUploader
              formName={`image-${index}`}
              type={"single"}
              selected={description.image}
              setSelected={(image) => {
                handleInputChange(index, "image", image);
              }}
              setImageSelectIsOpen={setImageSelectIsOpen}
            />
          </li>
          <li className="priceAreaWrap">
            <div className="addBtn" onClick={() => handleRemove(index)}>
              <p>項目を削除</p>
              <i name="plus circle">
                <FontAwesomeIcon icon={faSquareMinus} />
              </i>
            </div>
            {index === localDescriptions.length - 1 && (
              <div className="addBtn" onClick={handleAdd}>
                <p>項目を追加</p>
                <i name="plus circle">
                  <FontAwesomeIcon icon={faSquarePlus} />
                </i>
              </div>
            )}
          </li>
        </React.Fragment>
      ))}
      {localDescriptions.length === 0 && (
        <li className="priceAreaWrap">
          <div className="addBtn" onClick={handleAdd}>
            <p>コンセプト説明を追加</p>
            <i name="plus circle">
              <FontAwesomeIcon icon={faSquarePlus} />
            </i>
          </div>
        </li>
      )}
    </>
  );
};

export default ConceptDescription;
