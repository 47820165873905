import React, { useState, useEffect, useCallback, memo } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Overlay from "../../../components/overlay";
import "../../../styles/scss/website/studioEdit.scss";
import LibraryWindow from "../../../components/website/LibraryWindow";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemType = "IMAGE";

const Image = ({ id, src, index, moveImage }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { id, index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item) => {
      if (item.index !== index) {
        moveImage(item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <li ref={(node) => ref(drop(node))} style={{ cursor: "move" }}>
      <img
        src={src}
        alt="Studio Image"
        height="125"
        width="125"
        onClick={() => {
          moveImage(index, -1); // -1を指定して削除を表現
        }}
      />
    </li>
  );
};

const StudioEditContent = memo(
  ({
    studio,
    handleInputChange,
    isPublished,
    setIsPublished,
    handleToggle,
    message,
    tabs,
    selectedTab,
    setSelectedTab,
    newSingleImage,
    newMultipleImages,
    setNewSingleImage,
    setNewMultipleImages,
    uploadImages,
    setUploadImages,
    uploadTopImage,
    setUploadTopImage,
    setIsUploaded,
    setIsOpen,
    handleGetLocation,
  }) => {
    useEffect(() => {
      handleImageSubmit();
    }, [uploadImages]);

    useEffect(() => {
      handleImageSubmit();
    }, [uploadTopImage]);

    // 画像をAPIに送信（アップロード）
    const handleImageSubmit = async () => {
      if (uploadImages.length === 0 && uploadTopImage.length === 0) {
        return;
      }
      setIsOpen(1);
      const formData = new FormData();
      if (selectedTab.type === "single") {
        uploadTopImage.forEach((image) => {
          formData.append("images", image);
        });
      } else if (selectedTab.type === "multiple") {
        uploadImages.forEach((image) => {
          formData.append("images", image);
        });
      }

      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/image_upload/`, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);

        if (selectedTab.type === "single") {
          setNewSingleImage(data.images[0]);
        } else if (selectedTab.type === "multiple") {
          setNewMultipleImages((prevImages) => [...prevImages, ...data.images]);
        }

        setIsUploaded(true);
        alert("アップロードが完了しました");
        setIsOpen(0);
      } catch (error) {
        alert("登録に失敗しました");
        setIsOpen(0);
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    // stateに画像をセット
    const handleImageChange = (event) => {
      if (selectedTab.type === "single") {
        setUploadTopImage([event.target.files[0]]);
      } else if (selectedTab.type === "multiple") {
        setUploadImages([...event.target.files]);
      }
    };

    const moveImage = (fromIndex, toIndex) => {
      setNewMultipleImages((prevImages) => {
        const updatedImages = [...prevImages];
        if (toIndex === -1) {
          // 画像を削除
          updatedImages.splice(fromIndex, 1);
        } else {
          const [movedImage] = updatedImages.splice(fromIndex, 1);
          updatedImages.splice(toIndex, 0, movedImage);
        }
        return updatedImages;
      });
    };

    return (
      <>
        <div className="List studioEditList">
          {/* フォーム */}
          <ul className="form1">
            {/*  */}
            <li>
              <span>店舗名</span>
              <label className="form">
                <input
                  type="text"
                  name="name"
                  value={studio?.name}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            {/*  */}
            <li>
              <span>英語表記</span>
              <label className="form">
                <input
                  type="text"
                  name="slug"
                  value={studio?.slug}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            {/*  */}
            <li>
              <span>住所</span>
              <label className="form">
                <input
                  type="text"
                  name="address"
                  value={studio?.address}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            {/*  */}
            <button className="type-2" onClick={handleGetLocation}>
              緯度経度取得
            </button>
            {/*  */}
            <li>
              <span>緯度</span>
              <label className="form">
                <input
                  type="text"
                  name="lat"
                  value={studio?.lat}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            {/*  */}
            <li>
              <span>経度</span>
              <label className="form">
                <input
                  type="text"
                  name="lng"
                  value={studio?.lng}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            {/*  */}
            <li>
              <span>公開</span>
              {/* アクティブの場合.active */}
              <label className={`form switch${isPublished ? " active" : ""}`}>
                <input
                  type="radio"
                  name="is_published"
                  value="true"
                  checked={isPublished === true}
                  onClick={() => {
                    setIsPublished(!isPublished);
                    handleToggle();
                  }}
                />
                <input
                  type="radio"
                  name="is_published"
                  value="false"
                  checked={isPublished === false}
                  onClick={() => {
                    setIsPublished(!isPublished);
                    handleToggle();
                  }}
                />
              </label>
            </li>
          </ul>

          <div className="tabArea" style={{ gridColumn: "span 6" }}>
            <ul className="tabBtn_type1">
              {tabs.map((tab) => (
                <li
                  key={tab.text}
                  className={selectedTab.text === tab.text ? "active" : ""}
                  onClick={() => setSelectedTab(tab)}
                >
                  {tab.text}
                </li>
              ))}
            </ul>

            {/* 画像 */}
            <div className="tab-content tab1">
              <p className="ttl">{selectedTab.text}</p>
              <DndProvider backend={HTML5Backend}>
                <ul className="imgList">
                  {selectedTab.type === "single" && newSingleImage && (
                    <li>
                      <img
                        src={newSingleImage.url}
                        alt="Studio Image"
                        onClick={() => {
                          setNewSingleImage(null);
                        }}
                        loading="lazy"
                        height="125"
                        width="125"
                      />
                    </li>
                  )}
                  {selectedTab.type === "multiple" &&
                    newMultipleImages &&
                    newMultipleImages.map((image, index) => (
                      <Image
                        key={image.id}
                        id={image.id}
                        src={image.url}
                        index={index}
                        moveImage={moveImage}
                      />
                    ))}
                </ul>
              </DndProvider>

              {/* アップロード */}
              <ul className="btnList">
                <li>
                  <button
                    className="type-2"
                    onClick={() =>
                      document.getElementById("uploadInput").click()
                    }
                  >
                    画像をアップロード
                  </button>
                  <input
                    id="uploadInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    multiple={selectedTab.type === "multiple"}
                  />
                </li>
              </ul>
            </div>
          </div>

          {/* メッセージ */}
          {message && (
            <div className="message success">
              <p>{message}</p>
              <Link
                to={`https://rudofactory.jp/studio/${studio.slug}`}
                target="_blank"
              >
                <button className="type-2">ページを見る</button>
              </Link>
            </div>
          )}
        </div>
      </>
    );
  }
);

function StudioEditPage() {
  const { studioId } = useParams();
  const [studio, setStudio] = useState({});
  const [isPublished, setIsPublished] = useState(false);
  const [message, setMessage] = useState("");
  const [newSingleImage, setNewSingleImage] = useState([]);
  const [newMultipleImages, setNewMultipleImages] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [uploadTopImage, setUploadTopImage] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [layout, setLayout] = useState("2");
  const [isOpen, setIsOpen] = useState(0); // オーバーレイの表示/非表示
  const [overlayText, setOverlayText] = useState("loading..."); // オーバーレイの文言

  const breadcrumbs = [
    { title: "スタジオ一覧", path: `/website/studio-list` },
    {
      title: "スタジオ詳細",
      path: `/website/studio-edit/${studio.id}`,
    },
  ];

  const tabs = [
    { text: "店舗画像", type: "single" },
    { text: "VISUAL", type: "multiple" },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  // 入力フォーム変更
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "slug") {
      // 小文字のアルファベットと半角アンダーバーのみを許可
      const lowerCaseValue = value.toLowerCase();
      const sanitizedValue = lowerCaseValue.replace(/[^a-z_]/g, "");
      if (lowerCaseValue !== sanitizedValue) {
        alert(
          "「英語表記」には小文字のアルファベットと半角アンダーバーしか使用できません。"
        );
      }
      setStudio((prevStudio) => ({
        ...prevStudio,
        [name]: sanitizedValue,
      }));
    } else {
      setStudio((prevStudio) => ({
        ...prevStudio,
        [name]: value,
      }));
    }
  };

  // 店舗情報を更新
  const handleStudioDataSubmit = async () => {
    if (!studio.name || !studio.slug || !studio.address) {
      alert("すべてのフィールドに値を入力してください。");
      return;
    }
    setOverlayText("スタジオ情報を更新中");
    setIsOpen(1);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const imageIds = newMultipleImages
        ? newMultipleImages.map((image) => image.id)
        : null;
      const topImageId = newSingleImage ? newSingleImage.id : null;
      const response = await fetch(`${apiUrl}/api/update_studio/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studio_id: studioId,
          name: studio.name,
          address: studio.address,
          slug: studio.slug,
          details: studio.details,
          lat: studio.lat,
          lng: studio.lng,
          top_image_id: topImageId,
          image_ids: imageIds,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      setMessage(data.message);
      fetchStudioData();
      setIsUploaded(true);
      setIsOpen(0);
    } catch (error) {
      setMessage(error);
      setIsOpen(0);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 公開用トグル
  const handleToggle = async () => {
    if (!isPublished && (studio.lat === null || studio.lng === null)) {
      alert("緯度経度が存在しないため、公開できません。");
      setIsPublished(false);
      return;
    }
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/update_is_published/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studio_id: studioId,
          is_published: !isPublished,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setIsPublished(!isPublished);
      console.log(data);
    } catch (error) {
      alert("更新に失敗しました");
      console.error("更新中にエラーが発生しました:", error);
    }
  };

  // スタジオデータの取得
  const fetchStudioData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_studio/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studio_id: studioId,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setStudio(data);
      setNewSingleImage(data.top_image);
      setNewMultipleImages(data.images);
      setIsPublished(data.is_published);
      // const sortedDetailsIconImages = data.details_icon_images.sort(
      //   (a, b) => a.id - b.id
      // );
      // setDetailsIconImages(sortedDetailsIconImages);
      // if (!data.details || data.details.length === 0) {
      //   const defaultDetails = [
      //     {
      //       details_icon: sortedDetailsIconImages[0]?.id || "",
      //       details_text: "",
      //     },
      //   ];
      //   setStudio((prevStudio) => ({
      //     ...prevStudio,
      //     details: defaultDetails,
      //   }));
      //   setSelectedItem([sortedDetailsIconImages[0] || {}]);
      //   setDropdownOpen([false]);
      // } else {
      //   setDropdownOpen(data.details.map(() => false));
      //   setSelectedItem(
      //     data.details.map(
      //       (details) =>
      //         sortedDetailsIconImages.find(
      //           (icon) => icon.id === details.details_icon
      //         ) || {}
      //     )
      //   );
      // }
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, [studioId]);

  useEffect(() => {
    fetchStudioData();
  }, [fetchStudioData]);

  // 緯度経度を取得
  const handleGetLocation = async () => {
    if (!studio.address) {
      alert("住所に値を入力してください。");
      return;
    }
    setOverlayText("緯度経度を取得中");
    setIsOpen(1);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_location/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address: studio.address,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      alert(data.message);
      setStudio((prevStudio) => ({
        ...prevStudio,
        lat: data.location.lat,
        lng: data.location.lng,
      }));
      setIsOpen(0);
    } catch (error) {
      alert(error);
      setIsOpen(0);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <div className="add">
          <button className="type-1 type-grad" onClick={handleStudioDataSubmit}>
            この内容で更新
          </button>
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <StudioEditContent
          studio={studio}
          handleInputChange={handleInputChange}
          isPublished={isPublished}
          setIsPublished={setIsPublished}
          handleToggle={handleToggle}
          message={message}
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          newSingleImage={newSingleImage}
          newMultipleImages={newMultipleImages}
          setNewSingleImage={setNewSingleImage}
          setNewMultipleImages={setNewMultipleImages}
          uploadImages={uploadImages}
          setUploadImages={setUploadImages}
          uploadTopImage={uploadTopImage}
          setUploadTopImage={setUploadTopImage}
          setIsUploaded={setIsUploaded}
          setIsOpen={setIsOpen}
          handleGetLocation={handleGetLocation}
        />
      </div>
      <div className={`content-grid grid-3 layout-type-${layout}`}>
        <LibraryWindow
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          newSingleImage={newSingleImage}
          newMultipleImages={newMultipleImages}
          setNewSingleImage={setNewSingleImage}
          setNewMultipleImages={setNewMultipleImages}
          isUploaded={isUploaded}
          setIsUploaded={setIsUploaded}
        />
      </div>
      <Overlay isOpen={isOpen} children={overlayText} />
    </>
  );
}

export default StudioEditPage;
