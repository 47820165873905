import React, { useState, useEffect } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLoginCheck } from "../../hooks/useLoginCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import Breadcrumbs from "../../components/Breadcrumbs";
import "../../styles/scss/managementUserList.scss";

function UserListPage() {
  useLoginCheck();

  const [users, setUsers] = useState([]); // ユーザーリスト
  const [selectedUser, setSelectedUser] = useState(null); // 選択したユーザー

  const breadcrumbs = [
    { title: "ユーザー管理", path: `/management/user-list` },
  ];

  // ユーザーの取得
  const fetchUserData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_users/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();

      setUsers(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // 権限の更新
  const handleSubmit = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/change_auth/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: selectedUser,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      alert("権限を更新しました");
      fetchUserData();
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // レイアウト
  // レイアウトを設定するためのstate
  const [layout, setLayout] = React.useState("4");

  // レイアウト変更の関数
  const changeLayout = (newLayout) => {
    setLayout(newLayout);
  };

  const handleSelectChange = (event) => {
    // 選択された値を取得
    const selectedValue = event.target.value;

    if (selectedValue === "管理者") {
      setSelectedUser((prevUser) => ({
        ...prevUser,
        is_superuser: true,
        is_active: true,
      }));
    } else if (selectedValue === "スタッフ") {
      setSelectedUser((prevUser) => ({
        ...prevUser,
        is_superuser: false,
        is_active: true,
      }));
    } else if (selectedValue === "承認待ち") {
      setSelectedUser((prevUser) => ({
        ...prevUser,
        is_superuser: false,
        is_active: false,
      }));
    }
  };

  const UserList = () => {
    return (
      <div className="studentList">
        {/* ユーザー一覧 */}
        <div className="List">
          <div className="gridList">
            <ul className="ttl">
              <li>ID</li>
              <li>ユーザー名</li>
              <li>メール</li>
              <li>権限</li>
            </ul>
            {users.map((user) => (
              <ul
                className="listContents"
                key={user.id}
                onClick={() => {
                  setSelectedUser(user);
                  changeLayout("1");
                }}
              >
                <>
                  <li>{user.id}</li>
                  <li>{user.username}</li>
                  <li>{user.email}</li>
                  <li>
                    {user.is_superuser
                      ? "管理者"
                      : user.is_active
                      ? "スタッフ"
                      : "承認待ち"}
                  </li>
                </>
              </ul>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const UserDetail = () => {
    return (
      <>
        <div className="UserEdit">
          <button
            className="closeBtn"
            onClick={() => {
              setSelectedUser(null);
              changeLayout("4");
            }}
          >
            <i>
              <FontAwesomeIcon icon={faTimes} />
            </i>
          </button>
          <p className="contentTtl">権限編集</p>
          <ul className="">
            <li>ID</li>
            <li>{selectedUser.id}</li>
            <li>ユーザー名</li>
            <li>{selectedUser.username}</li>
            <li>メール</li>
            <li>{selectedUser.email}</li>
            <li>権限</li>
            <li>
              <label className="form">
                <select
                  defaultValue={
                    selectedUser.is_superuser
                      ? "管理者"
                      : selectedUser.is_active
                      ? "スタッフ"
                      : "承認待ち"
                  }
                  onChange={handleSelectChange}
                >
                  <option value="管理者">管理者</option>
                  <option value="スタッフ">スタッフ</option>
                  <option value="承認待ち">承認待ち</option>
                </select>
              </label>
            </li>
          </ul>
          <ul>
            <li className="submit">
              <button
                type="submit"
                className="submit type-3 type-3"
                onClick={handleSubmit}
              >
                更新する
              </button>
            </li>
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout}`}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <UserList />
      </div>
      <div
        className={`content-grid grid-3 layout-type-${layout} UserEdit-wrap edit`}
      >
        {selectedUser !== null && <UserDetail />}
      </div>
    </>
  );
}

export default UserListPage;
