import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";

function ResetPasswordPage() {
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const [userToken, setUserToken] = useState("");
  const [userIdParam, setUserIdParam] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const { setIsLoggedIn } = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (uid && token) {
      console.log(uid);
      console.log(token);
      setUserIdParam(uid);
      setUserToken(token);
    }
  }, [uid, token]);

  useEffect(() => {
    if (userIdParam && userToken) {
      checkUidToken();
    }
  }, [userIdParam, userToken]);

  // uidとtokenのチェック
  const checkUidToken = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/check_uid_token/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: uid,
          token: token,
        }),
      });
      const data = await response.json();

      if (!response.ok) {
        alert(data.message);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setUserId(data.user_id);
      setEmail(data.email);
    } catch (error) {
      console.error("エラーが発生しました:", error);
      navigate("/login");
    }
  };

  useEffect(() => {
    console.log(userId);
    console.log(email);
  }, [userId, email]);

  const handleSubmit = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/change_password/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          email: email,
          password: password,
          password_confirm: passwordConfirm,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      alert("パスワードを変更しました。再度ログインして下さい。");
      navigate("/login");
    } catch (error) {
      console.error("エラーが発生しました:", error);
    }
  };

  return (
    <>
      <div className="loginWrap">
        <h2>パスワードリセット</h2>
        <div className="formWrap">
          {/* ユーザーネーム */}
          <label className="inputLabel text">
            <p>新しいパスワード</p>
            <input
              type="text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>

          {/*  */}
          <label className="inputLabel text">
            <p>新しいパスワード（確認用）</p>
            <input
              type="text"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </label>

          <button onClick={handleSubmit}>パスワードをリセットする</button>

          {error && (
            <div className="message">
              <p color="red">{error}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ResetPasswordPage;
