import React, { useState, useEffect, useCallback, memo } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Overlay from "../../../components/overlay";
import "../../../styles/scss/website/newsEdit.scss";
import LibraryWindow from "../../../components/website/LibraryWindow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes, // ×アイコンを追加
} from "@fortawesome/pro-solid-svg-icons";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemType = "IMAGE";

const Image = ({ id, src, index, moveImage }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { id, index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item) => {
      if (item.index !== index) {
        moveImage(item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <li ref={(node) => ref(drop(node))} style={{ cursor: "move" }}>
      <img
        src={src}
        alt="News Image"
        height="125"
        width="125"
        onClick={() => {
          moveImage(index, -1); // -1を指定して削除を表現
        }}
      />
    </li>
  );
};

const NewsEditContent = memo(
  ({
    news,
    handleInputChange,
    isSecret,
    setIsSecret,
    handleToggle,
    message,
    tabs,
    selectedTab,
    setSelectedTab,
    newSingleImage,
    newMultipleImages,
    setNewSingleImage,
    setNewMultipleImages,
    uploadImages,
    setUploadImages,
    uploadTopImage,
    setUploadTopImage,
    setIsUploaded,
    setIsOpen,
    hashtagMasterList,
    selectedHashTags,
    handleRemoveTag,
    handleHashtagInputChange,
    handleHashtagInputKeyPress,
    handleSelectedHashTags,
    filteredHashtags,
    setFilteredHashtags,
    hashtagInput,
  }) => {
    useEffect(() => {
      handleImageSubmit();
    }, [uploadImages]);

    useEffect(() => {
      handleImageSubmit();
    }, [uploadTopImage]);

    // 画像をAPIに送信（アップロード）
    const handleImageSubmit = async () => {
      if (uploadImages.length === 0 && uploadTopImage.length === 0) {
        return;
      }
      setIsOpen(1);
      const formData = new FormData();
      if (selectedTab.type === "single") {
        uploadTopImage.forEach((image) => {
          formData.append("images", image);
        });
      } else if (selectedTab.type === "multiple") {
        uploadImages.forEach((image) => {
          formData.append("images", image);
        });
      }

      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/image_upload/`, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);

        if (selectedTab.type === "single") {
          setNewSingleImage(data.images[0]);
        } else if (selectedTab.type === "multiple") {
          setNewMultipleImages((prevImages) => [...prevImages, ...data.images]);
        }

        setIsUploaded(true);
        alert("アップロードが完了しました");
        setIsOpen(0);
      } catch (error) {
        alert("登録に失敗しました");
        setIsOpen(0);
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    // stateに画像をセット
    const handleImageChange = (event) => {
      if (selectedTab.type === "single") {
        setUploadTopImage([event.target.files[0]]);
      } else if (selectedTab.type === "multiple") {
        setUploadImages([...event.target.files]);
      }
    };

    const moveImage = (fromIndex, toIndex) => {
      setNewMultipleImages((prevImages) => {
        const updatedImages = [...prevImages];
        if (toIndex === -1) {
          // 画像を削除
          updatedImages.splice(fromIndex, 1);
        } else {
          const [movedImage] = updatedImages.splice(fromIndex, 1);
          updatedImages.splice(toIndex, 0, movedImage);
        }
        return updatedImages;
      });
    };

    // 入力されたテキストに基づいて hashtagMasterList からフィルタリング
    useEffect(() => {
      if (hashtagInput.trim() === "") {
        setFilteredHashtags([]);
        return;
      }
      const results = hashtagMasterList.filter((hashtag) =>
        hashtag.text.toLowerCase().includes(hashtagInput.toLowerCase())
      );
      setFilteredHashtags(results);
    }, [hashtagInput, hashtagMasterList]);

    return (
      <>
        <div className="List newsEditList">
          {/* フォーム */}
          <ul className="form1">
            {/*  */}
            <li>
              <span>タイトル</span>
              <label className="form">
                <input
                  type="text"
                  name="title"
                  value={news?.title}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            {/*  */}
            <li className="textarea">
              <span>本文</span>
              <label className="form">
                <textarea
                  name="text"
                  value={news?.text}
                  onChange={handleInputChange}
                ></textarea>
              </label>
            </li>
            {/*  */}
            <li className="hashTag">
              <span>ハッシュタグ</span>
              <label className="form">
                {selectedHashTags.map((hashtag, index) => (
                  <p key={index}>
                    <span>{hashtag.text}</span>
                    <i>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() => handleRemoveTag(hashtag)}
                      />
                    </i>
                  </p>
                ))}
                <div className="addHashtag">
                  <input
                    type="text"
                    name="hashtags"
                    placeholder="ハッシュタグを追加"
                    value={hashtagInput}
                    onChange={handleHashtagInputChange}
                    onKeyPress={handleHashtagInputKeyPress}
                  />

                  {filteredHashtags.length > 0 && (
                    <ul className="tooltip">
                      {filteredHashtags.map((hashtagMaster) => (
                        <li
                          key={hashtagMaster.id}
                          onClick={() => handleSelectedHashTags(hashtagMaster)}
                        >
                          {hashtagMaster.text}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </label>
            </li>
            {/*  */}
            <li>
              <span>限定公開</span>
              {/* アクティブの場合.active */}
              <label className={`form switch${isSecret ? " active" : ""}`}>
                <input
                  type="radio"
                  name="is_secret"
                  value="true"
                  checked={isSecret === true}
                  onClick={() => {
                    setIsSecret(!isSecret);
                    handleToggle();
                  }}
                />
                <input
                  type="radio"
                  name="is_secret"
                  value="false"
                  checked={isSecret === false}
                  onClick={() => {
                    setIsSecret(!isSecret);
                    handleToggle();
                  }}
                />
              </label>
            </li>
          </ul>

          <div className="tabArea" style={{ gridColumn: "span 6" }}>
            <ul className="tabBtn_type1">
              {tabs.map((tab) => (
                <li
                  key={tab.text}
                  className={selectedTab.text === tab.text ? "active" : ""}
                  onClick={() => setSelectedTab(tab)}
                >
                  {tab.text}
                </li>
              ))}
            </ul>

            {/* 画像 */}
            <div className="tab-content tab1">
              <p className="ttl">{selectedTab.text}</p>
              <DndProvider backend={HTML5Backend}>
                <ul className="imgList">
                  {selectedTab.type === "single" && newSingleImage && (
                    <li>
                      <img
                        src={newSingleImage.url}
                        alt="News Image"
                        onClick={() => {
                          setNewSingleImage(null);
                        }}
                        loading="lazy"
                        height="125"
                        width="125"
                      />
                    </li>
                  )}
                  {selectedTab.type === "multiple" &&
                    newMultipleImages &&
                    newMultipleImages.map((image, index) => (
                      <Image
                        key={image.id}
                        id={image.id}
                        src={image.url}
                        index={index}
                        moveImage={moveImage}
                      />
                    ))}
                </ul>
              </DndProvider>

              {/* アップロード */}
              <ul className="btnList">
                <li>
                  <button
                    className="type-2"
                    onClick={() =>
                      document.getElementById("uploadInput").click()
                    }
                  >
                    画像をアップロード
                  </button>
                  <input
                    id="uploadInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    multiple={selectedTab.type === "multiple"}
                  />
                </li>
              </ul>
            </div>
          </div>

          {/* メッセージ */}
          {message && (
            <div className="message success">
              <p>{message}</p>
              <Link
                to={`https://rudofactory.jp/news/${news.id}`}
                target="_blank"
              >
                <button className="type-2">ページを見る</button>
              </Link>
            </div>
          )}
        </div>
      </>
    );
  }
);

function NewsEditPage() {
  const { newsId } = useParams();
  const [news, setNews] = useState({});
  const [isSecret, setIsSecret] = useState(false);
  const [message, setMessage] = useState("");
  const [newSingleImage, setNewSingleImage] = useState([]);
  const [newMultipleImages, setNewMultipleImages] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [uploadTopImage, setUploadTopImage] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [layout, setLayout] = useState("2");
  const [isOpen, setIsOpen] = useState(0); // オーバーレイの表示/非表示
  const [hashtagMasterList, setHashtagMasterList] = useState([]);
  const [filteredHashtags, setFilteredHashtags] = useState([]);
  const [selectedHashTags, setSelectedHashTags] = useState([]);
  const [hashtagInput, setHashtagInput] = useState("");
  const [deletedTags, setDeletedTags] = useState([]);

  const breadcrumbs = [
    { title: "投稿一覧", path: `/website/news-list` },
    {
      title: "投稿詳細",
      path: `/website/news-edit/${news.id}`,
    },
  ];

  const tabs = [
    { text: "アイキャッチ", type: "single" },
    { text: "投稿画像", type: "multiple" },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  // ハッシュタグマスタの取得
  const fetchHashtagMasterData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_hashtag_master/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setHashtagMasterList(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // ハッシュタグの取得
  const fetchHashtagForTarget = useCallback(async () => {
    if (!newsId) return;
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_hashtag_for_target/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target_id: newsId,
          type: "News",
        }),
      });
      const data = await response.json();
      setSelectedHashTags(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [newsId]);

  // タグマスタの取得
  useEffect(() => {
    fetchHashtagMasterData();
  }, [fetchHashtagMasterData]);

  // ニュースのidを基にハッシュタグを取得
  useEffect(() => {
    fetchHashtagForTarget();
  }, [fetchHashtagForTarget]);

  // 入力欄を基にタグをstateに保存する関数
  const handleAddHashtag = () => {
    if (hashtagInput) {
      // ハッシュタグは大文字全角英数記号の場合、半角英数に変換する
      const normalizedHashtagInput = hashtagInput
        .replace(/[Ａ-Ｚａ-ｚ０-９！-～]/g, (s) =>
          String.fromCharCode(s.charCodeAt(0) - 0xfee0)
        )
        .toLowerCase();
      // 一致するハッシュタグを検索
      const matchedHashtag = hashtagMasterList.find(
        (tag) => tag.text === normalizedHashtagInput
      );

      setSelectedHashTags((prevTags) => {
        // 既に削除されたタグのリストにあるか確認
        const existingDeletedTagIndex = deletedTags.findIndex(
          (tag) => tag.text === normalizedHashtagInput
        );

        if (existingDeletedTagIndex !== -1) {
          // 削除されたタグのリストからタグを戻す
          const existingDeletedTag = deletedTags[existingDeletedTagIndex];
          setDeletedTags((prevDeletedTags) =>
            prevDeletedTags.filter(
              (_, index) => index !== existingDeletedTagIndex
            )
          );
          return [...prevTags, existingDeletedTag];
        } else {
          // 既に選択されたタグのリストにあるか確認
          const isTagAlreadyAdded = prevTags.some(
            (tag) => tag.text === normalizedHashtagInput
          );

          if (!isTagAlreadyAdded) {
            // 一致するものがある場合はそのレコードを、なければ新しいオブジェクトを追加
            const newTag = matchedHashtag
              ? {
                  id: null,
                  master_id: matchedHashtag.id,
                  text: normalizedHashtagInput,
                  order: null,
                }
              : {
                  id: null,
                  master_id: null,
                  text: normalizedHashtagInput,
                  order: null,
                };
            return [...prevTags, newTag];
          }
        }

        return prevTags; // 既に存在する場合は何も追加しない
      });

      setHashtagInput("");
    }
  };

  // タグを削除する関数
  const handleRemoveTag = (tagToRemove) => {
    setSelectedHashTags((prevTags) =>
      prevTags.filter((tag) => tag !== tagToRemove)
    );
    setDeletedTags((prevDeletedTags) => [...prevDeletedTags, tagToRemove]);
  };

  // タグ入力欄でエンターを押下した際にstateに保持する関数を呼び出す
  const handleHashtagInputKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddHashtag();
      setHashtagInput("");
    }
  };

  // インプットの状態を監視
  const handleHashtagInputChange = (event) => {
    setHashtagInput(event.target.value);
  };

  // ハッシュタグの保存
  const saveHashTags = async (news_id) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/save_hashtags/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          target_id: news_id,
          hashtags: selectedHashTags,
          type: "News",
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // stateにハッシュタグをセット
  const handleSelectedHashTags = (hashtagMaster) => {
    // 既に削除されたタグのリストにあるか確認
    const existingDeletedTagIndex = deletedTags.findIndex(
      (tag) => tag.master_id === hashtagMaster.id
    );

    if (existingDeletedTagIndex !== -1) {
      // 削除されたタグのリストからタグを戻す
      const existingDeletedTag = deletedTags[existingDeletedTagIndex];
      setDeletedTags((prevDeletedTags) =>
        prevDeletedTags.filter((_, index) => index !== existingDeletedTagIndex)
      );
      setSelectedHashTags((prevTags) => [...prevTags, existingDeletedTag]);
    } else {
      // 既に選択されたタグのリストに含まれているか確認
      const exists = selectedHashTags.some(
        (tag) => tag.master_id === hashtagMaster.id
      );

      if (!exists) {
        // 存在しない場合は新たに追加
        setSelectedHashTags((prevTags) => [
          ...prevTags,
          {
            id: null,
            master_id: hashtagMaster.id,
            text: hashtagMaster.text,
            order: null,
          },
        ]);
      }
    }
    setHashtagInput("");
  };

  // 入力フォーム変更
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNews((prevNews) => ({
      ...prevNews,
      [name]: value,
    }));
  };

  // 投稿情報を更新
  const handleNewsDataSubmit = async () => {
    if (!news.title || !news.text) {
      alert("タイトルと本文は必須です。");
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const imageIds = newMultipleImages
        ? newMultipleImages.map((image) => image.id)
        : null;
      const topImageId = newSingleImage ? newSingleImage.id : null;
      const response = await fetch(`${apiUrl}/api/update_news/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          news_id: newsId,
          title: news.title,
          text: news.text,
          top_image_id: topImageId,
          image_ids: imageIds,
        }),
      });

      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      const data = await response.json();
      await saveHashTags(newsId);

      setMessage(data.message);
      fetchNewsData();
      setIsUploaded(true);
      fetchHashtagForTarget();
      fetchHashtagMasterData();
    } catch (error) {
      setMessage(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 公開用トグル
  const handleToggle = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/update_is_secret/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          news_id: newsId,
          is_secret: !isSecret,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setIsSecret(!isSecret);
      console.log(data);
    } catch (error) {
      alert("更新に失敗しました");
      console.error("更新中にエラーが発生しました:", error);
    }
  };

  // 投稿データの取得
  const fetchNewsData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_news/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          news_id: newsId,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setNews(data);
      setNewSingleImage(data.top_image);
      setNewMultipleImages(data.images);
      setIsSecret(data.is_secret);
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, [newsId]);

  useEffect(() => {
    fetchNewsData();
  }, [fetchNewsData]);

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <div className="add">
          <button className="type-1 type-grad" onClick={handleNewsDataSubmit}>
            この内容で更新
          </button>
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <NewsEditContent
          news={news}
          handleInputChange={handleInputChange}
          isSecret={isSecret}
          setIsSecret={setIsSecret}
          handleToggle={handleToggle}
          message={message}
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          newSingleImage={newSingleImage}
          newMultipleImages={newMultipleImages}
          setNewSingleImage={setNewSingleImage}
          setNewMultipleImages={setNewMultipleImages}
          uploadImages={uploadImages}
          setUploadImages={setUploadImages}
          uploadTopImage={uploadTopImage}
          setUploadTopImage={setUploadTopImage}
          setIsUploaded={setIsUploaded}
          setIsOpen={setIsOpen}
          hashtagMasterList={hashtagMasterList}
          selectedHashTags={selectedHashTags}
          handleRemoveTag={handleRemoveTag}
          handleHashtagInputChange={handleHashtagInputChange}
          handleHashtagInputKeyPress={handleHashtagInputKeyPress}
          handleSelectedHashTags={handleSelectedHashTags}
          filteredHashtags={filteredHashtags}
          setFilteredHashtags={setFilteredHashtags}
          hashtagInput={hashtagInput}
        />
      </div>
      <div className={`content-grid grid-3 layout-type-${layout}`}>
        <LibraryWindow
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          newSingleImage={newSingleImage}
          newMultipleImages={newMultipleImages}
          setNewSingleImage={setNewSingleImage}
          setNewMultipleImages={setNewMultipleImages}
          isUploaded={isUploaded}
          setIsUploaded={setIsUploaded}
        />
      </div>
      <Overlay isOpen={isOpen} children={"アップロード中"} />
    </>
  );
}

export default NewsEditPage;
