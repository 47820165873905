import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-solid-svg-icons";
import { faSpinnerScale } from "@fortawesome/pro-solid-svg-icons";

const Overlay = ({ isOpen, children }) => {
  if (!isOpen) return null;

  return (
    <div className="overlay">
      <div className="overlay-content">
        <FontAwesomeIcon icon={faSpinnerScale} spinPulse />
        <p>{children}</p>
      </div>
    </div>
  );
};

export default Overlay;
