import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
} from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import "../../../styles/scss/website/price.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faSquareMinus,
  faSquarePlus,
} from "@fortawesome/pro-solid-svg-icons";

import Iframe from "react-iframe";

const ForwardedIframe = forwardRef((props, ref) => (
  <Iframe {...props} innerRef={ref} />
));

const PriceEditPage = () => {
  const [pagePriceContents, setPagePriceContents] = useState();
  const [message, setMessage] = useState("");

  // プライス詳細の取得
  const fetchPagePriceContentData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_page_price_content/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setPagePriceContents(data.page_price_content);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  useEffect(() => {
    fetchPagePriceContentData();
  }, []);

  // inputタグが更新された時にstateを更新する関数
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [key, index] = name.split("-");
    setPagePriceContents((prevPagePriceContents) => {
      return prevPagePriceContents.map((block, idx) => {
        if (idx.toString() === index) {
          return {
            ...block,
            [key]: value,
          };
        }
        return block;
      });
    });
  };

  // submitボタンが押下された時に動く関数
  const handleSubmit = async () => {
    // 更新するデータ
    const formData = {
      page_price_contents: pagePriceContents,
    };

    // バリデーション
    const errMsg = formValidation(formData);
    if (errMsg) {
      alert(errMsg);
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/update_page_price_content/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page_price_contents: pagePriceContents,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }
      setMessage(data.message);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // バリデーション
  function formValidation(formData) {
    console.log(formData.page_price_contents);
    // 必須チェック
    const requiredFields = [];
    if (!formData.page_price_contents) requiredFields.push("PC用動画(720p)");
    const pricesRequiredFields = [];
    if (formData.page_price_contents) {
      formData.page_price_contents.forEach((content, index) => {
        if (!content.title)
          requiredFields.push(`ブロック${index + 1}のタイトル`);
        if (content.prices && content.prices.length > 0) {
          content.prices.forEach((price, i) => {
            if (!price.label || !price.amount)
              pricesRequiredFields.push(`ブロック${index + 1}の料金詳細`);
          });
        }
      });
    }
    if (pricesRequiredFields.length > 0) {
      requiredFields.push(...pricesRequiredFields);
    }
    const errMsg =
      requiredFields.length != 0
        ? `${requiredFields.join("、")}を設定してください。`
        : "";
    return errMsg;
  }

  // レイアウトを設定するためのstate
  const [layout, setLayout] = useState("2");

  const breadcrumbs = [{ title: "プライス詳細", path: `` }];

  const origin = process.env.REACT_APP_ORIGIN;
  const iframeRef = useRef(null);

  // 管理画面 iframe用
  useEffect(() => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const updateIframeContent = () => {
        try {
          iframe.contentWindow.postMessage({ pagePriceContents }, origin);
        } catch (error) {
          console.error("クロスオリジンのエラーが発生しました:", error);
        }
      };

      iframe.onload = updateIframeContent;
      updateIframeContent();
    }
  }, [pagePriceContents]);

  // ブロックの追加
  const handleAdd = () => {
    setPagePriceContents([
      ...pagePriceContents,
      {
        title: "",
        description: "",
        prices: [{ label: "", amount: 0 }],
        button_description: "",
        button_text: "",
        button_link: "",
      },
    ]);
  };

  // ブロックの削除
  const handleRemove = (blockIndex) => {
    setPagePriceContents((prevPagePriceContents) => {
      return prevPagePriceContents.filter((_, idx) => idx !== blockIndex);
    });
  };

  // 料金の更新
  const handlePriceChange = (e, blockIndex) => {
    const { name, value } = e.target;
    const [key, index] = name.split("-");
    setPagePriceContents((prevPagePriceContents) => {
      return prevPagePriceContents.map((block, idx) => {
        if (idx === blockIndex) {
          const newPrices = block.prices.map((price, priceIdx) => {
            if (priceIdx.toString() === index) {
              return {
                ...price,
                [key]: value,
              };
            }
            return price;
          });
          return {
            ...block,
            prices: newPrices,
          };
        }
        return block;
      });
    });
  };

  // 料金の追加
  const handleAddPrice = (blockIndex) => {
    setPagePriceContents((prevPagePriceContents) => {
      return prevPagePriceContents.map((block, idx) => {
        if (idx === blockIndex) {
          // ブロックとその価格リストを新しいオブジェクトとしてコピー
          return {
            ...block,
            prices: [...block.prices, { label: "", amount: "" }],
          };
        }
        return block;
      });
    });
  };

  // 料金の削除
  const handleRemovePrice = (blockIndex, priceIndex) => {
    setPagePriceContents((prevPagePriceContents) => {
      return prevPagePriceContents.map((block, idx) => {
        if (idx === blockIndex) {
          return {
            ...block,
            prices: block.prices.filter((_, i) => i !== priceIndex),
          };
        }
        return block;
      });
    });
  };

  // メッセージを非表示
  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 5000);
  }, [message]);

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        {/* サイト表示 */}
        <div className="siteView">
          <ForwardedIframe
            ref={iframeRef}
            url={`${origin}/price`}
            width="100%"
            height="1000px"
          />
        </div>
      </div>
      <div className={`content-grid grid-3 layout-type-${layout}`}>
        <div className="textForm">
          {pagePriceContents?.map((pagePriceContent, index) => (
            <React.Fragment key={index}>
              <p>ブロック{index + 1}</p>
              <ul>
                <li>
                  <p>タイトル</p>
                  <label className="form">
                    <input
                      type="text"
                      name={`title-${index}`}
                      value={pagePriceContent.title}
                      onChange={handleInputChange}
                    />
                  </label>
                </li>
                <li>
                  <p>説明</p>
                  <label className="form">
                    <textarea
                      name={`description-${index}`}
                      value={pagePriceContent.description}
                      onChange={handleInputChange}
                    />
                  </label>
                </li>
                <li className="priceAreaWrap">
                  <p className="form-label">料金詳細</p>
                  <>
                    {pagePriceContent.prices.map((price, i) => (
                      <React.Fragment key={`price-${i}`}>
                        <label className="form">
                          <div className="priceArea">
                            <input
                              type="text"
                              placeholder="タイトル"
                              value={price.label}
                              name={`label-${i}`}
                              onChange={(e) => {
                                handlePriceChange(e, index);
                              }}
                            />
                            <input
                              type="text"
                              placeholder="金額"
                              value={price.amount}
                              name={`amount-${i}`}
                              onChange={(e) => {
                                handlePriceChange(e, index);
                              }}
                            />

                            <div className="RemoveBtn">
                              <i
                                name="minus circle"
                                onClick={() => handleRemovePrice(index, i)}
                              >
                                <FontAwesomeIcon icon={faMinus} />
                              </i>
                            </div>
                          </div>
                        </label>
                      </React.Fragment>
                    ))}
                    <div
                      className="addBtn"
                      onClick={() => handleAddPrice(index)}
                    >
                      <p>料金を追加</p>
                      <i name="plus circle">
                        <FontAwesomeIcon icon={faSquarePlus} />
                      </i>
                    </div>
                  </>
                </li>
                <p>ボタン</p>
                <li>
                  <p>テキスト</p>
                  <label className="form">
                    <input
                      type="text"
                      name={`button_description-${index}`}
                      value={pagePriceContent.button_description}
                      onChange={handleInputChange}
                    />
                  </label>
                </li>
                <li>
                  <p>ボタンテキスト</p>
                  <label className="form">
                    <input
                      type="text"
                      name={`button_text-${index}`}
                      value={pagePriceContent.button_text}
                      onChange={handleInputChange}
                    />
                  </label>
                </li>
                <li>
                  <p>リンク</p>
                  <label className="form">
                    <input
                      type="text"
                      name={`button_link-${index}`}
                      value={pagePriceContent.button_link}
                      onChange={handleInputChange}
                    />
                  </label>
                </li>
              </ul>
              {pagePriceContents?.length > 1 && (
                <div className="addBtn" onClick={() => handleRemove(index)}>
                  <p>ブロックを削除</p>
                  <i name="plus circle">
                    <FontAwesomeIcon icon={faSquareMinus} />
                  </i>
                </div>
              )}
            </React.Fragment>
          ))}
          <div className="addBtn" onClick={() => handleAdd()}>
            <p>ブロックを追加</p>
            <i name="plus circle">
              <FontAwesomeIcon icon={faSquarePlus} />
            </i>
          </div>

          <div className="submit">
            <button
              type="submit"
              className="submit type-3 type-3"
              onClick={handleSubmit}
            >
              更新
            </button>
          </div>
          {message && (
            <div className="message success">
              <p>{message}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PriceEditPage;
