import React, { useState, useEffect, useCallback } from "react";

const ImageSelect = ({ setImageSelectIsOpen, type, selected, setSelected }) => {
  const [images, setImages] = useState([]); // 画像リスト
  const [recentImages, setRecentImages] = useState([]); // 最近の画像リスト
  const [favoriteImages, setFavoriteImages] = useState([]); // お気に入りの画像リスト
  const [tempSelected, setTempSelected] = useState(selected || []); // 一時的な変数

  // 最近の画像リストの取得
  const fetchRecentImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_recent_images/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setRecentImages(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // お気に入りの画像リストの取得
  const fetchFavoriteImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_favorite_images/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setFavoriteImages(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // ハッシュタグごとの画像リストの取得
  const fetchImagesDataByHashtag = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/get_images_by_hashtag_master/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setImages(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  });

  // 画像リストの取得
  useEffect(() => {
    fetchImagesDataByHashtag();
    fetchRecentImagesData();
    fetchFavoriteImagesData();
  }, []);

  // 画像クリック
  const handleImageClick = (image) => {
    if (type === "single") {
      setTempSelected(image);
    } else if (type === "multiple") {
      setTempSelected((prevTempSelected) => {
        if (Array.isArray(prevTempSelected)) {
          if (
            prevTempSelected.some(
              (selectedImage) => selectedImage.id === image.id
            )
          ) {
            return prevTempSelected.filter(
              (selectedImage) => selectedImage.id !== image.id
            );
          } else {
            return [...prevTempSelected, image];
          }
        } else {
          return [image];
        }
      });
    }
  };

  function isVideo(mimeType) {
    return mimeType.startsWith("video/");
  }

  return (
    <div
      className="overlay"
      onClick={() => {
        setImageSelectIsOpen("");
      }}
    >
      <div
        className="ov-inner"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/*  */}
        <div className="imgList">
          {/* お気に入り*/}
          {favoriteImages && (
            <>
              <p className="ov-ttl">お気に入り</p>
              <ul>
                {favoriteImages
                  .filter((image) => !isVideo(image.mime_type))
                  .map((image) => (
                    <>
                      <li
                        className={
                          type === "single"
                            ? tempSelected && tempSelected.id === image.id
                              ? "on"
                              : ""
                            : tempSelected &&
                              tempSelected.some(
                                (selectedImage) => selectedImage.id === image.id
                              )
                            ? "on"
                            : ""
                        }
                        key={image.id}
                        onClick={() => {
                          handleImageClick(image);
                        }}
                      >
                        <img src={image.url} alt={`画像${image.id}`} />
                      </li>
                    </>
                  ))}
              </ul>
            </>
          )}
          {/* 最近のアップロード */}
          {recentImages && (
            <>
              <p className="ov-ttl">最近のアップロード</p>
              <ul>
                {recentImages
                  .filter((image) => !isVideo(image.mime_type))
                  .map((image) => (
                    <>
                      <li
                        className={
                          type === "single"
                            ? tempSelected && tempSelected.id === image.id
                              ? "on"
                              : ""
                            : tempSelected &&
                              tempSelected.some(
                                (selectedImage) => selectedImage.id === image.id
                              )
                            ? "on"
                            : ""
                        }
                        key={image.id}
                        onClick={() => {
                          handleImageClick(image);
                        }}
                      >
                        <img src={image.url} alt={`画像${image.id}`} />
                      </li>
                    </>
                  ))}
              </ul>
            </>
          )}
          {/* ハッシュタグ*/}
          {images &&
            images.map((imageGroup, index) => (
              <>
                <p className="ov-ttl" key={index}>
                  {imageGroup.hashtag_master_text}
                </p>
                <ul>
                  {imageGroup.images
                    .filter((image) => !isVideo(image.mime_type))
                    .map((image) => (
                      <>
                        <li
                          className={
                            type === "single"
                              ? tempSelected && tempSelected.id === image.id
                                ? "on"
                                : ""
                              : tempSelected &&
                                tempSelected.some(
                                  (selectedImage) =>
                                    selectedImage.id === image.id
                                )
                              ? "on"
                              : ""
                          }
                          key={image.id}
                          onClick={() => {
                            handleImageClick(image);
                          }}
                        >
                          <img src={image.url} alt={`画像${image.id}`} />
                        </li>
                      </>
                    ))}
                </ul>
              </>
            ))}
        </div>
        {/*  */}
        <div className="imgPreview">
          {/* プレビュー */}
          <div className="">
            <p className="ov-ttl">プレビュー</p>
            <figure className="imgCenter">
              {tempSelected &&
              (Array.isArray(tempSelected)
                ? tempSelected.length > 0
                : Object.keys(tempSelected).length > 0) ? (
                <>
                  {type === "single" && <img src={tempSelected.url} alt={``} />}
                  {type === "multiple" && (
                    <img
                      src={tempSelected[tempSelected.length - 1].url}
                      alt={``}
                    />
                  )}
                </>
              ) : (
                <img src="https://via.placeholder.com/150" alt={``} />
              )}
            </figure>
          </div>

          {/* 選択済み */}
          {tempSelected &&
            (Array.isArray(tempSelected)
              ? tempSelected.length > 0
              : Object.keys(tempSelected).length > 0) && (
              <>
                {type === "multiple" && (
                  <div className="">
                    <p className="ov-ttl">選択済み</p>
                    <div className="imgList">
                      <ul>
                        {tempSelected.map((x) => (
                          <li
                            className={
                              type === "single"
                                ? tempSelected && tempSelected.id === x.id
                                  ? "on"
                                  : ""
                                : tempSelected &&
                                  tempSelected.some(
                                    (selectedImage) => selectedImage.id === x.id
                                  )
                                ? "on"
                                : ""
                            }
                            key={x.id}
                          >
                            <img src={x.url} alt={`画像${x.id}`} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </>
            )}
          {/* サブミット */}
          <button
            onClick={() => {
              setSelected(tempSelected);
              setImageSelectIsOpen("");
            }}
            disabled={
              !(
                tempSelected &&
                (Array.isArray(tempSelected)
                  ? tempSelected.length > 0
                  : Object.keys(tempSelected).length > 0)
              )
            }
          >
            画像を追加
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageSelect;
