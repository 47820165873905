import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faSquarePlus } from "@fortawesome/pro-solid-svg-icons";

// PriceAreaコンポーネント
const PriceArea = ({ prices, setPrices }) => {
  const handlePriceChange = (e, index) => {
    const { name, value } = e.target;
    const newPrices = [...prices];
    newPrices[index][name.split("_")[0]] = value;
    setPrices(newPrices);
  };

  const handleAdd = () => {
    setPrices([...prices, { name: "", before: "", after: "" }]);
  };

  const handleRemove = (index) => {
    setPrices(prices.filter((_, i) => i !== index));
  };

  return (
    <>
      {prices?.map((_, index) => (
        <>
          <label className="form">
            <div className="priceArea" key={index}>
              <input
                type="text"
                placeholder="値下げ項目名"
                value={prices[index]?.name}
                name={`name_${index}`}
                onChange={(e) => {
                  handlePriceChange(e, index);
                }}
              />
              <input
                type="text"
                placeholder="値下げ前"
                value={prices[index]?.before}
                name={`before_${index}`}
                onChange={(e) => {
                  handlePriceChange(e, index);
                }}
              />
              <input
                type="text"
                placeholder="値下げ後"
                value={prices[index]?.after}
                name={`after_${index}`}
                onChange={(e) => {
                  handlePriceChange(e, index);
                }}
              />
              <div className="RemoveBtn">
                {prices?.length > 1 && (
                  <i name="minus circle" onClick={() => handleRemove(index)}>
                    <FontAwesomeIcon icon={faMinus} />
                  </i>
                )}
              </div>
            </div>
          </label>
        </>
      ))}
      <div className="addBtn" onClick={() => handleAdd()}>
        <p>項目を追加</p>
        <i name="plus circle">
          <FontAwesomeIcon icon={faSquarePlus} />
        </i>
      </div>
    </>
  );
};

export default React.memo(PriceArea);
