import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) || false
  );
  const [isSuperuser, setIsSuperuser] = useState(
    JSON.parse(localStorage.getItem("isSuperuser")) || false
  );

  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    localStorage.setItem("isSuperuser", isLoggedIn);
  }, [isSuperuser]);

  const logout = () => {
    setIsLoggedIn(false);
    setIsSuperuser(false);
  };

  const value = {
    isLoggedIn,
    setIsLoggedIn,
    isSuperuser,
    setIsSuperuser,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
