import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";

const LibraryWindow = ({
  tabs,
  selectedTab,
  setSelectedTab,
  newSingleImage,
  newMultipleImages,
  setNewSingleImage,
  setNewMultipleImages,
  newMultipleTopImages,
  setNewMultipleTopImages,
  isUploaded,
  setIsUploaded,
}) => {
  const [images, setImages] = useState([]); // 画像リスト
  const [recentImages, setRecentImages] = useState([]); // 最近の画像リスト
  const [favoriteImages, setFavoriteImages] = useState([]); // お気に入りの画像リスト
  const [selectedSingleImage, setSelectedSingleImage] = useState(null); // 選択した画像リスト（単一）
  const [selectedMultipleImages, setSelectedMultipleImages] = useState([]); // 選択した画像リスト（複数）
  const [selectedMultipleTopImages, setSelectedMultipleTopImages] = useState(
    []
  ); // 選択した画像リスト（トップ用複数）

  useEffect(() => {
    setSelectedSingleImage(newSingleImage);
  }, [newSingleImage]);

  useEffect(() => {
    setSelectedMultipleImages(newMultipleImages);
  }, [newMultipleImages]);

  useEffect(() => {
    setSelectedMultipleTopImages(newMultipleTopImages);
  }, [newMultipleTopImages]);

  // 最近の画像リストの取得
  const fetchRecentImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_recent_images/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setRecentImages(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // お気に入りの画像リストの取得
  const fetchFavoriteImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_favorite_images/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setFavoriteImages(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // ハッシュタグごとの画像リストの取得
  const fetchImagesDataByHashtag = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/get_images_by_hashtag_master/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setImages(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  });

  // 画像リストの取得
  useEffect(() => {
    fetchImagesDataByHashtag();
    fetchRecentImagesData();
    fetchFavoriteImagesData();
  }, []);

  // 画像がアップロードされたら最近のアップロードのリストを更新
  useEffect(() => {
    if (!isUploaded) return;
    fetchRecentImagesData();
    fetchImagesDataByHashtag();
    setIsUploaded(false);
  }, [isUploaded]);

  function isVideo(mimeType) {
    return mimeType.startsWith("video/");
  }

  return (
    <>
      <div className={`libraryWindow ${tabs.length <= 1 ? "notab" : ""}`}>
        {/*  */}

        {tabs.length > 1 && (
          <ul className="tabBtn_type1">
            {tabs.map((tab) => (
              <li
                key={tab.text}
                className={selectedTab.text === tab.text ? "active" : ""}
                onClick={() => setSelectedTab(tab)}
              >
                {tab.text}
              </li>
            ))}
          </ul>
        )}

        <div className="checked">
          {/* 選択中 */}
          <figure className="nowchecked">
            <p>選択中</p>
            {selectedTab.type === "single" && selectedSingleImage ? (
              <img
                src={selectedSingleImage.url}
                alt="Studio Image"
                loading="lazy"
                height="250"
                width="250"
              />
            ) : selectedTab.type === "multiple" &&
              selectedMultipleImages.length > 0 ? (
              <img
                src={
                  selectedMultipleImages[selectedMultipleImages.length - 1].url
                }
                alt="Studio Image"
                loading="lazy"
                height="250"
                width="250"
              />
            ) : selectedTab.type === "top" &&
              selectedMultipleTopImages.length > 0 ? (
              <img
                src={
                  selectedMultipleTopImages[
                    selectedMultipleTopImages.length - 1
                  ].url
                }
                alt="Studio Image"
                loading="lazy"
                height="250"
                width="250"
              />
            ) : null}
          </figure>
          {/* 選択済 */}
          <ul className="checkedList">
            <p>選択済み</p>
            {selectedTab.type === "single" && selectedSingleImage && (
              <li>
                <img
                  src={selectedSingleImage.url}
                  alt="Studio Image"
                  onClick={() => {
                    setSelectedSingleImage(null);
                  }}
                  loading="lazy"
                  height="100"
                  width="100"
                />
                <i>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </i>
              </li>
            )}

            {selectedTab.type === "multiple" &&
              selectedMultipleImages &&
              selectedMultipleImages.map((image, index) => (
                <li key={index}>
                  <img
                    src={image.url}
                    alt="Studio Image"
                    onClick={() => {
                      setSelectedMultipleImages((prevSelectedImages) =>
                        prevSelectedImages.filter((img) => img.id !== image.id)
                      );
                    }}
                    loading="lazy"
                    height="100"
                    width="100"
                  />
                  <i>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </i>
                </li>
              ))}

            {selectedTab.type === "top" &&
              selectedMultipleTopImages &&
              selectedMultipleTopImages.map((image, index) => (
                <li key={index}>
                  <img
                    src={image.url}
                    alt="Studio Image"
                    onClick={() => {
                      setSelectedMultipleTopImages((prevSelectedImages) =>
                        prevSelectedImages.filter((img) => img.id !== image.id)
                      );
                    }}
                    loading="lazy"
                    height="100"
                    width="100"
                  />
                  <i>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </i>
                </li>
              ))}
          </ul>

          {/* 追加ボタン */}
          <button
            className="type-2"
            onClick={() => {
              if (selectedTab.type === "single") {
                setNewSingleImage(selectedSingleImage);
              } else if (selectedTab.type === "multiple") {
                setNewMultipleImages(selectedMultipleImages);
              } else if (selectedTab.type === "top") {
                setNewMultipleTopImages(selectedMultipleTopImages);
              }
            }}
          >
            画像を追加する
          </button>
        </div>

        <div className="libraryList">
          {/*  */}
          <p className="ttl">ライブラリ</p>

          {favoriteImages && (
            <>
              <p className="hashtag">お気に入り</p>
              <ul>
                {favoriteImages
                  .filter((image) => !isVideo(image.mime_type))
                  .map((image) => (
                    <li key={image.id}>
                      <img
                        src={image.url}
                        alt={`Image ${image.id}`}
                        onClick={() => {
                          if (selectedTab.type === "single") {
                            setSelectedSingleImage(image);
                          } else if (selectedTab.type === "multiple") {
                            setSelectedMultipleImages((prevSelectedImages) => {
                              if (
                                prevSelectedImages.some(
                                  (img) => img.id === image.id
                                )
                              ) {
                                return prevSelectedImages.filter(
                                  (img) => img.id !== image.id
                                );
                              } else {
                                return [...prevSelectedImages, image];
                              }
                            });
                          } else if (selectedTab.type === "top") {
                            setSelectedMultipleTopImages(
                              (prevSelectedImages) => {
                                if (
                                  prevSelectedImages.some(
                                    (img) => img.id === image.id
                                  )
                                ) {
                                  return prevSelectedImages.filter(
                                    (img) => img.id !== image.id
                                  );
                                } else {
                                  return [...prevSelectedImages, image];
                                }
                              }
                            );
                          }
                        }}
                        loading="lazy"
                        height="100"
                        width="100"
                      />
                      {selectedTab.type === "single" &&
                        selectedSingleImage &&
                        selectedSingleImage.id === image.id && (
                          <i>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </i>
                        )}
                      {selectedTab.type === "multiple" &&
                        selectedMultipleImages &&
                        selectedMultipleImages.some(
                          (img) => img.id === image.id
                        ) && (
                          <i>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </i>
                        )}
                      {selectedTab.type === "top" &&
                        selectedMultipleTopImages &&
                        selectedMultipleTopImages.some(
                          (img) => img.id === image.id
                        ) && (
                          <i>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </i>
                        )}
                    </li>
                  ))}
              </ul>
            </>
          )}

          {recentImages && (
            <>
              <p className="hashtag">最近のアップロード</p>
              <ul>
                {recentImages
                  .filter((image) => !isVideo(image.mime_type))
                  .map((image) => (
                    <li key={image.id}>
                      <img
                        src={image.url}
                        alt={`Image ${image.id}`}
                        onClick={() => {
                          if (selectedTab.type === "single") {
                            setSelectedSingleImage(image);
                          } else if (selectedTab.type === "multiple") {
                            setSelectedMultipleImages((prevSelectedImages) => {
                              if (
                                prevSelectedImages.some(
                                  (img) => img.id === image.id
                                )
                              ) {
                                return prevSelectedImages.filter(
                                  (img) => img.id !== image.id
                                );
                              } else {
                                return [...prevSelectedImages, image];
                              }
                            });
                          } else if (selectedTab.type === "top") {
                            setSelectedMultipleTopImages(
                              (prevSelectedImages) => {
                                if (
                                  prevSelectedImages.some(
                                    (img) => img.id === image.id
                                  )
                                ) {
                                  return prevSelectedImages.filter(
                                    (img) => img.id !== image.id
                                  );
                                } else {
                                  return [...prevSelectedImages, image];
                                }
                              }
                            );
                          }
                        }}
                        loading="lazy"
                        height="100"
                        width="100"
                      />
                      {selectedTab.type === "single" &&
                        selectedSingleImage &&
                        selectedSingleImage.id === image.id && (
                          <i>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </i>
                        )}
                      {selectedTab.type === "multiple" &&
                        selectedMultipleImages &&
                        selectedMultipleImages.some(
                          (img) => img.id === image.id
                        ) && (
                          <i>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </i>
                        )}
                      {selectedTab.type === "top" &&
                        selectedMultipleTopImages &&
                        selectedMultipleTopImages.some(
                          (img) => img.id === image.id
                        ) && (
                          <i>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </i>
                        )}
                    </li>
                  ))}
              </ul>
            </>
          )}

          {images &&
            images.map((imageGroup) => (
              <div key={imageGroup.hashtag_master_id}>
                <p className="hashtag">{imageGroup.hashtag_master_text}</p>
                <ul>
                  {imageGroup.images
                    .filter((image) => !isVideo(image.mime_type))
                    .map((image) => (
                      <li key={image.id}>
                        <img
                          src={image.url}
                          alt={`Image ${image.id}`}
                          onClick={() => {
                            if (selectedTab.type === "single") {
                              setSelectedSingleImage(image);
                            } else if (selectedTab.type === "multiple") {
                              setSelectedMultipleImages(
                                (prevSelectedImages) => {
                                  if (
                                    prevSelectedImages.some(
                                      (img) => img.id === image.id
                                    )
                                  ) {
                                    return prevSelectedImages.filter(
                                      (img) => img.id !== image.id
                                    );
                                  } else {
                                    return [...prevSelectedImages, image];
                                  }
                                }
                              );
                            } else if (selectedTab.type === "top") {
                              setSelectedMultipleTopImages(
                                (prevSelectedImages) => {
                                  if (
                                    prevSelectedImages.some(
                                      (img) => img.id === image.id
                                    )
                                  ) {
                                    return prevSelectedImages.filter(
                                      (img) => img.id !== image.id
                                    );
                                  } else {
                                    return [...prevSelectedImages, image];
                                  }
                                }
                              );
                            }
                          }}
                          loading="lazy"
                          height="100"
                          width="100"
                        />
                        {selectedTab.type === "single" &&
                          selectedSingleImage &&
                          selectedSingleImage.id === image.id && (
                            <i>
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </i>
                          )}
                        {selectedTab.type === "multiple" &&
                          selectedMultipleImages &&
                          selectedMultipleImages.some(
                            (img) => img.id === image.id
                          ) && (
                            <i>
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </i>
                          )}
                        {selectedTab.type === "top" &&
                          selectedMultipleTopImages &&
                          selectedMultipleTopImages.some(
                            (img) => img.id === image.id
                          ) && (
                            <i>
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </i>
                          )}
                      </li>
                    ))}
                </ul>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default React.memo(LibraryWindow);
