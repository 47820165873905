import React, { useState, useEffect, useCallback, memo } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPenToSquare,
  faTimes, // ×アイコンを追加
} from "@fortawesome/pro-solid-svg-icons";
import Breadcrumbs from "../../../components/Breadcrumbs";
import "../../../styles/scss/website/lessonEdit.scss";

// タイムテーブル部分
const LessonEditContent = memo(
  ({
    startTime,
    endTime,
    timeArea,
    handleMouseDown,
    handleMouseUp,
    timeTexts,
    lessonList,
    calculateLessonTime,
    instructors,
    setLessonList,
    handleTimeChange,
  }) => {
    return (
      <>
        <div className="lessonEdit">
          {/* 表の時間を設定 */}
          <div className="setting">
            <p>タイムテーブルの時間を設定</p>
            <div className="timeSetting">
              <label className="date">
                <input
                  type="time"
                  name="start"
                  min="00:00"
                  max="23:45"
                  required
                  value={startTime}
                  onChange={(e) => handleTimeChange(e.target.value, "start")}
                />
              </label>
              <p>〜</p>
              <label className="date">
                <input
                  type="time"
                  name="end"
                  min="00:15"
                  max="24:00"
                  required
                  value={endTime}
                  onChange={(e) => handleTimeChange(e.target.value, "end")}
                />
              </label>
            </div>
          </div>

          {/* 表 */}
          <div
            className="lessonBaseGrid"
            style={{ gridTemplateRows: `repeat(${timeArea}, 1fr)` }}
          >
            {/* ベースレイアウト */}
            {/* 週 */}
            <ul
              className="week mon"
              style={{ gridRow: `1 / span ${timeArea}` }}
            >
              <li>月</li>
              {Array.from({ length: timeArea - 1 }).map((_, index) => (
                <li
                  key={index}
                  data-gridnum={index + 2}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                ></li>
              ))}
            </ul>
            <ul
              className="week tue"
              style={{ gridRow: `1 / span ${timeArea}` }}
            >
              <li>火</li>
              {Array.from({ length: timeArea - 1 }).map((_, index) => (
                <li
                  key={index}
                  data-gridnum={index + 2}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                ></li>
              ))}
            </ul>
            <ul
              className="week wed"
              style={{ gridRow: `1 / span ${timeArea}` }}
            >
              <li>水</li>
              {Array.from({ length: timeArea - 1 }).map((_, index) => (
                <li
                  key={index}
                  data-gridnum={index + 2}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                ></li>
              ))}
            </ul>
            <ul
              className="week thu"
              style={{ gridRow: `1 / span ${timeArea}` }}
            >
              <li>木</li>
              {Array.from({ length: timeArea - 1 }).map((_, index) => (
                <li
                  key={index}
                  data-gridnum={index + 2}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                ></li>
              ))}
            </ul>
            <ul
              className="week fri"
              style={{ gridRow: `1 / span ${timeArea}` }}
            >
              <li>金</li>
              {Array.from({ length: timeArea - 1 }).map((_, index) => (
                <li
                  key={index}
                  data-gridnum={index + 2}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                ></li>
              ))}
            </ul>
            <ul
              className="week sat"
              style={{ gridRow: `1 / span ${timeArea}` }}
            >
              <li>土</li>
              {Array.from({ length: timeArea - 1 }).map((_, index) => (
                <li
                  key={index}
                  data-gridnum={index + 2}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                ></li>
              ))}
            </ul>
            <ul
              className="week sun"
              style={{ gridRow: `1 / span ${timeArea}` }}
            >
              <li>日</li>
              {Array.from({ length: timeArea - 1 }).map((_, index) => (
                <li
                  key={index}
                  data-gridnum={index + 2}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                ></li>
              ))}
            </ul>

            {/* 出力 */}
            <ul
              className="timeText"
              style={{
                gridTemplateRows: `subgrid`,
                gridColumn: "1/2",
                gridRow: `1 / ${timeArea + 2}`,
              }}
            >
              <li></li>
              {timeTexts.map((time, index) => (
                <li key={index} className="text">
                  <span>{time}</span>
                </li>
              ))}
            </ul>

            {/* レッスン */}
            <ul
              className="lesson"
              style={{
                gridTemplateRows: `subgrid`,
                gridRow: `2 / span ${timeArea - 1}`,
              }}
            >
              {/* done : 完了 / notDone : 未完了 */}
              {lessonList.map((lesson, i) => {
                const { start, end } = calculateLessonTime(
                  lesson.min,
                  lesson.max
                ); // レッスン時間を計算
                return (
                  <li
                    key={lesson.id ? `lesson-${lesson.id}` : `index-${i}`}
                    className={`lessonItem notDone ${lesson.week}`}
                    style={{
                      gridRow: `${lesson.min - 1} / ${lesson.max}`,
                    }}
                  >
                    <i
                      className="closeBtn"
                      onClick={() => {
                        const newLessonList = lessonList.filter(
                          (_, index) => index !== i
                        );
                        setLessonList(newLessonList);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </i>
                    <div className="lessonData">
                      <div className="time">
                        <p>
                          {start} - {end}
                        </p>{" "}
                        {/* 計算された時間を表示 */}
                      </div>
                      <select
                        className="instructor"
                        defaultValue={lesson.instructor}
                        onChange={(e) => {
                          const newLessonList = [...lessonList];
                          const selectedInstructor = instructors.find(
                            (instructor) =>
                              instructor.username === e.target.value
                          );
                          newLessonList[i].instructor = e.target.value;
                          newLessonList[i].instructor_id = selectedInstructor
                            ? selectedInstructor.id
                            : null;
                          setLessonList(newLessonList);
                        }}
                      >
                        <option value="">-</option>
                        {instructors.map((instructor) => (
                          <option
                            key={instructor.id}
                            value={instructor.username}
                          >
                            {instructor.username}
                          </option>
                        ))}
                      </select>
                      <div className="lessonName">
                        <input
                          type="text"
                          value={lesson.genre}
                          onChange={(e) => {
                            const newLessonList = [...lessonList];
                            newLessonList[i].genre = e.target.value;
                            setLessonList(newLessonList);
                          }}
                        />
                      </div>
                      <div className="lessonName">
                        <input
                          type="text"
                          value={lesson.level}
                          onChange={(e) => {
                            const newLessonList = [...lessonList];
                            newLessonList[i].level = e.target.value;
                            setLessonList(newLessonList);
                          }}
                        />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </>
    );
  }
);

function LessonEditPage() {
  const { studioId } = useParams();
  const [lessons, setLessons] = useState([]);

  const breadcrumbs = [
    { title: "スタジオ一覧", path: `/website/studio-list` },
    {
      title: "レッスン詳細",
      path: `/website/lesson-edit/${studioId}`,
    },
  ];

  const [layout, setLayout] = React.useState("4");

  // 表を計算するステート
  const [timeArea, setTimeArea] = useState(24 + 1);

  const [startTime, setStartTime] = useState("18:00");
  const [endTime, setEndTime] = useState("22:00");
  const [timeTexts, setTimeTexts] = useState([]);
  const [instructors, setInstructors] = useState([]);

  // スタジオデータを取得し、開始時間と終了時間を設定
  const fetchStudioData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_studio/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studio_id: studioId,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      if (data.start_time && data.end_time) {
        setStartTime(data.start_time.slice(0, 5));
        setEndTime(data.end_time.slice(0, 5));
      }
    } catch (error) {
      console.error("スタジオデータの取得に失敗しました", error);
    }
  }, [studioId]);

  // レッスンデータの取得
  const fetchLessonData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_lessons/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studio_id: studioId,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      setLessons(data);
    } catch (error) {
      console.error("レッスンデータの取得に失敗しました", error);
    }
  }, [studioId]);

  // インストラクター一覧取得
  const fetchInstructorData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_instructors/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setInstructors(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [studioId]);

  // 最初に必要な時データを取得
  useEffect(() => {
    fetchStudioData();
    fetchLessonData();
    fetchInstructorData();
  }, [studioId]);

  // レッスンの時間を基にgrid番号を取得し、編集用のレッスンリストに追加
  useEffect(() => {
    setLessonList([]);
    lessons.forEach((lesson) => {
      const { min, max } = calculateGridNumbers(
        lesson.start_time,
        lesson.end_time
      );
      setLessonList((prevLessonList) => {
        const newLesson = {
          min: min,
          max: max,
          week: lesson.day_of_week.slice(0, 3),
          id: lesson.id,
          instructor_id: lesson.instructor_id,
          instructor: lesson.instructor_name,
          genre: lesson.genre,
          level: lesson.level,
          start_time: lesson.start_time,
          end_time: lesson.end_time,
        };
        const isDuplicate = prevLessonList.some(
          (lesson) =>
            lesson.min === newLesson.min &&
            lesson.max === newLesson.max &&
            lesson.week === newLesson.week
        );
        if (isDuplicate) {
          return prevLessonList;
        }
        return [...prevLessonList, newLesson];
      });
    });
  }, [lessons]);

  // 開始時間変更で、grid番号を取得しなおす
  useEffect(() => {
    const oldLessonList = lessonList;
    setLessonList([]);

    oldLessonList.forEach((lesson) => {
      const { min, max } = calculateGridNumbers(
        lesson.start_time,
        lesson.end_time
      );
      setLessonList((prevLessonList) => {
        const newLesson = {
          min: min,
          max: max,
          week: lesson.week,
          id: lesson.id,
          instructor_id: lesson.instructor_id,
          instructor: lesson.instructor,
          genre: lesson.genre,
          level: lesson.level,
          start_time: lesson.start_time,
          end_time: lesson.end_time,
        };
        const isDuplicate = prevLessonList.some(
          (lesson) =>
            lesson.min === newLesson.min &&
            lesson.max === newLesson.max &&
            lesson.week === newLesson.week
        );
        if (isDuplicate) {
          return prevLessonList;
        }
        return [...prevLessonList, newLesson];
      });
    });
  }, [startTime]);

  // starTimeとendTimeを基にgridの縦の数を計算する
  // startTimeからendTimeまでの1時間おきの配列を作成する
  const calculateTimeArea = (startTime, endTime) => {
    // 時間として正しく無ければ後続処理しない
    if (
      !startTime ||
      !endTime ||
      !/^\d{2}:\d{2}$/.test(startTime) ||
      !/^\d{2}:\d{2}$/.test(endTime)
    ) {
      return;
    }

    // startとendが逆転している場合は処理しない
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);
    if (
      startHour > endHour ||
      (startHour === endHour && startMinute >= endMinute)
    ) {
      return;
    }

    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;
    const differenceInMinutes = endTotalMinutes - startTotalMinutes;
    const timeArea = Math.floor(differenceInMinutes / 5) + 1;
    setTimeArea(timeArea);

    // timeTextsのstateに1時間ごとの時間の文字列を格納
    const timeTextsArray = [];
    for (let i = startHour; i <= endHour; i++) {
      timeTextsArray.push(
        `${i.toString().padStart(2, "0")}:${startMinute
          .toString()
          .padStart(2, "0")}`
      );
    }
    setTimeTexts(timeTextsArray);
  };

  useEffect(() => {
    calculateTimeArea(startTime, endTime);
  }, [startTime, endTime]);

  // ドラッグ&ドロップでレッスンの追加の処理
  const [draggedGridNums, setDraggedGridNums] = useState({
    min: null,
    max: null,
  });

  const [lessonList, setLessonList] = useState([]);

  const handleMouseDown = (e) => {
    const gridNum = e.target.getAttribute("data-gridnum");
    // 親要素からクラス名を取得して曜日を判定
    const weekDay = e.target.parentNode.classList.contains("mon")
      ? "mon"
      : e.target.parentNode.classList.contains("tue")
      ? "tue"
      : e.target.parentNode.classList.contains("wed")
      ? "wed"
      : e.target.parentNode.classList.contains("thu")
      ? "thu"
      : e.target.parentNode.classList.contains("fri")
      ? "fri"
      : e.target.parentNode.classList.contains("sat")
      ? "sat"
      : e.target.parentNode.classList.contains("sun")
      ? "sun"
      : "";
    setDraggedGridNums((draggedGridNums) => ({
      ...draggedGridNums,
      min: gridNum,
      week: weekDay,
    }));
  };

  const handleMouseUp = (e) => {
    // maxしか設定されてない場合はリセットしてreturn
    if (draggedGridNums.min === null) {
      setDraggedGridNums({ min: null, max: null, week: null });
      return;
    }
    const gridNum = e.target.getAttribute("data-gridnum");
    setDraggedGridNums((draggedGridNums) => ({
      ...draggedGridNums,
      max: gridNum,
    }));
  };

  useEffect(() => {
    if (draggedGridNums.min && draggedGridNums.max && draggedGridNums.week) {
      const { start, end } = calculateLessonTime(
        draggedGridNums.min,
        draggedGridNums.max
      );
      setLessonList((lessonList) => [
        ...lessonList,
        {
          min: draggedGridNums.min,
          max: draggedGridNums.max,
          week: draggedGridNums.week,
          id: null,
          instructor_id: null,
          instructor: "",
          genre: "",
          level: "",
          start_time: start,
          end_time: end,
        },
      ]);
      setDraggedGridNums({ min: null, max: null, week: null });
    }
  }, [draggedGridNums]);

  ////ここまで////

  useEffect(() => {
    const handleMouseMove = (e) => {
      const lessonItems = document.querySelectorAll(".lessonItem");
      const lesson = document.querySelector(".lesson");
      let isMouseOverLessonItem = false;

      lessonItems.forEach((item) => {
        const rect = item.getBoundingClientRect();
        if (
          e.clientX >= rect.left &&
          e.clientX <= rect.right &&
          e.clientY >= rect.top &&
          e.clientY <= rect.bottom
        ) {
          isMouseOverLessonItem = true;
        }
      });

      if (isMouseOverLessonItem) {
        lesson.style.zIndex = "2";
      } else {
        lesson.style.zIndex = "0";
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // レッスン時間の計算
  // レッスン時間の計算を行う関数を追加
  const calculateLessonTime = (min, max) => {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const start = new Date(0, 0, 0, startHour, startMinute); // startTimeを基準時間とする
    const end = new Date(0, 0, 0, startHour, startMinute); // こちらもstartTimeを基準とする

    start.setMinutes(start.getMinutes() + (min - 2) * 5); // min * 10分を加算
    end.setMinutes(end.getMinutes() + (max - 1) * 5); // max * 10分を加算

    const formatTime = (date) =>
      `${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;

    return {
      start: formatTime(start),
      end: formatTime(end),
    };
  };

  // grid番号の計算
  // レッスンの開始時間と終了時間を基に、grid番号のminとmaxを計算する関数
  const calculateGridNumbers = (start, end) => {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const baseTime = new Date(0, 0, 0, startHour, startMinute); // startTimeを基準時間とする

    const [startLessonHour, startLessonMinute] = start.split(":").map(Number);
    const startLessonTime = new Date(
      0,
      0,
      0,
      startLessonHour,
      startLessonMinute
    );

    const [endLessonHour, endLessonMinute] = end.split(":").map(Number);
    const endLessonTime = new Date(0, 0, 0, endLessonHour, endLessonMinute);

    const diffStart = (startLessonTime - baseTime) / (5 * 60 * 1000); // 分単位の差を計算し、10分で割る
    const diffEnd = (endLessonTime - baseTime) / (5 * 60 * 1000); // 分単位の差を計算し、10分で割る

    const min = diffStart + 2;
    const max = diffEnd + 1;

    return {
      min: min.toString(),
      max: max.toString(),
    };
  };

  // 時間変更時バリデーション
  const handleTimeChange = (value, type) => {
    const lessonTimes = lessonList.map((lesson) => ({
      start: lesson.start_time,
      end: lesson.end_time,
    }));
    const minLessonTime = Math.min(
      ...lessonTimes.map((time) =>
        new Date(`1970-01-01T${time.start}:00`).getTime()
      )
    );
    const maxLessonTime = Math.max(
      ...lessonTimes.map((time) =>
        new Date(`1970-01-01T${time.end}:00`).getTime()
      )
    );

    if (type === "start") {
      const newStartTime = new Date(`1970-01-01T${value}:00`).getTime();
      if (value >= endTime) {
        alert("開始時間は終了時間より前でなければなりません。");
        return;
      }
      if (newStartTime > minLessonTime) {
        alert("開始時間はレッスンの最小時間より前でなければなりません。");
        return;
      }
      setStartTime(value);
    } else if (type === "end") {
      const newEndTime = new Date(`1970-01-01T${value}:00`).getTime();
      if (value <= startTime) {
        alert("終了時間は開始時間より後でなければなりません。");
        return;
      }
      if (newEndTime < maxLessonTime) {
        alert("終了時間はレッスンの最大時間より後でなければなりません。");
        return;
      }
      setEndTime(value);
    }
  };

  // 英語の曜日を日本語に変換
  const getJapaneseDayOfWeek = (day) => {
    const dayOfWeekMapping = {
      mon: "月",
      tue: "火",
      wed: "水",
      thu: "木",
      fri: "金",
      sat: "土",
      sun: "日",
    };

    return dayOfWeekMapping[day] || "";
  };

  // インストラクター情報を更新
  const handleLessonDataSubmit = async () => {
    const errMsg = [];
    lessonList.forEach((lesson, index) => {
      if (!lesson.instructor || !lesson.genre || !lesson.level) {
        errMsg.push(
          `${getJapaneseDayOfWeek(lesson.week)}の${lesson.start_time}~${
            lesson.end_time
          }のデータに不備があります`
        );
      }
    });
    if (errMsg.length !== 0) {
      alert(errMsg.join("\n"));
      return;
    }

    const newLessonList = lessonList.map((lesson) => {
      const { start, end } = calculateLessonTime(lesson.min, lesson.max);
      return {
        ...lesson,
        min: start,
        max: end,
      };
    });

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/update_lessons/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lessons: newLessonList,
          start_time: startTime,
          end_time: endTime,
          studio_id: studioId,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      alert("レッスンを更新しました");
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    console.log(lessonList);
  }, [lessonList]);

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <div className="add">
          <button className="type-1 type-grad" onClick={handleLessonDataSubmit}>
            この内容で更新
          </button>
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <LessonEditContent
          startTime={startTime}
          endTime={endTime}
          timeArea={timeArea}
          handleMouseDown={handleMouseDown}
          handleMouseUp={handleMouseUp}
          timeTexts={timeTexts}
          lessonList={lessonList}
          calculateLessonTime={calculateLessonTime}
          instructors={instructors}
          setLessonList={setLessonList}
          handleTimeChange={handleTimeChange}
        />
      </div>
      <div className={`content-grid grid-3 layout-type-${layout}`}></div>
    </>
  );
}

export default LessonEditPage;
