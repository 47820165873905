import React, { useState, useEffect, useCallback, memo } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, GridRow, GridColumn } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import Breadcrumbs from "../../../components/Breadcrumbs";
import "../../../styles/scss/website/studioList.scss";

const StudioEditContent = memo(
  ({ studio, handleInputChange, handleStudioDataSubmit }) => {
    return (
      <>
        <p className="ttl">新しいスタジオを追加</p>
        {/* <p className="ttl">スタジオを編集</p> */}
        <div className="List">
          <ul className="gridList studioEditList">
            <li>店舗名</li>
            <li>
              <label className="form">
                <input
                  type="text"
                  name="name"
                  value={studio?.name}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>英語表記</li>
            <li>
              <label className="form">
                <input
                  type="text"
                  name="slug"
                  value={studio?.slug}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>住所</li>
            <li>
              <label className="form">
                <input
                  type="text"
                  name="address"
                  value={studio?.address}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li className="submit">
              <button
                type="submit"
                className="submit type-3 type-3"
                onClick={handleStudioDataSubmit}
              >
                スタジオを追加する
              </button>
            </li>
          </ul>
        </div>
      </>
    );
  }
);

function StudioListPage() {
  const [studios, setStudios] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [studio, setStudio] = useState({});

  const breadcrumbs = [{ title: "スタジオ一覧", path: `/website/studio-list` }];

  // スタジオ一覧取得
  const fetchStudiosData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_studios/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setStudios(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  useEffect(() => {
    fetchStudiosData();
  }, [fetchStudiosData]);

  // 入力フォーム変更
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "slug") {
      // 半角英数字と半角アンダーバーのみを許可
      const sanitizedValue = value.replace(/[^a-zA-Z0-9_]/g, "");
      if (value !== sanitizedValue) {
        alert(
          "「英語表記」には半角英数字と半角アンダーバーしか使用できません。"
        );
      }
      setStudio((prevStudio) => ({
        ...prevStudio,
        [name]: sanitizedValue,
      }));
    } else {
      setStudio((prevStudio) => ({
        ...prevStudio,
        [name]: value,
      }));
    }
  };

  // スタジオ追加
  const handleStudioDataSubmit = async () => {
    if (!studio.name || !studio.slug || !studio.address) {
      alert("すべてのフィールドに値を入力してください。");
      return;
    }
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/create_studio/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: studio.name,
          address: studio.address,
          slug: studio.slug,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      alert(data.message);
      setStudio({});
      fetchStudiosData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // スタジオ削除
  const handleStudioDataDelete = async (studio_id) => {
    const confirmation = window.confirm(
      "店舗情報の削除は取り消せません。\n紐づくレッスン情報や画像なども削除されます。\n本当に削除しますか？"
    );
    if (!confirmation) {
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/delete_studio/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studio_id: studio_id,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }
      alert(data.message);
      fetchStudiosData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 近い店舗の更新
  const updateNearbyStudios = async () => {
    if (
      window.confirm("古い店舗間の距離情報は破棄されます。本当に実行しますか？")
    ) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/update_nearby_studios/`, {
          method: "POST",
        });
        if (!response.ok) {
          throw new Error("ネットワークレスポンスが異常です。");
        }
        alert("店舗間の距離情報を更新しました。");
      } catch (error) {
        console.error("データの更新中にエラーが発生しました:", error);
        alert("店舗間の距離情報の更新に失敗しました。");
      }
    }
  };

  /////////////////

  //　新　//

  ////////////////

  // レイアウト
  // レイアウトを設定するためのstate
  const [layout, setLayout] = useState("4");

  const StudioListContent = () => {
    return (
      <div className="studioListcontent">
        <div className="List">
          <div className="gridList">
            {studios.map((studio) => (
              <ul className="listContents">
                <Link to={`/website/studio-edit/${studio.id}`}>
                  <li>
                    <i>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </i>
                    <span>{studio.name}</span>
                  </li>
                </Link>
                <Link
                  to={`https://rudofactory.jp/studio/${studio.slug}`}
                  target="_blank"
                >
                  <li>
                    <span>https://rudofactory.jp/studio/{studio.slug}</span>
                  </li>
                </Link>
                <Link to={`/website/lesson-edit/${studio.id}`}>
                  <li>
                    <button className="type-2">レッスンテーブル</button>
                  </li>
                </Link>
                {studio.is_published == true && (
                  <li className="published">
                    <i>
                      <FontAwesomeIcon icon={faCheck} />
                    </i>
                    <span>公開</span>
                  </li>
                )}
                <li
                  className="delete"
                  onClick={() => {
                    handleStudioDataDelete(studio.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <div className="add">
          <button
            className="type-1"
            onClick={() => {
              setIsOpen(!isOpen);
              setLayout(isOpen ? "4" : "1");
            }}
          >
            スタジオを追加
          </button>
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <StudioListContent />
      </div>
      <div
        className={`content-grid grid-3 layout-type-${layout}`}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <StudioEditContent
          studio={studio}
          handleInputChange={handleInputChange}
          handleStudioDataSubmit={handleStudioDataSubmit}
        />
      </div>
    </>
  );
}

export default StudioListPage;
