import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
} from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "../../../styles/scss/website/campaign.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-light-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import PriceArea from "../../../components/website/campaign/PriceArea";

import Iframe from "react-iframe";

const ForwardedIframe = forwardRef((props, ref) => (
  <Iframe {...props} innerRef={ref} />
));

const CampaignEditPage = () => {
  const { id } = useParams(); // ルートパラメータの`id`を取得
  const navigate = useNavigate();
  const location = useLocation();
  const [campaign, setCampaign] = useState({
    id: null,
    slug: "",
    default_studio: "",
    target_studios: [],
    title: "",
    text: "",
    price_title: "",
    images: [],
  });
  const [prices, setPrices] = useState([{ name: "", before: "", after: "" }]);

  const [page, setPage] = useState(1); // ページネーション用
  const [searchWord, setSearchWord] = useState(""); // 検索文字

  const [parentUpdated, setParentUpdated] = useState(false); // 子コンポーネントで更新が必要な場合にtrueにする

  const [overlay, setOverlay] = useState(false); // オーバーレイの制御
  const [images, setImages] = useState([]); // 画像リスト
  const [checkImages, setCheckImages] = useState([]); // 選択した画像
  const [nowImage, setNowImage] = useState([]); // 現在選択中の画像
  const [studios, setStudios] = useState([]);

  const [checkedStudio, setCheckedStudio] = useState(null); // チェックされているラジオボタンのスタジオ
  const [checkedStudios, setCheckedStudios] = useState([]); // チェックされているチェックボックスのスタジオ

  // スタジオリストの取得
  const fetchStudiosData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_studios/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setStudios(data);
      const newOptions = data.map((studio) => ({
        key: studio.id,
        value: studio.id,
        text: studio.name,
      }));
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  useEffect(() => {
    fetchStudiosData();
  }, [fetchStudiosData]);

  // 子コンポーネントに渡して更新を検知する関数
  const handleUpdate = useCallback(() => {
    // 更新が必要なときに実行する処理
    fetchImagesData();
    fetchCampaignData();
  }, []);

  // 画像リストの取得
  const fetchImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: page,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      if (page === 1) {
        setImages(data);
      } else {
        setImages((prevImages) => {
          const newImages = data.filter(
            (newImage) =>
              !prevImages.some((prevImage) => prevImage.id === newImage.id)
          );
          return [...prevImages, ...newImages];
        });
      }
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [page]);

  // キャンペーンの取得
  const fetchCampaignData = useCallback(async () => {
    if (!id) {
      setCampaign({
        title: "🎉新規生徒大募集🎉\n✨7月限定キャンペーン開催中✨",
        text: "7月限定で入会金半額キャンペーンを実施中です🩷\n全レッスン1回ずつ無料体験もできますので是非ご応募ください🫧",
        price_title: "7月のキャンペーン",
      });
      setPrices([
        { name: "入会金", before: 5610, after: 0 },
        { name: "体験レッスン", before: 1980, after: 0 },
      ]);
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_campaign/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      // price_detailsをパース
      data.price_details = JSON.parse(data.price_details);
      setCampaign(data);
      if (data.default_studio !== null) {
        setCheckedStudio(data.default_studio);
      }
      if (data.target_studios !== null) {
        setCheckedStudios(JSON.parse(data.target_studios));
      }
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [id]);

  // inputタグが更新された時にstateを更新する関数
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCampaign((prev) => ({ ...prev, [name]: value }));
  };

  // submitボタンが押下された時に動く関数
  const handleSubmit = async (e) => {
    e.preventDefault();

    // バリデーション
    if (!campaign.slug) {
      alert("エラー: スラッグは必須です。");
      return;
    }
    if (!campaign.price_title) {
      alert("エラー: 値下げタイトルは必須です。");
      return;
    }
    for (let i = 0; i < prices.length; i++) {
      if (
        !prices[i].name ||
        (!prices[i].before && prices[i].before !== 0) ||
        (!prices[i].after && prices[i].after !== 0)
      ) {
        alert("エラー: 値下げ詳細は必須です。");
        return;
      }
      if (isNaN(prices[i].before) || isNaN(prices[i].after)) {
        alert("エラー: 値下げ前、値下げ後は数値で入力して下さい。");
        return;
      }
    }

    const formData = new FormData();

    formData.append("campaign_id", campaign.id);
    formData.append("slug", campaign.slug);
    formData.append("default_studio", checkedStudio);
    formData.append("target_studios", JSON.stringify(checkedStudios));
    formData.append("title", campaign.title);
    formData.append("text", campaign.text);
    formData.append("price_title", campaign.price_title);
    formData.append("price_details", JSON.stringify(prices));
    const imageIds = checkImages
      ? checkImages.map((image) => image.id).join(",")
      : null;
    formData.append("image_ids", imageIds);

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/campaign_edit/`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        const data = await response.json();
        alert(`エラー: ${data.error}`);
        return;
      }

      const data = await response.json();
      const newPath = `/website/campaign-edit/${data.campaign.id}`;
      if (location.pathname === newPath) {
        // 既に必要なページにいる場合、stateを更新
        fetchCampaignData();
        fetchImagesData();
        setNowImage([]);
        setParentUpdated(true);
        alert("更新しました");
      } else {
        // 新規作成ページにいる場合、編集ページへ遷移
        alert("保存しました");
        navigate(newPath);
      }
    } catch (error) {
      alert("登録に失敗しました");
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 検索欄の状態を監視
  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
  };

  // 画像の検索
  const searchImagesData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_images/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: 1,
          search_word: searchWord,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setImages(data);
      setPage(1);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  };

  // 画像選択時にstateにセット
  const handleCheckImages = (image) => {
    // 現在選択中の画像にセット
    setNowImage(image);

    setCheckImages((prev) => {
      // 既に同じ画像が存在するか確認
      const isImageAlreadyAdded = prev.some(
        (prevImage) => prevImage.id === image.id
      );
      if (!isImageAlreadyAdded) {
        // 画像が存在しない場合、追加
        return [...prev, image];
      }
      // 画像が既に存在する場合、その画像をstateから削除
      return prev.filter((prevImage) => prevImage.id !== image.id);
    });
  };

  // オーバーレイの表示/非表示
  const toggleOverlay = (bool) => {
    setOverlay(bool);
  };

  // ビデオかどうか
  const isVideo = (url) => {
    // 動画ファイルの拡張子リスト
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    // URLの末尾の拡張子を確認
    return videoExtensions.some((extension) => url.endsWith(extension));
  };

  // キャンペーンの取得
  useEffect(() => {
    fetchCampaignData();
  }, [fetchCampaignData]);

  // 画像リストの取得
  useEffect(() => {
    fetchImagesData();
  }, [fetchImagesData]);

  useEffect(() => {
    if (JSON.stringify(campaign.images) !== JSON.stringify(checkImages)) {
      setCheckImages(campaign.images);
    }

    if (JSON.stringify(campaign.price_details) !== JSON.stringify(prices)) {
      if (campaign.price_details === undefined) return;

      setPrices(campaign.price_details);
    }
  }, [campaign]);

  // レイアウト
  // レイアウトを設定するためのstate
  const [layout, setLayout] = useState("2");

  const breadcrumbs = [
    { title: "キャンペーン一覧", path: `/website/campaign-list` },
    { title: "9月キャンペーン", path: `` },
  ];

  const origin = process.env.REACT_APP_ORIGIN;
  const iframeRef = useRef(null);

  // 管理画面 iframe用
  useEffect(() => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const updateIframeContent = () => {
        try {
          iframe.contentWindow.postMessage(
            { campaign, prices },
            id
              ? `${origin}/newvisitor/${campaign.slug}`
              : `${origin}/newvisitor`
          );
        } catch (error) {
          console.error("クロスオリジンのエラーが発生しました:", error);
        }
      };

      iframe.onload = updateIframeContent;
      updateIframeContent();
    }
  }, [campaign, prices]);

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        {/* サイト表示 */}
        <div className="siteView">
          <ForwardedIframe
            ref={iframeRef}
            url={
              id
                ? `${origin}/newvisitor/${campaign.slug}`
                : `${origin}/newvisitor`
            }
            width="100%"
            height="1000px"
          />
        </div>
      </div>
      <div className={`content-grid grid-3 layout-type-${layout}`}>
        <div className="textForm">
          <ul>
            <li className="heroheader">
              <p>ヒーローヘッダー</p>

              {/*  画像がないとき */}
              <div className="upload">
                {/* アップロード */}
                <div
                  className="upload-area"
                  // onDrop={handleDrop}
                  // onDragOver={handleDragOver}
                >
                  {/*  */}
                  <FontAwesomeIcon icon={faImage} />
                  <p>
                    画像/動画をドラッグ&ドロップ
                    <br />
                    (最大12MB)
                  </p>
                </div>
                {/* 画像を選択 */}
                <button className="imgSelectBtn">画像を選択</button>
              </div>

              {/*  画像選択後 */}
              {checkImages && checkImages.length > 0 && (
                <div className="imgChecked">
                  {checkImages.map((image) => (
                    <figure key={image.id}>
                      <img src={image.image_url} alt="" />
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        className="remove-icon"
                        onClick={() => {
                          setCheckImages((prevImages) =>
                            prevImages.filter((img) => img.id !== image.id)
                          );
                        }}
                      />
                    </figure>
                  ))}
                </div>
              )}
            </li>

            {/* ラジオボタン */}
            <li className="radio-form">
              <p className="ttl">デフォルト店舗</p>
              <div className="radio">
                {studios &&
                  studios.map((studio) => {
                    return (
                      <>
                        <input
                          id={`radio${studio.id}`}
                          type="radio"
                          checked={checkedStudio == studio.id}
                          onChange={() => {
                            setCheckedStudio(studio.id);
                          }}
                        />
                        <label htmlFor={`radio${studio.id}`}>
                          <p>{studio.name}校</p>
                        </label>
                      </>
                    );
                  })}
              </div>
            </li>

            {/* チェックボックス */}
            <li className="checkbox-form">
              <p className="ttl">対象店舗</p>
              <div className="checkbox">
                {studios &&
                  studios.map((studio) => {
                    return (
                      <>
                        <input
                          id={`check${studio.id}`}
                          type="checkbox"
                          checked={checkedStudios.includes(studio.id)}
                          onChange={() => {
                            setCheckedStudios((prevStudios) => {
                              if (prevStudios.includes(studio.id)) {
                                return prevStudios.filter(
                                  (id) => id !== studio.id
                                );
                              } else {
                                return [...prevStudios, studio.id];
                              }
                            });
                          }}
                        />
                        <label htmlFor={`check${studio.id}`}>
                          <i>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                          <p>{studio.name}校</p>
                        </label>
                      </>
                    );
                  })}
              </div>
            </li>

            <li>
              <p>スラッグ</p>
              <label className="form">
                <input
                  type="text"
                  name="slug"
                  value={campaign?.slug}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>
              <p>タイトル</p>
              <label className="form">
                <textarea
                  name="title"
                  value={campaign?.title}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>
              <p>本文</p>
              <label className="form">
                <textarea
                  name="text"
                  value={campaign?.text}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>
              <p>割引タイトル</p>
              <label className="form">
                <textarea
                  name="price_title"
                  value={campaign?.price_title}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li className="priceAreaWrap">
              <p className="form-label">値下げ詳細</p>
              <PriceArea prices={prices} setPrices={setPrices} />
            </li>
            <li className="submit">
              <button
                type="submit"
                className="submit type-3 type-3"
                onClick={handleSubmit}
              >
                更新
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="overlay" style={{ display: "none" }}>
        <div className="ov-inner">
          {/*  */}
          <div className="imgList">
            {/*  */}
            {Array.from({ length: 20 }).map((_, index) => (
              <>
                <p className="ov-ttl" key={index}>
                  Hashtag 01
                </p>
                <ul>
                  {Array.from({ length: 20 }).map((_, subIndex) => (
                    <li className="on" key={subIndex}>
                      <img
                        src="https://via.placeholder.com/150"
                        alt={`サンプル画像 ${subIndex + 1}`}
                      />
                    </li>
                  ))}
                </ul>
              </>
            ))}
          </div>
          {/*  */}
          <div className="imgPreview">
            {/*  */}
            <div className="">
              <p className="ov-ttl">プレビュー</p>
              <figure className="imgCenter">
                <img src="https://via.placeholder.com/150" alt={``} />
              </figure>
            </div>

            {/*  */}
            <div className="">
              <p className="ov-ttl">選択済み</p>
              <div className="imgList">
                {/*  */}
                <>
                  <ul>
                    {Array.from({ length: 10 }).map((_, subIndex) => (
                      <li className="on" key={subIndex}>
                        <img
                          src="https://via.placeholder.com/150"
                          alt={`サンプル画像 ${subIndex + 1}`}
                        />
                      </li>
                    ))}
                  </ul>
                </>
              </div>
            </div>
            {/* サブミット */}
            <button>画像を追加</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignEditPage;
