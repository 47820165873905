import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-light-svg-icons";

const VideoSelect = ({
  setVideoSelectIsOpen,
  videoSmall,
  videoMedium,
  videoLarge,
  videoExtraLarge,
  setVideoSmall,
  setVideoMedium,
  setVideoLarge,
  setVideoExtraLarge,
}) => {
  const [images, setImages] = useState([]); // 画像リスト
  const [recentImages, setRecentImages] = useState([]); // 最近の画像リスト
  const [favoriteImages, setFavoriteImages] = useState([]); // お気に入りの画像リスト
  const [tempSelected, setTempSelected] = useState({}); // 一時的な変数

  const [defaultIsOpen, setDefaultIsOpen] = useState(false); // デフォルトのオーバーレイ
  const [uploadIsOpen, setUploadIsOpen] = useState(false); // アップロードのオーバーレイ
  const [selectIsOpen, setSelectIsOpen] = useState(false); // 選択のオーバーレイ

  const [targetVideoSize, setTargetVideoSize] = useState(""); // 差替え対象の動画

  const [oldVideoSmall, setOldVideoSmall] = useState(""); // 既存の動画（small）
  const [oldVideoMedium, setOldVideoMedium] = useState(""); // 既存の動画（medium）
  const [oldVideoLarge, setOldVideoLarge] = useState(""); // 既存の動画（large）
  const [oldVideoExtraLarge, setOldVideoExtraLarge] = useState(""); // 既存の動画（extra large）

  // 最近の画像リストの取得
  const fetchRecentImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_recent_videos/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setRecentImages(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // お気に入りの画像リストの取得
  const fetchFavoriteImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_favorite_videos/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setFavoriteImages(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // 画像リストの取得
  useEffect(() => {
    fetchRecentImagesData();
    fetchFavoriteImagesData();
  }, []);

  // 画像クリック
  const handleImageClick = (image) => {
    setTempSelected(image);
  };

  useEffect(() => {
    setOldVideoSmall(videoSmall);
    setOldVideoMedium(videoMedium);
    setOldVideoLarge(videoLarge);
    setOldVideoExtraLarge(videoExtraLarge);
  }, [videoSmall, videoMedium, videoLarge, videoExtraLarge]);

  useEffect(() => {
    if (
      oldVideoSmall !== null ||
      oldVideoMedium !== null ||
      oldVideoLarge !== null ||
      oldVideoExtraLarge !== null
    ) {
      dispDefaultOverlay();
    } else {
      dispUploadOverlay();
    }
  }, [oldVideoSmall, oldVideoMedium, oldVideoLarge, oldVideoExtraLarge]);

  const dispDefaultOverlay = () => {
    setDefaultIsOpen(true);
    setSelectIsOpen(false);
    setUploadIsOpen(false);
  };

  const dispUploadOverlay = () => {
    setDefaultIsOpen(false);
    setSelectIsOpen(false);
    setUploadIsOpen(true);
  };

  const dispSelectOverlay = () => {
    setDefaultIsOpen(false);
    setSelectIsOpen(true);
    setUploadIsOpen(false);
  };

  const handleDrop = useCallback(async (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length === 1 && files[0].type.startsWith("video/")) {
      try {
        const formData = new FormData();
        formData.append("images", files[0]);

        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/image_upload/`, {
          method: "POST",
          credentials: "include",
          body: formData,
        });

        if (!response.ok) {
          throw new Error("動画のアップロードに失敗しました。");
        }

        const data = await response.json();
        // ここで返されたデータを使用して、必要に応じて状態を更新します。
        console.log("アップロードされた動画:", data);

        if (
          oldVideoSmall === null &&
          oldVideoMedium === null &&
          oldVideoLarge === null &&
          oldVideoExtraLarge === null
        ) {
          setOldVideoSmall(data.images[0]);
          setOldVideoMedium(data.images[0]);
          setOldVideoLarge(data.images[0]);
          setOldVideoExtraLarge(data.images[0]);
        } else if (targetVideoSize === "small") {
          setOldVideoSmall(data.images[0]);
        } else if (targetVideoSize === "medium") {
          setOldVideoMedium(data.images[0]);
        } else if (targetVideoSize === "large") {
          setOldVideoLarge(data.images[0]);
        } else {
          setOldVideoExtraLarge(data.images[0]);
        }
        fetchRecentImagesData();
      } catch (error) {
        console.error("アップロード中にエラーが発生しました:", error);
      }
    } else {
      alert("動画は1ファイルずつアップロードしてください。");
    }
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSetSelected = () => {
    if (
      oldVideoSmall === null &&
      oldVideoMedium === null &&
      oldVideoLarge === null &&
      oldVideoExtraLarge === null
    ) {
      setOldVideoSmall(tempSelected);
      setOldVideoMedium(tempSelected);
      setOldVideoLarge(tempSelected);
      setOldVideoExtraLarge(tempSelected);
    } else if (targetVideoSize === "small") {
      setOldVideoSmall(tempSelected);
    } else if (targetVideoSize === "medium") {
      setOldVideoMedium(tempSelected);
    } else if (targetVideoSize === "large") {
      setOldVideoLarge(tempSelected);
    } else {
      setOldVideoExtraLarge(tempSelected);
    }
    setTempSelected({});
  };

  const handleUpdateVideo = () => {
    setVideoSmall(oldVideoSmall);
    setVideoMedium(oldVideoMedium);
    setVideoLarge(oldVideoLarge);
    setVideoExtraLarge(oldVideoExtraLarge);
  };

  return (
    <>
      {defaultIsOpen && (
        <div
          className="overlay"
          onClick={() => {
            setVideoSelectIsOpen("");
          }}
        >
          <div
            className="ov-inner video-ov"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="movieList">
              {/* 動画リスト */}
              <>
                <ul>
                  {/* 4K */}
                  <li>
                    <p className="ov-ttl">4K</p>
                    {oldVideoExtraLarge ? (
                      <>
                        <video src={oldVideoExtraLarge.url} controls />
                        <p>
                          {oldVideoExtraLarge.width} ×{" "}
                          {oldVideoExtraLarge.height}
                        </p>
                      </>
                    ) : (
                      <p>動画なし</p>
                    )}
                    <button
                      className="type-2"
                      onClick={() => {
                        setTargetVideoSize("extraLarge");
                        dispUploadOverlay();
                      }}
                    >
                      差替え
                    </button>
                  </li>
                  {/* QHD */}
                  <li>
                    <p className="ov-ttl">QHD</p>
                    {oldVideoLarge ? (
                      <>
                        <video src={oldVideoLarge.url} controls />
                        <p>
                          {oldVideoLarge.width} × {oldVideoLarge.height}
                        </p>
                      </>
                    ) : (
                      <p>動画なし</p>
                    )}
                    <button
                      className="type-2"
                      onClick={() => {
                        setTargetVideoSize("large");
                        dispUploadOverlay();
                      }}
                    >
                      差替え
                    </button>
                  </li>
                  {/* フルHD */}
                  <li>
                    <p className="ov-ttl">フルHD</p>
                    {oldVideoMedium ? (
                      <>
                        <video src={oldVideoMedium.url} controls />
                        <p>
                          {oldVideoMedium.width} × {oldVideoMedium.height}
                        </p>
                      </>
                    ) : (
                      <p>動画なし</p>
                    )}
                    <button
                      className="type-2"
                      onClick={() => {
                        setTargetVideoSize("medium");
                        dispUploadOverlay();
                      }}
                    >
                      差替え
                    </button>
                  </li>
                  {/* HD */}
                  <li>
                    <p className="ov-ttl">HD</p>
                    {oldVideoSmall ? (
                      <>
                        <video src={oldVideoSmall.url} controls />
                        <p>
                          {oldVideoSmall.width} × {oldVideoSmall.height}
                        </p>
                      </>
                    ) : (
                      <p>動画なし</p>
                    )}
                    <button
                      className="type-2"
                      onClick={() => {
                        setTargetVideoSize("small");
                        dispUploadOverlay();
                      }}
                    >
                      差替え
                    </button>
                  </li>
                </ul>

                {/* サブミット */}
                <button
                  className="submit"
                  onClick={() => {
                    handleUpdateVideo();
                    setVideoSelectIsOpen("");
                  }}
                >
                  動画を追加
                </button>
              </>
            </div>
          </div>
        </div>
      )}

      {uploadIsOpen && (
        <div
          className="overlay"
          onClick={() => {
            setVideoSelectIsOpen("");
          }}
        >
          <div
            className="ov-inner video-ov"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="upload">
              {/* アップロード */}
              <div
                className="upload-area"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                {/*  */}
                <FontAwesomeIcon icon={faImage} />
                <p>
                  動画をドラッグ&ドロップ
                  <br />
                  (最大12MB)
                </p>
              </div>
              {/* 画像を選択 */}
              <button
                className="imgSelectBtn"
                onClick={() => {
                  dispSelectOverlay();
                }}
              >
                動画を選択
              </button>
            </div>
          </div>
        </div>
      )}

      {selectIsOpen && (
        <div
          className="overlay"
          onClick={() => {
            setVideoSelectIsOpen("");
          }}
        >
          <div
            className="ov-inner"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {/*  */}
            <div className="imgList">
              {/* お気に入り */}
              {favoriteImages.length > 0 && (
                <>
                  <p className="ov-ttl">お気に入り</p>
                  <ul>
                    {favoriteImages.map((image) => (
                      <>
                        <li
                          className={
                            tempSelected && tempSelected.id === image.id
                              ? "on"
                              : ""
                          }
                          key={image.id}
                          onClick={() => {
                            handleImageClick(image);
                          }}
                        >
                          <video src={image.url} />
                          <p className="video-size">
                            {image.width} × {image.height}
                          </p>
                        </li>
                      </>
                    ))}
                  </ul>
                </>
              )}
              {/* 最近のアップロード */}
              {recentImages.length > 0 && (
                <>
                  <p className="ov-ttl">最近のアップロード</p>
                  <ul>
                    {recentImages.map((image) => (
                      <>
                        <li
                          className={
                            tempSelected && tempSelected.id === image.id
                              ? "on"
                              : ""
                          }
                          key={image.id}
                          onClick={() => {
                            handleImageClick(image);
                          }}
                        >
                          <video src={image.url} />
                          <p className="video-size">
                            {image.width} × {image.height}
                          </p>
                        </li>
                      </>
                    ))}
                  </ul>
                </>
              )}
            </div>
            {/*  */}
            <div className="imgPreview">
              {/* プレビュー */}
              <div className="">
                <p className="ov-ttl">プレビュー</p>
                {tempSelected && Object.keys(tempSelected).length > 0 && (
                  <>
                    <figure className="imgCenter">
                      <video src={tempSelected.url} controls />
                    </figure>
                    <p>
                      {tempSelected.width} × {tempSelected.height}
                    </p>
                  </>
                )}
              </div>

              {/* サブミット */}
              <button
                onClick={() => {
                  handleSetSelected();
                  // dispDefaultOverlay();
                }}
                disabled={
                  !(
                    tempSelected &&
                    (Array.isArray(tempSelected)
                      ? tempSelected.length > 0
                      : Object.keys(tempSelected).length > 0)
                  )
                }
              >
                動画を追加
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoSelect;
