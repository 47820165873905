import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/password_reset/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setSuccess(data.message);
    } catch (error) {
      console.error("エラーが発生しました:", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="loginWrap">
          <h2>パスワードリセット</h2>
          <div className="formWrap">
            {/* メールアドレス */}
            <label className="inputLabel text">
              <p>メールアドレスを入力して下さい</p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>

            <button onClick={handleSubmit}>送信</button>

            {error && (
              <div className="message">
                <p color="red">{error}</p>
              </div>
            )}

            {success && <p color="red">{success}</p>}
          </div>
        </div>
      </form>
    </>
  );
}

export default ForgotPasswordPage;
