import React, { useState, useEffect, useCallback, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import Breadcrumbs from "../../../components/Breadcrumbs";
import "../../../styles/scss/website/qa.scss";

// 修正
// 選択時.ulにactiveを
// Q&Aを編集を、<タイトル>を編集に

const QaEditContent = memo(
  ({ qa, handleInputChange, handleQaDataSubmit, handleQaDataUpdate, edit }) => {
    return (
      <>
        {edit ? (
          <p className="ttl">Q & Aを編集</p>
        ) : (
          <p className="ttl">新しいQ & Aを追加</p>
        )}
        <div className="List">
          <ul className="gridList qaEditList">
            <li>Q</li>
            <li>
              <label className="form">
                <input
                  name="question"
                  value={qa.question || ""}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>A</li>
            <li>
              <label className="form">
                <textarea
                  name="answer"
                  value={qa.answer || ""}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li className="submit">
              {edit ? (
                <button
                  type="submit"
                  className="submit type-3 type-3"
                  onClick={handleQaDataUpdate}
                >
                  Q & Aを更新する
                </button>
              ) : (
                <button
                  type="submit"
                  className="submit type-3 type-3"
                  onClick={handleQaDataSubmit}
                >
                  Q & Aを追加する
                </button>
              )}
            </li>
          </ul>
        </div>
      </>
    );
  }
);

function QAPage() {
  const [qas, setQas] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [qa, setQa] = useState({});
  const [edit, setEdit] = useState(false);

  const breadcrumbs = [{ title: "Q & A", path: `/website/qa` }];

  // Q & A一覧取得
  const fetchQasData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_question_answers/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setQas(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  useEffect(() => {
    fetchQasData();
  }, [fetchQasData]);

  // 入力フォーム変更
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQa((prevQa) => ({
      ...prevQa,
      [name]: value,
    }));
  };

  // Q & A追加
  const handleQaDataSubmit = async () => {
    if (!qa.question || !qa.answer) {
      alert("すべてのフィールドに値を入力してください。");
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/save_question_answer/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: qa.question,
          answer: qa.answer,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      alert(data.message);
      setQa({});
      fetchQasData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // Q & A編集
  const handleQaDataUpdate = async () => {
    if (!qa.question || !qa.answer) {
      alert("すべてのフィールドに値を入力してください。");
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/update_question_answer/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          qa_id: qa.id,
          question: qa.question,
          answer: qa.answer,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      alert(data.message);
      fetchQasData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // Q & A削除
  const handleQaDataDelete = async (qa_id) => {
    const confirmation = window.confirm(
      "Q & Aの削除は取り消せません。\n本当に削除しますか？"
    );
    if (!confirmation) {
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/delete_question_answer/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          qa_id: qa_id,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }
      alert(data.message);
      fetchQasData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 編集用のフォーム
  const handleEditOpen = (qaItem) => {
    setEdit(true);
    if (isOpen) {
      if (qaItem.id === qa.id) {
        setIsOpen(false);
        setLayout("4");
        setQa({});
      } else {
        setIsOpen(true);
        setLayout("1");
        setQa({
          id: qaItem.id,
          question: qaItem.question,
          answer: qaItem.answer,
        });
      }
    } else {
      setIsOpen(true);
      setLayout("1");
      setQa({
        id: qaItem.id,
        question: qaItem.question,
        answer: qaItem.answer,
      });
    }
  };

  // レイアウト
  // レイアウトを設定するためのstate
  const [layout, setLayout] = useState("4");

  const QaContent = () => {
    return (
      <div className="qacontent">
        <div className="List">
          <div className="gridList">
            {qas.map((qaItem) => (
              <ul
                className="listContents"
                onClick={() => {
                  handleEditOpen(qaItem);
                }}
              >
                <li>
                  <i>
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </i>
                  <span>{qaItem.question}</span>
                </li>
                <li>{qaItem.answer}</li>

                {/*  */}
                <li
                  className="delete"
                  onClick={() => {
                    handleQaDataDelete(qaItem.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <div className="add">
          <button
            className="type-1"
            onClick={() => {
              setQa({});
              setEdit(false);
              if (isOpen) {
                if (edit === true) {
                  setIsOpen(true);
                  setLayout("1");
                } else {
                  setIsOpen(false);
                  setLayout("4");
                }
              } else {
                setIsOpen(true);
                setLayout("1");
              }
            }}
          >
            Q & Aを追加
          </button>
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <QaContent />
      </div>
      <div
        className={`content-grid grid-3 layout-type-${layout}`}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <QaEditContent
          qa={qa}
          handleInputChange={handleInputChange}
          handleQaDataSubmit={handleQaDataSubmit}
          handleQaDataUpdate={handleQaDataUpdate}
          edit={edit}
        />
      </div>
    </>
  );
}

export default QAPage;
