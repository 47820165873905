import React, { useState, useEffect, useCallback, memo } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, GridRow, GridColumn } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import Breadcrumbs from "../../../components/Breadcrumbs";
import "../../../styles/scss/website/instructorList.scss";

const InstructorEditContent = memo(
  ({ instructor, handleInputChange, handleInstructorDataSubmit }) => {
    return (
      <>
        <p className="ttl">新しいインストラクターを追加</p>
        {/* <p className="ttl">インストラクターを編集</p> */}
        <div className="List">
          <ul className="gridList instructorEditList">
            <li>ダンサーネーム</li>
            <li>
              <label className="form">
                <input
                  type="text"
                  name="dancer_name"
                  value={instructor?.dancer_name}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>ハッシュタグ</li>
            <li>
              <label className="form">
                <input
                  type="text"
                  name="username"
                  value={instructor?.username}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li className="submit">
              <button
                type="submit"
                className="submit type-3 type-3"
                onClick={handleInstructorDataSubmit}
              >
                インストラクターを追加する
              </button>
            </li>
          </ul>
        </div>
      </>
    );
  }
);

function InstructorListPage() {
  const [instructors, setInstructors] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [instructor, setInstructor] = useState({});

  const breadcrumbs = [
    { title: "インストラクター一覧", path: `/website/instructor-list` },
  ];

  // インストラクター一覧取得
  const fetchInstructorsData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_instructors/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setInstructors(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  useEffect(() => {
    fetchInstructorsData();
  }, [fetchInstructorsData]);

  // 入力フォーム変更
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      // 半角英数字と半角アンダーバーのみを許可
      const sanitizedValue = value.replace(/[^a-zA-Z0-9_]/g, "");
      if (value !== sanitizedValue) {
        alert(
          "「ハッシュタグ」には半角英数字と半角アンダーバーしか使用できません。"
        );
      }
      setInstructor((prevInstructor) => ({
        ...prevInstructor,
        [name]: sanitizedValue,
      }));
    } else {
      setInstructor((prevInstructor) => ({
        ...prevInstructor,
        [name]: value,
      }));
    }
  };

  // インストラクター追加
  const handleInstructorDataSubmit = async () => {
    if (!instructor.username || !instructor.dancer_name) {
      alert("すべてのフィールドに値を入力してください。");
      return;
    }
    // 既存のusernameをチェック
    const isUsernameTaken = instructors.some(
      (existingInstructor) =>
        existingInstructor.username === instructor.username
    );
    if (isUsernameTaken) {
      alert(
        "このハッシュタグは既に使用されています。別のハッシュタグを入力してください。"
      );
      return;
    }
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/create_instructor/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: instructor.username,
          dancer_name: instructor.dancer_name,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      alert(data.message);
      setInstructor({});
      fetchInstructorsData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // インストラクター削除
  const handleInstructorDataDelete = async (instructor_id) => {
    const confirmation = window.confirm(
      "インストラクター情報の削除は取り消せません。\n紐づくレッスン情報や画像なども削除されます。\n本当に削除しますか？"
    );
    if (!confirmation) {
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/delete_instructor/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          instructor_id: instructor_id,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }
      alert(data.message);
      fetchInstructorsData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // レイアウト
  // レイアウトを設定するためのstate
  const [layout, setLayout] = useState("4");

  const InstructorListContent = () => {
    return (
      <div className="instructorListcontent">
        <div className="List">
          <div className="gridList">
            {instructors.map((instructor) => (
              <ul className="listContents">
                <Link to={`/website/instructor-edit/${instructor.id}`}>
                  <li>
                    <i>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </i>
                    <span>{instructor.dancer_name}</span>
                  </li>
                </Link>
                <Link to={`/website/instructor-edit/${instructor.id}`}>
                  <li>#{instructor.username}</li>
                </Link>
                {/* active か それ以外 */}
                {instructor.is_active == true && (
                  <li className="active">
                    <i>
                      <FontAwesomeIcon icon={faCheck} />
                    </i>
                    <span>active</span>
                  </li>
                )}

                {/*  */}
                <li
                  className="delete"
                  onClick={() => {
                    handleInstructorDataDelete(instructor.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <div className="add">
          <button
            className="type-1"
            onClick={() => {
              setIsOpen(!isOpen);
              setLayout(isOpen ? "4" : "1");
            }}
          >
            インストラクターを追加
          </button>
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <InstructorListContent />
      </div>
      <div
        className={`content-grid grid-3 layout-type-${layout}`}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <InstructorEditContent
          instructor={instructor}
          handleInputChange={handleInputChange}
          handleInstructorDataSubmit={handleInstructorDataSubmit}
        />
      </div>
    </>
  );
}

export default InstructorListPage;
