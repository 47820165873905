import React, { useEffect, useState, useCallback } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Overlay from "../../../components/overlay";
import "../../../styles/scss/website/visual.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-thin-svg-icons";

import { faStar as faRegularStar } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faSolidStar } from "@fortawesome/pro-solid-svg-icons";
import { faImage } from "@fortawesome/pro-light-svg-icons";

function VisualPage() {
  const [images, setImages] = useState([]); // 画像リスト
  const [recentImages, setRecentImages] = useState([]); // 最近の画像リスト
  const [favoriteImages, setFavoriteImages] = useState([]); // お気に入りの画像リスト
  const [previewImages, setPreviewImages] = useState([]); // プレビュー画像リスト
  const [currentPreview, setCurrentPreview] = useState(null); // 現在のプレビュー画像
  const [files, setFiles] = useState([]); // アップロードするファイルオブジェクトリスト
  const [isOpen, setIsOpen] = useState(0); // オーバーレイの表示/非表示

  // 最近の画像リストの取得
  const fetchRecentImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_recent_images/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setRecentImages(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // お気に入りの画像リストの取得
  const fetchFavoriteImagesData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_favorite_images/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setFavoriteImages(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // ハッシュタグごとの画像リストの取得
  const fetchImagesDataByHashtag = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/get_images_by_hashtag_master/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setImages(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  });

  // お気に入りの画像を更新
  const toggleFavoriteImage = async (imageId) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/toggle_favorite_image/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ image_id: imageId }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      await fetchFavoriteImagesData(); // お気に入りの画像リストを再取得
    } catch (error) {
      console.error("お気に入りの更新中にエラーが発生しました:", error);
    }
  };

  // 最初に各画像リストの取得
  useEffect(() => {
    fetchImagesDataByHashtag();
    fetchRecentImagesData();
    fetchFavoriteImagesData();
  }, []);

  // ファイルのドラッグ&ドロップ処理
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    const newPreviewItems = droppedFiles.map((file) => ({
      url: URL.createObjectURL(file),
      type: file.type, // MIMEタイプを保存
    }));
    setPreviewImages(newPreviewItems);
    setFiles(droppedFiles);
    setCurrentPreview(newPreviewItems[0]); // 最初のアイテムをプレビュー
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // 画像を追加するボタン押下時の処理
  const handleUpload = async () => {
    if (files.length === 0) {
      return;
    }
    setIsOpen(1);
    const formData = new FormData();

    files.forEach((image) => {
      formData.append("images", image);
    });

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/image_upload/`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);

      fetchRecentImagesData();
      setPreviewImages([]);
      setCurrentPreview(null);
      setFiles([]);

      alert("アップロードが完了しました");
      setIsOpen(0);
    } catch (error) {
      alert("登録に失敗しました");
      setIsOpen(0);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  function isImage(mimeType) {
    return mimeType.startsWith("image/");
  }

  function isVideo(mimeType) {
    return mimeType.startsWith("video/");
  }

  // // // // // // // // // // // // // // // // // // // // // // //
  const breadcrumbs = [{ title: "写真 / 動画", path: `/website/visual` }];
  const [layout, setLayout] = useState("2");

  return (
    <>
      {/* 真ん中カラム */}
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <h2>ライブラリ</h2>

        {/*  */}
        {favoriteImages.length > 0 && (
          <div className="section">
            <h4>お気に入り</h4>
            <ul className="thumbnail">
              {favoriteImages.map((image) => (
                <li key={image.id}>
                  <figure>
                    {isImage(image.mime_type) ? (
                      <img src={image.url} alt={`Image ${image.id}`} />
                    ) : isVideo(image.mime_type) ? (
                      <video src={image.url} controls />
                    ) : (
                      <p>サポートされていないファイル形式です</p>
                    )}
                    {/* お気に入り */}
                    <FontAwesomeIcon
                      className={`favorite ${
                        favoriteImages.some(
                          (favImage) => favImage.id === image.id
                        )
                          ? "on"
                          : "off"
                      }`}
                      icon={
                        favoriteImages.some(
                          (favImage) => favImage.id === image.id
                        )
                          ? faSolidStar
                          : faRegularStar
                      }
                      onClick={() => toggleFavoriteImage(image.id)}
                    />
                    {/* ローディング */}
                    <FontAwesomeIcon
                      className="loading"
                      icon={faSpinnerThird}
                    />
                  </figure>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/*  */}
        {recentImages.length > 0 && (
          <div className="section">
            <h4>最近のアップロード</h4>
            <ul className="thumbnail">
              {recentImages.map((image) => (
                <li key={image.id}>
                  <figure>
                    {isImage(image.mime_type) ? (
                      <img src={image.url} alt={`Image ${image.id}`} />
                    ) : isVideo(image.mime_type) ? (
                      <video src={image.url} controls />
                    ) : (
                      <p>サポートされていないファイル形式です</p>
                    )}
                    {/* お気に入り */}
                    <FontAwesomeIcon
                      className={`favorite ${
                        favoriteImages.some(
                          (favImage) => favImage.id === image.id
                        )
                          ? "on"
                          : "off"
                      }`}
                      icon={
                        favoriteImages.some(
                          (favImage) => favImage.id === image.id
                        )
                          ? faSolidStar
                          : faRegularStar
                      }
                      onClick={() => toggleFavoriteImage(image.id)}
                    />
                    {/* ローディング */}
                    <FontAwesomeIcon
                      className="loading"
                      icon={faSpinnerThird}
                    />
                  </figure>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/*  */}
        {images &&
          images.map((imageGroup) => (
            <div className="section" key={imageGroup.hashtag_master_id}>
              <h4>{imageGroup.hashtag_master_text}</h4>
              <ul className="thumbnail">
                {imageGroup.images.map((image) => (
                  <li key={image.id}>
                    <figure>
                      {isImage(image.mime_type) ? (
                        <img src={image.url} alt={`Image ${image.id}`} />
                      ) : isVideo(image.mime_type) ? (
                        <video src={image.url} controls />
                      ) : (
                        <p>サポートされていないファイル形式です</p>
                      )}
                      {/* お気に入り */}
                      <FontAwesomeIcon
                        className={`favorite ${
                          favoriteImages.some(
                            (favImage) => favImage.id === image.id
                          )
                            ? "on"
                            : "off"
                        }`}
                        icon={
                          favoriteImages.some(
                            (favImage) => favImage.id === image.id
                          )
                            ? faSolidStar
                            : faRegularStar
                        }
                        onClick={() => toggleFavoriteImage(image.id)}
                      />
                      {/* ローディング */}
                      <FontAwesomeIcon
                        className="loading"
                        icon={faSpinnerThird}
                      />
                    </figure>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>

      {/* 右カラム */}
      <div className={`content-grid grid-3 layout-type-${layout}`}>
        <div
          className="upload-area"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {/*  */}
          <FontAwesomeIcon icon={faImage} />
          <p>
            画像/動画をドラッグ&ドロップ
            <br />
            (最大12MB)
          </p>
        </div>

        {/*  */}
        <div className="preview-area">
          <figure className="preview-img">
            {currentPreview &&
              (currentPreview.type.startsWith("image/") ? (
                <img src={currentPreview.url} alt="Preview" />
              ) : currentPreview.type.startsWith("video/") ? (
                <video src={currentPreview.url} controls />
              ) : null)}
          </figure>
          <ul className="cursel">
            {previewImages.map((item, index) => (
              <li key={index} onClick={() => setCurrentPreview(item)}>
                {item.type.startsWith("image/") ? (
                  <img src={item.url} alt={`Preview ${index}`} />
                ) : item.type.startsWith("video/") ? (
                  <video src={item.url} />
                ) : null}
              </li>
            ))}
          </ul>
        </div>

        {/* 追加ボタン */}
        {previewImages.length !== 0 && (
          <button className="type-2" onClick={handleUpload}>
            画像を追加する
          </button>
        )}
      </div>
      <Overlay isOpen={isOpen} children={"アップロード中"} />
    </>
  );
}

export default VisualPage;
