import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useLoginCheck } from "../../hooks/useLoginCheck";
import { Chart } from "react-google-charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../../components/Breadcrumbs";
import "../../styles/scss/studentList.scss";

import { faHome, faSearch } from "@fortawesome/pro-solid-svg-icons";

function TopPage() {
  const breadcrumbs = [
    { title: "生徒一覧", path: `/management/customer-list` },
  ];

  useLoginCheck();

  const navigate = useNavigate();
  const data = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];

  const options = {
    title: "My Daily Activities",
    pieHole: 0.4,
  };

  // 新js

  const [isMenuOpen, setIsMenuOpen] = React.useState(true);
  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // レイアウト
  // レイアウトを設定するためのstate
  const [layout, setLayout] = React.useState("1");

  // レイアウト変更の関数
  const changeLayout = (newLayout) => {
    setLayout(newLayout);
  };

  // 生徒一覧
  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // alert("クリップボードにコピーしました: " + text);
      })
      .catch((err) => {
        // console.error("クリップボードへのコピーに失敗しました: ", err);
      });
  };

  // grid-2
  const StudentList = () => {
    return (
      <div className="studentList">
        {/* 検索 */}
        <div className="serchForm">
          <label className="form icon" htmlFor="">
            <i>
              <FontAwesomeIcon icon={faSearch} />
            </i>
            <input type="search" />
          </label>
        </div>

        {/* 生徒一覧 */}
        <div className="List">
          <div className="gridList">
            <ul className="ttl">
              <li>ID</li>
              <li>名前</li>
              <li>フリガナ</li>
              <li>保護者</li>
              <li>入会店舗</li>
              <li>電話番号</li>
              <li>メール</li>
              <li>入会月</li>
            </ul>
            {Array.from({ length: 100 }).map((_, index) => (
              <ul className="listContents" key={index}>
                <li onClick={() => handleCopyToClipboard("999")}>999</li>
                <li onClick={() => handleCopyToClipboard("田中 太郎")}>
                  田中 太郎
                </li>
                <li onClick={() => handleCopyToClipboard("タナカ タロウ")}>
                  タナカ タロウ
                </li>
                <li onClick={() => handleCopyToClipboard("田中 ひろし")}>
                  田中 ひろし
                </li>
                <li onClick={() => handleCopyToClipboard("大阪校")}>大阪校</li>
                <li onClick={() => handleCopyToClipboard("070-1234-5678")}>
                  070-1234-5678
                </li>
                <li
                  onClick={() => handleCopyToClipboard("samplesample@aaa.jp")}
                >
                  samplesample@aaa.jp
                </li>
                <li onClick={() => handleCopyToClipboard("2024年5月")}>
                  2024年5月
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    );
  };
  // grid-3
  const StudentSingle = () => {
    return (
      <div className="studenSingle">
        <figure>
          <img
            src="https://dance-slash.com/_next/image?url=%2Fimages%2Fnewvisitor%2Fshooting_50.png&w=1080&q=75"
            alt=""
          />
        </figure>
        <ul className="tabBtn">
          <li className={`detail ${activeTab === "detail" ? "active" : ""}`}>
            生徒詳細
          </li>
          <li className={`plan ${activeTab === "plan" ? "active" : ""}`}>
            プラン
          </li>
          <li className={`pay ${activeTab === "pay" ? "active" : ""}`}>
            支払い履歴
          </li>
        </ul>
        <div className="tabPanel">
          <RenderTabContent />
        </div>
      </div>
    );
  };

  // タブの切り替え
  // タブの切り替え用のstateを作成
  const [activeTab, setActiveTab] = useState("pay");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // タブの切り替え
  const RenderTabContent = () => {
    switch (activeTab) {
      case "detail":
        return (
          <div className="detail">
            <ul>
              <li>ID</li>
              <li>999</li>
              <li>名前</li>
              <li>田中 太郎</li>
              <li>フリガナ</li>
              <li>タナカ タロウ</li>
              <li>保護者</li>
              <li>田中 ひろし</li>
              <li>入会店舗</li>
              <li>大阪校</li>
              <li>電話番号</li>
              <li>070-1234-5678</li>
              <li>メール</li>
              <li>samplesample@aaa.jp</li>
              <li>入会月</li>
              <li>2024年5月</li>
            </ul>
          </div>
        );
      case "plan":
        return (
          <div className="plan">
            <ul>
              <li>プラン名</li>
              <li>週3プラン</li>
              <li>プラン開始月</li>
              <li>2024年 3月</li>
              <li className="full planLesson">
                <p>所属レッスン</p>
                <button>CHIAYA 西大路御池 初級(火)</button>
                <button>CHIAYA 西大路御池 初級(火)</button>
                <button>CHIAYA 西大路御池 初級(火)</button>
              </li>
            </ul>
          </div>
        );
      case "pay":
        return (
          <div className="pay">
            <ul>
              <li>支払い方法</li>
              <li>請求書払い</li>
            </ul>
            {/* 支払い履歴 */}

            <div className="paymentListWrap">
              {Array.from({ length: 25 }, (_, index) => (
                <React.Fragment key={index}>
                  {" "}
                  {/* keyを追加 */}
                  <ul className="paymentList paid">
                    <li>2024年 7月20日</li>
                    <li>15,576円</li>
                    <li>
                      <button>支払い済み</button>
                    </li>
                  </ul>
                  <ul className="paymentList unpaid">
                    <li>2024年 7月20日</li>
                    <li>15,576円</li>
                    <li>
                      <button>未払い</button>
                    </li>
                  </ul>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <>
        <div className={`content-grid grid-2 layout-type-${layout}`}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <StudentList />
        </div>
        <div className={`content-grid grid-3 layout-type-${layout}`}>
          <StudentSingle />
        </div>
      </>
    </>
  );
}

export default TopPage;
