import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import Breadcrumbs from "../../../components/Breadcrumbs";
import "../../../styles/scss/website/campaignList.scss";

function CampaignListPage() {
  const [campaigns, setCampaigns] = useState([]);

  const breadcrumbs = [
    { title: "キャンペーン一覧", path: `/website/campaign-list` },
  ];
  const [layout, setLayout] = useState("4");

  const fetchCampaignsData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_campaigns/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setCampaigns(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // キャンペーンの取得
  useEffect(() => {
    fetchCampaignsData();
  }, [fetchCampaignsData]);

  // 投稿の削除
  const handleCampaignDataDelete = async (campaign_id) => {
    const confirmation = window.confirm("このキャンペーンを終了しますか？");
    if (!confirmation) {
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/delete_campaign/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          campaign_id: campaign_id,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }
      alert(data.message);
      fetchCampaignsData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const CampaignListContent = () => {
    return (
      <div className="campaignListcontent">
        <div className="List">
          <div className="gridList">
            <>
              {campaigns.map((campaign) => (
                <ul
                  className={`listContents ${
                    campaign.delete_flg ? "disabled" : ""
                  }`}
                  key={campaign.id}
                >
                  <Link to={`/website/campaign-edit/${campaign.id}`}>
                    <li>
                      <i>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </i>
                      <span>{campaign.title}</span>
                    </li>
                  </Link>
                  <Link
                    to={`https://rudofactory.jp/newvisitor/${campaign.slug}`}
                    target="_blank"
                  >
                    <li>
                      <span>
                        https://rudofactory.jp/newvisitor/{campaign.slug}
                      </span>
                    </li>
                  </Link>
                  {campaign.delete_flg == true && (
                    <li className="published">
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                      <span>閉鎖</span>
                    </li>
                  )}
                  <li
                    className="delete"
                    onClick={() => {
                      handleCampaignDataDelete(campaign.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </li>
                </ul>
              ))}
            </>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* 真ん中カラム */}
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <div className="add">
          <Link to={`/website/campaign-edit`}>
            <button className="type-1">キャンペーンを追加</button>
          </Link>
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <CampaignListContent />
      </div>
    </>
  );
}

export default CampaignListPage;
