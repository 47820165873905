import React, { useState, useEffect, useCallback } from "react";
import { Icon, TableHeaderCell } from "semantic-ui-react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useLoginCheck } from "../../hooks/useLoginCheck";
import Breadcrumbs from "../../components/Breadcrumbs";
import "../../styles/scss/managementRemoveCustomerList.scss";

function RemoveCustomerListPage() {
  useLoginCheck();
  const [removeCustomers, setRemoveCustomers] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortType, setSortType] = useState(null);

  const breadcrumbs = [
    { title: "退会者一覧", path: `/management/remove-customer-list` },
  ];

  const fetchRemoveCustomersData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_remove_customers/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sort_column: sortColumn,
          sort_type: sortType,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setRemoveCustomers(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, [sortColumn, sortType]);

  function handleColumnClick(col) {
    setSortColumn(col);
    setSortType(sortType === "desc" || !sortType ? "asc" : "desc");
  }

  useEffect(() => {
    setSortType("asc");
  }, [sortColumn]);

  useEffect(() => {
    fetchRemoveCustomersData();
  }, [sortColumn, sortType, fetchRemoveCustomersData]);

  const CustomTableHeaderCell = ({ title, columnName, width }) => {
    return (
      <TableHeaderCell
        width={width}
        onClick={() => {
          handleColumnClick(columnName);
        }}
      >
        {title}
        {columnName === sortColumn && sortType === "asc" && (
          <Icon name="triangle up" />
        )}
        {columnName === sortColumn && sortType === "desc" && (
          <Icon name="triangle down" />
        )}
      </TableHeaderCell>
    );
  };

  ///////////////////////////////////
  // ↑旧
  // ↓新
  /////////////////////////////
  // /////////////////////////////////
  // ↑旧
  // ↓新
  /////////////////////////////

  // レイアウト
  // レイアウトを設定するためのstate
  const [layout, setLayout] = React.useState("4");

  // レイアウト変更の関数
  const changeLayout = (newLayout) => {
    setLayout(newLayout);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // alert("クリップボードにコピーしました: " + text);
      })
      .catch((err) => {
        // console.error("クリップボードへのコピーに失敗しました: ", err);
      });
  };

  const RemovecustomerList = () => {
    return (
      <div className="studentList">
        {/* 生徒一覧 */}
        <div className="List">
          <div className="gridList">
            <ul className="ttl">
              <li>顧客ID</li>
              <li>日付</li>
              <li>名前</li>
              <li>理由</li>
              <li>詳細</li>
              <li>開始月</li>
            </ul>
            {Array.from({ length: 100 }).map((_, index) => (
              <ul className="listContents">
                <React.Fragment key={index}>
                  <li onClick={() => handleCopyToClipboard("999")}>999</li>
                  <li onClick={() => handleCopyToClipboard("2024年7月10日")}>
                    2024年7月10日
                  </li>
                  <li onClick={() => handleCopyToClipboard("秋崎 妃葵")}>
                    秋崎 妃葵
                  </li>
                  <li
                    onClick={() =>
                      handleCopyToClipboard("ダンスのレベルが合わない")
                    }
                  >
                    ダンスのレベルが合わない
                  </li>
                  <li
                    onClick={() =>
                      handleCopyToClipboard(
                        "初心者で全く経験がない中でダンスの基礎やステップからではなく、K-POPは完コピがスタイルとゆうのを知らずにはいったのでまずは基礎を習ってからまた踊れる自信がついたら再チャレンジしたいとおもいます。"
                      )
                    }
                  >
                    初心者で全く経験がない中でダンスの基礎やステップからではなく、K-POPは完コピがスタイルとゆうのを知らずにはいったのでまずは基礎を習ってからまた踊れる自信がついたら再チャレンジしたいとおもいます。
                  </li>
                  <li onClick={() => handleCopyToClipboard("2024年7月")}>
                    2024年7月
                  </li>
                </React.Fragment>
              </ul>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <>
        <div className={`content-grid grid-2 layout-type-${layout}`}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <RemovecustomerList />
        </div>
        <div className={`content-grid grid-3 layout-type-${layout}`}></div>
      </>
    </>
  );
}

export default RemoveCustomerListPage;
