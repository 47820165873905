import React, { useState, useEffect, useCallback, memo } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import Breadcrumbs from "../../../components/Breadcrumbs";
import parse from "html-react-parser";
import "../../../styles/scss/website/newsList.scss";

const NewsEditContent = memo(
  ({ news, handleInputChange, handleNewsDataSubmit }) => {
    return (
      <>
        <p className="ttl">新しい投稿を追加</p>
        <div className="List">
          <ul className="gridList newsEditList">
            <li>タイトル</li>
            <li>
              <label className="form">
                <input
                  type="text"
                  name="title"
                  value={news?.title || ""}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>本文</li>
            <li>
              <label className="form">
                <textarea
                  name="text"
                  value={news?.text || ""}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li className="submit">
              <button
                type="submit"
                className="submit type-3 type-3"
                onClick={handleNewsDataSubmit}
              >
                投稿を追加する
              </button>
            </li>
          </ul>
        </div>
      </>
    );
  }
);

function NewsListPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [news, setNews] = useState({});
  const [newsList, setNewsList] = useState([]);

  const breadcrumbs = [{ title: "投稿一覧", path: `/website/news-list` }];
  const [layout, setLayout] = useState("4");

  // 投稿リストの取得
  const fetchNewsListData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_news_list/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setNewsList(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // 投稿リストの取得
  useEffect(() => {
    fetchNewsListData();
  }, [fetchNewsListData]);

  // 入力フォーム変更
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNews((prevNews) => ({
      ...prevNews,
      [name]: value,
    }));
  };

  // 投稿の追加
  const handleNewsDataSubmit = async () => {
    if (!news.title || !news.text) {
      alert("すべてのフィールドに値を入力してください。");
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/create_news/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: news.title,
          text: news.text,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      alert(data.message);
      setNews({});
      fetchNewsListData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 投稿の削除
  const handleNewsDataDelete = async (news_id) => {
    const confirmation = window.confirm("本当に削除しますか？");
    if (!confirmation) {
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/delete_news/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          news_id: news_id,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }
      alert(data.message);
      fetchNewsListData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const NewsListContent = () => {
    return (
      <div className="newsListcontent">
        <div className="List">
          <div className="gridList">
            <>
              {newsList.map((news) => (
                <ul className="listContents" key={news.id}>
                  <Link to={`/website/news-edit/${news.id}`}>
                    <li>
                      <i>
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </i>
                      <span>{news.title}</span>
                    </li>
                  </Link>
                  <Link to={`/website/news-edit/${news.id}`}>
                    <li>{news.text}</li>
                  </Link>
                  {news.is_secret == true && (
                    <li className="published">
                      <i>
                        <FontAwesomeIcon icon={faCheck} />
                      </i>
                      <span>限定公開</span>
                    </li>
                  )}
                  <li
                    className="delete"
                    onClick={() => {
                      handleNewsDataDelete(news.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </li>
                </ul>
              ))}
            </>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* 真ん中カラム */}
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <div className="add">
          <button
            className="type-1"
            onClick={() => {
              setIsOpen(!isOpen);
              setLayout(isOpen ? "4" : "1");
            }}
          >
            投稿を追加
          </button>
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <NewsListContent />
      </div>
      <div
        className={`content-grid grid-3 layout-type-${layout}`}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <NewsEditContent
          news={news}
          handleInputChange={handleInputChange}
          handleNewsDataSubmit={handleNewsDataSubmit}
        />
      </div>
    </>
  );
}

export default NewsListPage;
