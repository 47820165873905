import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
  useLocation,
} from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import TopPage from "./pages/management/TopPage";
import LoginPage from "./pages/auth/LoginPage";
import SignUpPage from "./pages/auth/SignUpPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import CustomerListPage from "./pages/management/CustomerListPage";
import FreeCustomerListPage from "./pages/management/FreeCustomerListPage";
import RemoveCustomerListPage from "./pages/management/RemoveCustomerListPage";
import SendMailPage from "./pages/management/SendMailPage";
import WebsiteManagementPage from "./pages/website/WebsiteManagementPage";
import UserListPage from "./pages/management/UserListPage";
import TopEditPage from "./pages/website/top/TopEditPage";
import AboutEditPage from "./pages/website/about/AboutEditPage";
import PriceEditPage from "./pages/website/price/PriceEditPage";
import StudioListPage from "./pages/website/studio/StudioListPage";
import StudioEditPage from "./pages/website/studio/StudioEditPage";
import InstructorListPage from "./pages/website/instructor/InstructorListPage";
import InstructorEditPage from "./pages/website/instructor/InstructorEditPage";
import LessonEditPage from "./pages/website/lesson/LessonEditPage";
import NewsListPage from "./pages/website/news/NewsListPage";
import NewsEditPage from "./pages/website/news/NewsEditPage";
import VisualPage from "./pages/website/visual/VisualPage";
import QaPage from "./pages/website/qa/QaPage";
import FaqListPage from "./pages/website/faq/FaqListPage";
import FaqEditPage from "./pages/website/faq/FaqEditPage";
import AnalyticsPage from "./pages/website/analytics/AnalyticsPage";
import CampaignListPage from "./pages/website/campaign/CampaignListPage";
import CampaignEditPage from "./pages/website/campaign/CampaignEditPage";
import ReviewPage from "./pages/website/review/ReviewPage";
import useBodyClass from "./hooks/useBodyClass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Overlay from "./components/overlay";

import {
  faObjectsColumn,
  faList,
  faClipboardList,
  faUserXmark,
  faEnvelope,
  faBrowser,
  faRightFromBracket,
  faHome,
  faBook,
  faBuilding,
  faChild,
  faCalendarCheck,
  faCamera,
  faQuestionCircle,
  faClipboardQuestion,
  faEdit,
  faComment,
  faBullhorn,
  faChartBar,
  faArrowLeft,
  faCircleDollar,
} from "@fortawesome/pro-solid-svg-icons";

function App() {
  // ログイン状態
  const { isLoggedIn, isSuperuser, logout } = useAuth();

  // 画面
  const RenderPage = () => {
    useBodyClass();

    if (!isLoggedIn) {
      return <Navigate to="/login" replace />;
    }

    // MENUボタン
    const menuToggle = () => {
      const wrapElement = document.querySelector(".wrap");
      if (wrapElement.classList.contains("close")) {
        wrapElement.classList.remove("close");
        wrapElement.classList.add("open");
      } else if (wrapElement.classList.contains("open")) {
        wrapElement.classList.remove("open");
        wrapElement.classList.add("close");
      }
    };

    return (
      <>
        <div className="wrap close">
          <Routes>
            <Route
              path="/"
              element={
                <SidebarLayout menuType="management">
                  <TopPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/management/customer-list"
              element={
                <SidebarLayout menuType="management">
                  <CustomerListPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/management/free-customer-list"
              element={
                <SidebarLayout menuType="management">
                  <FreeCustomerListPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/management/send-mail"
              element={
                <SidebarLayout menuType="management">
                  <SendMailPage />
                </SidebarLayout>
              }
            />
            {/* <Route
              path="/management/user-list"
              element={
                <SidebarLayout menuType="management">
                  <UserListPage />
                </SidebarLayout>
              }
            /> */}
            <Route
              path="/website/top-edit"
              element={
                <SidebarLayout menuType="website">
                  <TopEditPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/about-edit"
              element={
                <SidebarLayout menuType="website">
                  <AboutEditPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/price-edit"
              element={
                <SidebarLayout menuType="website">
                  <PriceEditPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/website-management"
              element={
                <SidebarLayout menuType="website">
                  <WebsiteManagementPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/studio-list"
              element={
                <SidebarLayout menuType="website">
                  <StudioListPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/studio-edit/:studioId"
              element={
                <SidebarLayout menuType="website">
                  <StudioEditPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/instructor-list"
              element={
                <SidebarLayout menuType="website">
                  <InstructorListPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/instructor-edit/:instructorId"
              element={
                <SidebarLayout menuType="website">
                  <InstructorEditPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/lesson-edit/:studioId"
              element={
                <SidebarLayout menuType="website">
                  <LessonEditPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/visual"
              element={
                <SidebarLayout menuType="website">
                  <VisualPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/qa"
              element={
                <SidebarLayout menuType="website">
                  <QaPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/faq-list"
              element={
                <SidebarLayout menuType="website">
                  <FaqListPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/faq-edit/:id?"
              element={
                <SidebarLayout menuType="website">
                  <FaqEditPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/news-list"
              element={
                <SidebarLayout menuType="website">
                  <NewsListPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/news-edit/:newsId?"
              element={
                <SidebarLayout menuType="website">
                  <NewsEditPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/review"
              element={
                <SidebarLayout menuType="website">
                  <ReviewPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/campaign-list"
              element={
                <SidebarLayout menuType="website">
                  <CampaignListPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/campaign-edit/:id?"
              element={
                <SidebarLayout menuType="website">
                  <CampaignEditPage />
                </SidebarLayout>
              }
            />
            <Route
              path="/website/analytics"
              element={
                <SidebarLayout menuType="website">
                  <AnalyticsPage />
                </SidebarLayout>
              }
            />
          </Routes>
          <div className="content-grid menu-btn" onClick={menuToggle}>
            <span className="leftLine"></span>
            <p>MENU</p>
            <span className="rightLine"></span>
          </div>
        </div>
      </>
    );
  };

  // サイドバー
  const SidebarLayout = ({ children, menuType }) => {
    const location = useLocation();

    // h1文字分割
    const h1Text = "RUDO FACTORY";
    const h1Spans = h1Text.split("").map((char, index) => (
      <i key={index} className={index === 0 ? "first-letter" : ""}>
        {char}
      </i>
    ));

    // メニュー マウスホバー時のイベントハンドラ
    const handleMouseEnter = () => {
      document.querySelector(".wrap").classList.remove("close");
      document.querySelector(".wrap").classList.add("open");
    };
    const handleMouseLeave = () => {
      // document.querySelector(".wrap").classList.remove("open");
      // document.querySelector(".wrap").classList.add("close");
    };

    // ログアウト
    const handleLogout = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/logout/`);
        if (!response.ok) {
          throw new Error("ネットワークレスポンスが異常です。");
        }
        const data = await response.json();
        console.log(data);
        await logout();
        window.location.href = "/login";
      } catch (error) {
        alert("ログアウトに失敗しました。");
      }
    };

    // 管理画面
    const managementMenu = (
      <>
        <Link to="/" className={location.pathname === "/" ? "active" : ""}>
          <span>
            <FontAwesomeIcon icon={faObjectsColumn} />
          </span>
          <p>ダッシュボード</p>
        </Link>
        <Link
          to="/management/customer-list"
          className={
            location.pathname === "/management/customer-list" ? "active" : ""
          }
        >
          <span>
            <FontAwesomeIcon icon={faList} />
          </span>
          <p>生徒一覧</p>
        </Link>
        <Link
          to="/management/free-customer-list"
          className={
            location.pathname === "/management/free-customer-list"
              ? "active"
              : ""
          }
        >
          <span>
            <FontAwesomeIcon icon={faClipboardList} />
          </span>
          <p>体験申込者一覧</p>
        </Link>
        <Link
          to="/management/send-mail"
          className={
            location.pathname === "/management/send-mail" ? "active" : ""
          }
        >
          <span>
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
          <p>メール</p>
        </Link>
        {/* {isSuperuser && (
          <Link
            to="/management/user-list"
            className={
              location.pathname === "/management/user-list" ? "active" : ""
            }
          >
            <span>
              <FontAwesomeIcon icon={faChild} />
            </span>
            <p>ユーザー管理</p>
          </Link>
        )} */}
        <Link
          to="/website/top-edit"
          className={location.pathname === "/website/top-edit" ? "active" : ""}
        >
          <span>
            <FontAwesomeIcon icon={faBrowser} />
          </span>
          <p>WEBサイト管理</p>
        </Link>
        <Link to="" onClick={handleLogout}>
          <span>
            <FontAwesomeIcon icon={faRightFromBracket} />
          </span>
          <p>ログアウト</p>
        </Link>
      </>
    );

    // WEBサイト管理
    const websiteMenu = (
      <>
        <Link
          to="/website/top-edit"
          className={location.pathname === "/website/top-edit" ? "active" : ""}
        >
          <span>
            <FontAwesomeIcon icon={faHome} />
          </span>
          <p> トップ</p>
        </Link>
        <Link
          to="/website/about-edit"
          className={
            location.pathname === "/website/about-edit" ? "active" : ""
          }
        >
          <span>
            <FontAwesomeIcon icon={faBook} />
          </span>
          <p> アバウト</p>
        </Link>
        <Link
          to="/website/price-edit"
          className={
            location.pathname === "/website/price-edit" ? "active" : ""
          }
        >
          <span>
            <FontAwesomeIcon icon={faCircleDollar} />
          </span>
          <p> プライス</p>
        </Link>
        <Link
          to="/website/studio-list"
          className={
            location.pathname === "/website/studio-list" ||
            location.pathname.startsWith("/website/studio-edit/")
              ? "active"
              : ""
          }
        >
          <span>
            <FontAwesomeIcon icon={faBuilding} />
          </span>
          <p> スタジオ</p>
        </Link>
        <Link
          to="/website/instructor-list"
          className={
            location.pathname === "/website/instructor-list" ||
            location.pathname.startsWith("/website/instructor-edit/")
              ? "active"
              : ""
          }
        >
          <span>
            <FontAwesomeIcon icon={faChild} />
          </span>
          <p> インストラクター</p>
        </Link>
        {/* <Link
          to="/website/lesson-list"
          className={
            location.pathname === "/website/lesson-list" ? "active" : ""
          }
        >
          <span>
            <FontAwesomeIcon icon={faCalendarCheck} />
          </span>
          <p> レッスン</p>
        </Link> */}
        <Link
          to="/website/visual"
          className={location.pathname === "/website/visual" ? "active" : ""}
        >
          <span>
            <FontAwesomeIcon icon={faCamera} />
          </span>
          <p> 写真 / 動画</p>
        </Link>
        <Link
          to="/website/qa"
          className={location.pathname === "/website/qa" ? "active" : ""}
        >
          <span>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </span>
          <p>Q & A</p>
        </Link>
        <Link
          to="/website/faq-list"
          className={
            location.pathname === "/website/faq-list" ||
            location.pathname.startsWith("/website/faq-edit/")
              ? "active"
              : ""
          }
        >
          <span>
            <FontAwesomeIcon icon={faClipboardQuestion} />
          </span>
          <p>よくある質問</p>
        </Link>
        <Link
          to="/website/news-list"
          className={location.pathname === "/website/news-list" ? "active" : ""}
        >
          <span>
            <FontAwesomeIcon icon={faEdit} />
          </span>
          <p>投稿</p>
        </Link>
        <Link
          to="/website/review"
          className={location.pathname === "/website/review" ? "active" : ""}
        >
          <span>
            <FontAwesomeIcon icon={faComment} />
          </span>
          <p> 口コミ</p>
        </Link>
        <Link
          to="/website/campaign-list"
          className={
            location.pathname === "/website/campaign-list" ? "active" : ""
          }
        >
          <span>
            <FontAwesomeIcon icon={faBullhorn} />
          </span>
          <p>キャンペーン</p>
        </Link>
        {/* <Link
          to="/website/analytics"
          className={location.pathname === "/website/analytics" ? "active" : ""}
        >
          <span>
            <FontAwesomeIcon icon={faChartBar} />
          </span>
          <p> 解析</p>
        </Link> */}
        <Link to="/" className={location.pathname === "/" ? "active" : ""}>
          <span>
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
          <p> 管理画面に戻る</p>
        </Link>
      </>
    );

    return (
      <>
        <div
          className="content-grid grid-1 sideMenu"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* タイトル */}
          <Link to="">
            <h1 className="h1Title">
              {h1Spans.map((span, index) => (
                <span key={index}>{span}</span>
              ))}
            </h1>
          </Link>

          {/* メニュー */}
          {menuType === "management" ? managementMenu : websiteMenu}
        </div>
        {children}
      </>
    );
  };

  return (
    <Router>
      <div className="App login">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          {/* <Route path="/signup" element={<SignUpPage />} /> */}
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="/reset-password/:uid/:token"
            element={<ResetPasswordPage />}
          />
          <Route path="*" element={<RenderPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
