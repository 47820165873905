import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setIsLoggedIn, setIsSuperuser } = useAuth();
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/login/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });
      if (!response.ok) {
        setIsLoggedIn(false);
        setIsSuperuser(false);
        localStorage.setItem("isLoggedIn", false);
        localStorage.setItem("isSuperuser", false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      setIsLoggedIn(true);

      if (data.is_superuser) {
        setIsSuperuser(true);
      }

      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("isSuperuser", data.is_superuser);
      navigate("/");
    } catch (error) {
      setIsLoggedIn(false);
      setIsSuperuser(false);
      localStorage.setItem("isLoggedIn", false);
      localStorage.setItem("isSuperuser", false);
      console.error("ログイン中にエラーが発生しました:", error);
      setError(error);
    }
  };

  return (
    <>
      <div className="loginWrap">
        <h2>RUDO ADMIN</h2>
        <div className="formWrap">
          {/* ユーザーネーム */}
          <label className="inputLabel text">
            <p>ユーザー名</p>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>

          {/*  */}
          <label className="inputLabel text">
            <p>パスワード</p>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>

          {/* パスワード忘れ */}
          <p>
            <a href="/forgot-password">パスワードを忘れた方</a>
          </p>

          {/* 新規登録 */}
          {/* <p>
            <a href="/signup">新規登録</a>
          </p> */}

          <button onClick={handleSubmit}>ログイン</button>

          {error && (
            <div className="message">
              <p color="red">ログインに失敗しました。</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default LoginPage;
