import React, { useState, useEffect, useCallback, memo } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Overlay from "../../components/overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import "../../styles/scss/managementSendMail.scss";

// メール作成、送信者選択、送信部分の表示
const MailContent = memo(
  ({
    studios,
    selectedStudios,
    changeSelectedStudios,
    lessons,
    selectedLessons,
    changeSelectedLessons,
    trialCustomers,
    selectedCustomers,
    setSelectedCustomers,
    customers,
    title,
    setTitle,
    content,
    setContent,
    sendMail,
    selectedDate,
    createMailTemplate,
  }) => {
    const [formattedDate, setFormattedDate] = useState("");

    useEffect(() => {
      if (selectedDate) {
        const date = new Date(selectedDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        setFormattedDate(`${year}/${month}/${day}`);
      }
    }, [selectedDate]);

    return (
      <div className="mailcontent">
        {/* 宛先 */}
        <div className="recipientWrap mailContent-grid">
          <p>日付</p>
          <div className="recipient-content">
            {formattedDate === "" && <p>日付を選択してください</p>}
            {/* <ul>
              <li className="on">体験</li>
              <li>会員</li>
            </ul> */}
            {/* 日付 */}
            <ul>{formattedDate && <li className="on">{formattedDate}</li>}</ul>
          </div>
          {studios && <p>店舗</p>}
          <div className="recipient-content">
            {studios &&
              formattedDate !== "" &&
              selectedStudios.length === 0 && <p>店舗を選択してください</p>}
            {/* 店舗名 */}
            {studios && (
              <ul>
                {Object.keys(studios).map((studioName) => (
                  <li
                    key={studioName}
                    className={selectedStudios.includes(studioName) ? "on" : ""}
                    onClick={() => changeSelectedStudios(studioName)}
                  >
                    {studioName}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {lessons.length > 0 && <p>レッスン</p>}
          <div className="recipient-content">
            {/* レッスン */}
            {lessons.length > 0 &&
              selectedStudios.length !== 0 &&
              selectedLessons.length === 0 && <p>レッスンを選択してください</p>}
            {lessons.length > 0 && (
              <ul>
                {lessons.map((lesson) => (
                  <li
                    key={lesson.id}
                    className={selectedLessons.includes(lesson.id) ? "on" : ""}
                    onClick={() => changeSelectedLessons(lesson.id)}
                  >
                    {lesson.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {selectedLessons.length !== 0 && <p>宛先</p>}
          <div className="recipient-content">
            {/* 生徒名 */}
            {trialCustomers.length === 0 && selectedLessons.length !== 0 && (
              <p>体験申込者が存在しません</p>
            )}
            {trialCustomers.length > 0 &&
              selectedLessons.length !== 0 &&
              selectedCustomers.length === 0 && <p>宛先を選択してください</p>}
            {trialCustomers.length > 0 && (
              <ul>
                {trialCustomers.map((customer, index) => (
                  <li
                    key={`trial-${index}`}
                    className={`btn2 ${
                      selectedCustomers.some(
                        (c) =>
                          c.email === customer.email && c.name === customer.name
                      )
                        ? "on"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedCustomers((prevSelected) => {
                        const exists = prevSelected.some(
                          (c) =>
                            c.email === customer.email &&
                            c.name === customer.name
                        );
                        if (exists) {
                          return prevSelected.filter(
                            (c) =>
                              c.email !== customer.email ||
                              c.name !== customer.name
                          );
                        } else {
                          return [...prevSelected, customer];
                        }
                      });
                    }}
                  >
                    {customer.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* タイトル */}
        <div className="ttlWrap mailContent-grid">
          <p>タイトル</p>
          <label className="form">
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </label>
        </div>

        {/* 本文 */}
        <div className="contentWrap mailContent-grid">
          <p>本文</p>
          <label className="form">
            <textarea
              name=""
              id=""
              rows="15"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            ></textarea>
          </label>
        </div>

        {/* 送信ボタン */}
        <div className="btn-wrap">
          <button className="submit" onClick={createMailTemplate}>
            定型文に登録
          </button>
          <button className="submit" onClick={sendMail}>
            送信
          </button>
        </div>
      </div>
    );
  }
);

function SendMailPage() {
  const [studios, setStudios] = useState(null); // スタジオ
  const [lessons, setLessons] = useState([]); // レッスン
  const [selectedDate, setSelectedDate] = useState(null); // 選択された日付
  const [selectedStudios, setSelectedStudios] = useState([]); // 選択されたスタジオ
  const [selectedLessons, setSelectedLessons] = useState([]); // 選択されたレッスン
  const [selectedCustomers, setSelectedCustomers] = useState([]); // 選択された顧客
  const [customers, setCustomers] = useState([]); // 顧客
  const [trialCustomers, setTrialCustomers] = useState([]); // 体験顧客
  const [title, setTitle] = useState(""); // 件名
  const [content, setContent] = useState(""); // 本文

  const [isOpen, setIsOpen] = useState(0); // ローディング

  const [mailTemplates, setMailTemplates] = useState([]); // メールテンプレート
  const [selectedTemplate, setSelectedTemplate] = useState(null); // 選択したテンプレート

  const breadcrumbs = [{ title: "メール", path: `/management/send-mail` }];

  // メールテンプレートの取得
  const getMailTemplates = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_mail_templates/`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setMailTemplates(data);
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, []);

  // 最初にメールテンプレートを取得
  useEffect(() => {
    getMailTemplates();
  }, []);

  // スタジオデータの取得
  const getStudios = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_target_week_studios/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          date: selectedDate,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      setStudios(data);
      setSelectedStudios([]);
      setSelectedLessons([]);
      setSelectedCustomers([]);
      setLessons([]);
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, [selectedDate]);

  // 体験顧客取得
  const getTrialCustomers = useCallback(async () => {
    if (selectedLessons.length === 0) return;

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/get_trial_customers_from_lesson/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lesson_ids: selectedLessons,
            selected_date: selectedDate,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setTrialCustomers(data);
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, [selectedLessons, selectedDate]);

  // 送信者をセット
  useEffect(() => {
    setSelectedCustomers([...trialCustomers, ...customers]);
  }, [trialCustomers, customers]);

  // メール送信
  const sendMail = useCallback(async () => {
    // バリデーション
    if (selectedCustomers.length === 0) {
      alert("送信対象が存在しません。");
      return;
    }
    if (title === "" || content === "") {
      alert("件名と本文は必ず入力して下さい");
      return;
    }

    const confirmSend = window.confirm(
      `この内容で送信してよろしいですか？\n\n件名: ${title}\n\n本文: \n${content}`
    );
    if (confirmSend) {
      setIsOpen(1);
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(
          `${apiUrl}/api/send_mail_trial_and_exist_customers/`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              selected_customers: selectedCustomers,
              subject: title,
              body: content,
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        alert("メールを送信しました。");
        setIsOpen(0);
      } catch (error) {
        alert("メールの送信に失敗しました。");
        setIsOpen(0);
        console.error("詳細の取得に失敗しました", error);
      }
    }
  }, [selectedCustomers, title, content]);

  useEffect(() => {
    getTrialCustomers();
  }, [getTrialCustomers]);

  // 選択スタジオの変更
  const changeSelectedStudios = (studioName) => {
    // スタジオのステート操作
    setSelectedStudios((prevStudios) => {
      const updatedStudios = [...prevStudios];
      const index = updatedStudios.indexOf(studioName);
      if (index === -1) {
        updatedStudios.push(studioName);
      } else {
        updatedStudios.splice(index, 1);
      }
      return updatedStudios;
    });

    // レッスンのステート操作
    const newLessons = studios[studioName];

    setLessons((prevLessons) => {
      const updatedLessons = [...prevLessons];
      newLessons.forEach((newLesson) => {
        const exists = updatedLessons.some(
          (lesson) => lesson.id === newLesson.id
        );
        if (!exists) {
          updatedLessons.push(newLesson);
        } else {
          const index = updatedLessons.findIndex(
            (lesson) => lesson.id === newLesson.id
          );
          updatedLessons.splice(index, 1);
        }
      });
      return updatedLessons;
    });

    setSelectedLessons([]);
    setSelectedCustomers([]);
    setCustomers([]);
    setTrialCustomers([]);
  };

  // 選択レッスンの変更
  const changeSelectedLessons = (lessonId) => {
    // レッスンのステート操作
    setSelectedLessons((prevLessons) => {
      const updatedLessons = [...prevLessons];
      const index = updatedLessons.indexOf(lessonId);
      if (index === -1) {
        updatedLessons.push(lessonId);
      } else {
        updatedLessons.splice(index, 1);
      }
      return updatedLessons;
    });

    setCustomers([]);
    setTrialCustomers([]);
  };

  // 選択された日付からスタジオリストを取得
  useEffect(() => {
    if (selectedDate) {
      getStudios();
    }
  }, [selectedDate]);

  // 定型文新規作成
  const createMailTemplate = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/create_mail_template/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subject: title,
          body: content,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }

      alert("保存しました");
      getMailTemplates(); // メールテンプレート再取得
    } catch (error) {
      console.error("データの送信中にエラーが発生しました:", error);
    }
  };

  // 定型文削除
  const deleteMailTemplate = async (templateId) => {
    const confirmDelete = window.confirm("本当に削除しますか？");
    if (!confirmDelete) {
      return; // キャンセルの場合は何もしない
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/delete_mail_template/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          template_id: templateId,
        }),
      });
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }

      alert("削除しました");
      getMailTemplates(); // メールテンプレート再取得
    } catch (error) {
      console.error("データの送信中にエラーが発生しました:", error);
    }
  };

  ///////////////////////////////////
  // ↑旧
  // ↓新
  /////////////////////////////

  // レイアウト
  // レイアウトを設定するためのstate
  const [layout, setLayout] = React.useState("1");

  // レイアウト変更の関数
  const changeLayout = (newLayout) => {
    setLayout(newLayout);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // alert("クリップボードにコピーしました: " + text);
      })
      .catch((err) => {
        // console.error("クリップボードへのコピーに失敗しました: ", err);
      });
  };

  ////////////// カレンダー部分ここから //////////////
  const [currentDate, setCurrentDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  ); // 当月の1日をデフォルトで設定

  // 前月に移動
  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));
  };

  // 翌月に移動
  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
  };

  // 指定された月の日数を取得
  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0).getDate();
  };

  // 指定された月の初日の曜日を取得
  const getFirstDayOfMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month, 1).getDay();
  };

  // カレンダーの表示
  const CalendarContent = () => {
    const daysInMonth = getDaysInMonth(currentDate);
    const firstDayOfMonth = getFirstDayOfMonth(currentDate);
    const today = new Date();

    const days = [];
    // 初日の曜日までは空を設定
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<span key={`empty-${i}`} className="empty"></span>);
    }
    // 日数分日付を設定
    for (let i = 1; i <= daysInMonth; i++) {
      const dayDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        i
      );
      const isToday = dayDate.toDateString() === today.toDateString();
      const isSelected =
        selectedDate &&
        dayDate.toDateString() === new Date(selectedDate).toDateString();
      days.push(
        <span
          key={i}
          className={`${isToday ? "today" : ""} ${isSelected ? "active" : ""}`}
          onClick={() => setSelectedDate(dayDate.toDateString())}
        >
          {i}
        </span>
      );
    }

    return (
      <div className="calendar-content">
        <div className="month-navigation">
          <p className="month">
            <span onClick={handlePrevMonth}> &lt; </span>
            {currentDate.getFullYear()}/{currentDate.getMonth() + 1}
            <span onClick={handleNextMonth}> &gt; </span>
          </p>
        </div>
        <div className="dateList">
          <span>日</span>
          <span>月</span>
          <span>火</span>
          <span>水</span>
          <span>木</span>
          <span>金</span>
          <span>土</span>
          {days}
        </div>
      </div>
    );
  };
  ////////////// カレンダー部分ここまで //////////////

  // メールテンプレートの表示
  const TemplateContent = () => {
    return (
      <div className="template-content">
        <p className="month">定型文</p>
        {mailTemplates.map((template, index) => (
          <button
            key={index}
            className="type-2"
            onClick={() => {
              setTitle(template.subject);
              setContent(template.body);
            }}
          >
            {template.subject}
            <span
              className="delete"
              onClick={() => {
                deleteMailTemplate(template.id);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </span>
          </button>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout}`}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <MailContent
          studios={studios}
          selectedStudios={selectedStudios}
          changeSelectedStudios={changeSelectedStudios}
          lessons={lessons}
          selectedLessons={selectedLessons}
          changeSelectedLessons={changeSelectedLessons}
          trialCustomers={trialCustomers}
          selectedCustomers={selectedCustomers}
          setSelectedCustomers={setSelectedCustomers}
          customers={customers}
          title={title}
          setTitle={setTitle}
          content={content}
          setContent={setContent}
          sendMail={sendMail}
          selectedDate={selectedDate}
          createMailTemplate={createMailTemplate}
        />
      </div>
      <div className={`content-grid grid-3 layout-type-${layout}`}>
        <CalendarContent />
        <TemplateContent />
      </div>
      <Overlay isOpen={isOpen} children={"送信中"} />
    </>
  );
}

export default SendMailPage;
