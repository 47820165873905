import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

function VideoUploader({
  setVideoSelectIsOpen,
  videoExtraLarge,
  setVideoExtraLarge,
  videoLarge,
  setVideoLarge,
  videoMedium,
  setVideoMedium,
  videoSmall,
  setVideoSmall,
  openMode,
}) {
  return (
    <>
      <button
        className="type-2"
        onClick={() => {
          setVideoSelectIsOpen(openMode);
        }}
      >
        編集
      </button>
      <div className="imgChecked">
        {videoExtraLarge && (
          <>
            <figure>
              <video src={videoExtraLarge.url} />
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="remove-icon"
                onClick={() => {
                  setVideoExtraLarge(null);
                }}
              />
              <p className="video-size">
                {videoExtraLarge.width} × {videoExtraLarge.height}
              </p>
            </figure>
          </>
        )}

        {videoLarge && (
          <>
            <figure>
              <video src={videoLarge.url} />
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="remove-icon"
                onClick={() => {
                  setVideoLarge(null);
                }}
              />
              <p className="video-size">
                {videoLarge.width} × {videoLarge.height}
              </p>
            </figure>
          </>
        )}

        {videoMedium && (
          <>
            <figure>
              <video src={videoMedium.url} />
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="remove-icon"
                onClick={() => {
                  setVideoMedium(null);
                }}
              />
              <p className="video-size">
                {videoMedium.width} × {videoMedium.height}
              </p>
            </figure>
          </>
        )}

        {videoSmall && (
          <>
            <figure>
              <video src={videoSmall.url} />
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="remove-icon"
                onClick={() => {
                  setVideoSmall(null);
                }}
              />
              <p className="video-size">
                {videoSmall.width} × {videoSmall.height}
              </p>
            </figure>
          </>
        )}
      </div>
    </>
  );
}

export default React.memo(VideoUploader);
