import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
} from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import "../../../styles/scss/website/top.scss";
import ImageUploader from "../../../components/website/ImageUploader";
import ImageSelect from "../../../components/website/ImageSelect";
import VideoUploader from "../../../components/website/VideoUploader";
import VideoSelect from "../../../components/website/VideoSelect";
import Iframe from "react-iframe";

const ForwardedIframe = forwardRef((props, ref) => (
  <Iframe {...props} innerRef={ref} />
));

const TopEditPage = () => {
  const [pageTopContent, setPageTopContent] = useState();
  const [message, setMessage] = useState("");

  const [imageSelectIsOpen, setImageSelectIsOpen] = useState("");
  const [videoSelectIsOpen, setVideoSelectIsOpen] = useState("");
  const [heroHedderImage, setHeroHedderImage] = useState({});
  const [pcVideoSmall, setPcVideoSmall] = useState(null);
  const [pcVideoMedium, setPcVideoMedium] = useState(null);
  const [pcVideoLarge, setPcVideoLarge] = useState(null);
  const [pcVideoExtraLarge, setPcVideoExtraLarge] = useState(null);
  const [spVideoSmall, setSpVideoSmall] = useState(null);
  const [spVideoMedium, setSpVideoMedium] = useState(null);
  const [spVideoLarge, setSpVideoLarge] = useState(null);
  const [spVideoExtraLarge, setSpVideoExtraLarge] = useState(null);
  const [aboutImage, setAboutImage] = useState({});

  // トップ詳細の取得
  const fetchPageTopContentData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_page_top_content/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setPageTopContent(data);
      setHeroHedderImage(data.hero_hedder_image);
      setPcVideoSmall(data.pc_video_small);
      setPcVideoMedium(data.pc_video_medium);
      setPcVideoLarge(data.pc_video_large);
      setPcVideoExtraLarge(data.pc_video_extra_large);
      setSpVideoSmall(data.mobile_video_small);
      setSpVideoMedium(data.mobile_video_medium);
      setSpVideoLarge(data.mobile_video_large);
      setSpVideoExtraLarge(data.mobile_video_extra_large);
      setAboutImage(data.about_image);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // inputタグが更新された時にstateを更新する関数
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPageTopContent((prev) => ({ ...prev, [name]: value }));
  };

  // トップ詳細の取得
  useEffect(() => {
    fetchPageTopContentData();
  }, [fetchPageTopContentData]);

  // トップ詳細を更新
  const handleTopPageDataSubmit = async () => {
    // 更新するデータ
    const formData = {
      page_top_content: pageTopContent,
      hero_hedder_image: heroHedderImage.id,
      pc_video_small: pcVideoSmall ? pcVideoSmall.id : null,
      pc_video_medium: pcVideoMedium ? pcVideoMedium.id : null,
      pc_video_large: pcVideoLarge ? pcVideoLarge.id : null,
      pc_video_extra_large: pcVideoExtraLarge ? pcVideoExtraLarge.id : null,
      mobile_video_small: spVideoSmall ? spVideoSmall.id : null,
      mobile_video_medium: spVideoMedium ? spVideoMedium.id : null,
      mobile_video_large: spVideoLarge ? spVideoLarge.id : null,
      mobile_video_extra_large: spVideoExtraLarge ? spVideoExtraLarge.id : null,
      about_image: aboutImage.id,
    };

    // バリデーション
    const errMsg = formValidation(formData);
    if (errMsg) {
      alert(errMsg);
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/update_page_top_content/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }
      setMessage(data.message);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // バリデーション
  function formValidation(formData) {
    // 必須チェック
    const requiredFields = [];
    if (!formData.hero_hedder_image)
      requiredFields.push("ヒーローヘッダー画像");
    if (!formData.pc_video_small) requiredFields.push("PC用動画(720p)");
    if (!formData.pc_video_medium) requiredFields.push("PC用動画(1080p)");
    if (!formData.pc_video_large) requiredFields.push("PC用動画(1440p)");
    if (!formData.pc_video_extra_large) requiredFields.push("PC用動画(2160p)");
    if (!formData.mobile_video_small) requiredFields.push("スマホ用動画(720p)");
    if (!formData.mobile_video_medium)
      requiredFields.push("スマホ用動画(1080p)");
    if (!formData.mobile_video_large)
      requiredFields.push("スマホ用動画(1440p)");
    if (!formData.mobile_video_extra_large)
      requiredFields.push("スマホ用動画(2160p)");
    if (!formData.page_top_content.title_en)
      requiredFields.push("タイトル(英語)");
    if (!formData.page_top_content.title_ja)
      requiredFields.push("タイトル(日本語)");
    if (!formData.about_image) requiredFields.push("アバウト 画像");
    if (!formData.page_top_content.about_title_en)
      requiredFields.push("アバウト タイトル(英語)");
    if (!formData.page_top_content.about_description)
      requiredFields.push("アバウト 説明");
    if (!formData.page_top_content.newvisitor_catchcopy)
      requiredFields.push("初めての方へ キャッチコピー");
    if (!formData.page_top_content.newvisitor_title)
      requiredFields.push("初めての方へ タイトル");
    if (!formData.page_top_content.newvisitor_description)
      requiredFields.push("初めての方へ 説明");
    if (!formData.page_top_content.newvisitor_button_text)
      requiredFields.push("キャンペーン ボタンテキスト");
    if (!formData.page_top_content.campaign_title)
      requiredFields.push("キャンペーン タイトル");
    if (!formData.page_top_content.qa_description)
      requiredFields.push("Q&A 説明");
    const errMsg =
      requiredFields.length != 0
        ? `${requiredFields.join("、")}を設定してください。`
        : "";
    return errMsg;
  }

  // レイアウト
  // レイアウトを設定するためのstate
  const [layout, setLayout] = useState("2");

  const breadcrumbs = [{ title: "トップ詳細", path: `/website/top-edit` }];

  const origin = process.env.REACT_APP_ORIGIN;
  const iframeRef = useRef(null);

  // 管理画面 iframe用
  useEffect(() => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const updateIframeContent = () => {
        try {
          iframe.contentWindow.postMessage(
            {
              pageTopContent,
              heroHedderImage,
              pcVideoSmall,
              pcVideoMedium,
              pcVideoLarge,
              pcVideoExtraLarge,
              spVideoSmall,
              spVideoMedium,
              spVideoLarge,
              spVideoExtraLarge,
              aboutImage,
            },
            origin
          );
        } catch (error) {
          console.error("クロスオリジンのエラーが発生しました:", error);
        }
      };

      iframe.onload = updateIframeContent;
      updateIframeContent();
    }
  }, [
    pageTopContent,
    heroHedderImage,
    pcVideoSmall,
    pcVideoMedium,
    pcVideoLarge,
    pcVideoExtraLarge,
    spVideoSmall,
    spVideoMedium,
    spVideoLarge,
    spVideoExtraLarge,
    aboutImage,
  ]);

  // メッセージを非表示
  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 5000);
  }, [message]);

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        {/* サイト表示 */}
        <div className="siteView">
          <ForwardedIframe
            ref={iframeRef}
            url={origin}
            width="100%"
            height="1000px"
          />
        </div>
      </div>
      <div className={`content-grid grid-3 layout-type-${layout}`}>
        <div className="textForm">
          <ul>
            {/* ヒーローヘッダー画像 */}
            <li className="heroheader">
              <p>ヒーローヘッダー画像</p>
              <ImageUploader
                formName={"heroHedderImage"}
                type={"single"}
                selected={heroHedderImage}
                setSelected={setHeroHedderImage}
                setImageSelectIsOpen={setImageSelectIsOpen}
              />
            </li>
            {/* ヒーローヘッダー動画PC */}
            <li className="heroheader">
              <p>ヒーローヘッダー動画PC</p>
              <VideoUploader
                setVideoSelectIsOpen={setVideoSelectIsOpen}
                videoExtraLarge={pcVideoExtraLarge}
                setVideoExtraLarge={setPcVideoExtraLarge}
                videoLarge={pcVideoLarge}
                setVideoLarge={setPcVideoLarge}
                videoMedium={pcVideoMedium}
                setVideoMedium={setPcVideoMedium}
                videoSmall={pcVideoSmall}
                setVideoSmall={setPcVideoSmall}
                openMode={"PC"}
              />
            </li>
            {/* ヒーローヘッダー動画SP */}
            <li className="heroheader">
              <p>ヒーローヘッダー動画SP</p>
              <VideoUploader
                setVideoSelectIsOpen={setVideoSelectIsOpen}
                videoExtraLarge={spVideoExtraLarge}
                setVideoExtraLarge={setSpVideoExtraLarge}
                videoLarge={spVideoLarge}
                setVideoLarge={setSpVideoLarge}
                videoMedium={spVideoMedium}
                setVideoMedium={setSpVideoMedium}
                videoSmall={spVideoSmall}
                setVideoSmall={setSpVideoSmall}
                openMode={"SP"}
              />
            </li>
            <li>
              <p>タイトル(英語)</p>
              <label className="form">
                <input
                  type="text"
                  name="title_en"
                  value={pageTopContent?.title_en}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>
              <p>タイトル(日本語)</p>
              <label className="form">
                <input
                  type="text"
                  name="title_ja"
                  value={pageTopContent?.title_ja}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li className="heroheader">
              <p>アバウト 画像</p>
              <ImageUploader
                formName={"aboutImage"}
                type={"single"}
                selected={aboutImage}
                setSelected={setAboutImage}
                setImageSelectIsOpen={setImageSelectIsOpen}
              />
            </li>
            <li>
              <p>アバウト タイトル(英語)</p>
              <label className="form">
                <input
                  type="text"
                  name="about_title_en"
                  value={pageTopContent?.about_title_en}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>
              <p>アバウト 説明</p>
              <label className="form">
                <textarea
                  name="about_description"
                  value={pageTopContent?.about_description}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>
              <p>初めての方へ キャッチコピー</p>
              <label className="form">
                <input
                  type="text"
                  name="newvisitor_catchcopy"
                  value={pageTopContent?.newvisitor_catchcopy}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>
              <p>初めての方へ タイトル</p>
              <label className="form">
                <input
                  type="text"
                  name="newvisitor_title"
                  value={pageTopContent?.newvisitor_title}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>
              <p>初めての方へ 説明</p>
              <label className="form">
                <textarea
                  name="newvisitor_description"
                  value={pageTopContent?.newvisitor_description}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>
              <p>キャンペーン ボタンテキスト</p>
              <label className="form">
                <input
                  type="text"
                  name="newvisitor_button_text"
                  value={pageTopContent?.newvisitor_button_text}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>
              <p>キャンペーン タイトル</p>
              <label className="form">
                <input
                  type="text"
                  name="campaign_title"
                  value={pageTopContent?.campaign_title}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>
              <p>Q&A 説明</p>
              <label className="form">
                <textarea
                  name="qa_description"
                  value={pageTopContent?.qa_description}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li className="submit">
              <button
                type="button"
                className="submit type-3 type-3"
                onClick={handleTopPageDataSubmit}
              >
                更新
              </button>
            </li>
          </ul>
        </div>
        {message && (
          <div className="message success">
            <p>{message}</p>
          </div>
        )}
      </div>

      {/* 画像選択オーバーレイ */}
      {imageSelectIsOpen === "heroHedderImage" && (
        <ImageSelect
          type="single"
          setImageSelectIsOpen={setImageSelectIsOpen}
          selected={heroHedderImage}
          setSelected={setHeroHedderImage}
        />
      )}
      {imageSelectIsOpen === "aboutImage" && (
        <ImageSelect
          type="single"
          setImageSelectIsOpen={setImageSelectIsOpen}
          selected={aboutImage}
          setSelected={setAboutImage}
        />
      )}
      {videoSelectIsOpen === "PC" && (
        <VideoSelect
          setVideoSelectIsOpen={setVideoSelectIsOpen}
          videoSmall={pcVideoSmall}
          videoMedium={pcVideoMedium}
          videoLarge={pcVideoLarge}
          videoExtraLarge={pcVideoExtraLarge}
          setVideoSmall={setPcVideoSmall}
          setVideoMedium={setPcVideoMedium}
          setVideoLarge={setPcVideoLarge}
          setVideoExtraLarge={setPcVideoExtraLarge}
        />
      )}
      {videoSelectIsOpen === "SP" && (
        <VideoSelect
          setVideoSelectIsOpen={setVideoSelectIsOpen}
          videoSmall={spVideoSmall}
          videoMedium={spVideoMedium}
          videoLarge={spVideoLarge}
          videoExtraLarge={spVideoExtraLarge}
          setVideoSmall={setSpVideoSmall}
          setVideoMedium={setSpVideoMedium}
          setVideoLarge={setSpVideoLarge}
          setVideoExtraLarge={setSpVideoExtraLarge}
        />
      )}
    </>
  );
};

export default TopEditPage;
