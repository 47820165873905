import React, { useState, useEffect, useCallback, memo } from "react";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import Breadcrumbs from "../../../components/Breadcrumbs";
import "../../../styles/scss/website/review.scss";

const ReviewEditContent = memo(
  ({
    review,
    setReview,
    handleToggle,
    handleInputChange,
    handleReviewDataSubmit,
    handleReviewDataUpdate,
    edit,
  }) => {
    useEffect(() => {
      console.log(review);
    }, [review]);

    return (
      <>
        {edit ? (
          <p className="ttl">口コミを編集</p>
        ) : (
          <p className="ttl">新しい口コミを追加</p>
        )}
        <div className="List">
          <ul className="gridList reviewEditList">
            <li>投稿者</li>
            <li>
              <label className="form">
                <input
                  type="text"
                  name="reviewer_info"
                  value={review.reviewer_info || ""}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>本文</li>
            <li>
              <label className="form">
                <textarea
                  name="content"
                  value={review.content || ""}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>返信</li>
            <li>
              <label className="form">
                <input
                  type="text"
                  name="reply"
                  value={review.reply || ""}
                  onChange={handleInputChange}
                />
              </label>
            </li>

            {/*  */}
            <li>公開</li>
            <li>
              {/* アクティブの場合.active */}
              <label
                className={`form switch${review.is_published ? " active" : ""}`}
              >
                <input
                  type="radio"
                  name="is_published"
                  value={review.is_published === true ? "true" : "false"}
                  checked={review.is_published === true}
                  onClick={handleToggle}
                />
              </label>
            </li>

            <li className="submit">
              {edit ? (
                <button
                  type="submit"
                  className="submit type-3 type-3"
                  onClick={handleReviewDataUpdate}
                >
                  口コミを更新する
                </button>
              ) : (
                <button
                  type="submit"
                  className="submit type-3 type-3"
                  onClick={handleReviewDataSubmit}
                >
                  口コミを追加する
                </button>
              )}
            </li>
          </ul>
        </div>
      </>
    );
  }
);

function ReviewPage() {
  const [reviews, setReviews] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [review, setReview] = useState({});
  const [edit, setEdit] = useState(false);

  const breadcrumbs = [{ title: "口コミ", path: `/website/review` }];

  // 口コミ一覧取得
  const fetchReviewsData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_reviews/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setReviews(data);
      console.log(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  useEffect(() => {
    fetchReviewsData();
  }, [fetchReviewsData]);

  // 入力フォーム変更
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReview((prevReview) => ({
      ...prevReview,
      [name]: value,
    }));
  };

  // 公開用トグル
  const handleToggle = async (e) => {
    const { name, value } = e.target;
    setReview((prevReview) => ({
      ...prevReview,
      [name]: value === "true" ? false : true,
    }));
  };

  // 口コミ追加
  const handleReviewDataSubmit = async () => {
    const errMsg = [];
    if (!review.reviewer_info) errMsg.push("投稿者");
    if (!review.content) errMsg.push("本文");
    if (errMsg.length !== 0) {
      alert(`${errMsg.join("、")}を入力してください`);
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/save_review/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: review.content,
          reply: review.reply,
          reviewer_info: review.reviewer_info,
          is_published: review.is_published,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      alert(data.message);
      setReview({});
      fetchReviewsData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 口コミ編集
  const handleReviewDataUpdate = async () => {
    const errMsg = [];
    if (!review.reviewer_info) errMsg.push("投稿者");
    if (!review.content) errMsg.push("本文");
    if (errMsg.length !== 0) {
      alert(`${errMsg.join("、")}を入力してください`);
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/update_review/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          review_id: review.id,
          content: review.content,
          reply: review.reply,
          reviewer_info: review.reviewer_info,
          is_published: review.is_published,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      alert(data.message);
      fetchReviewsData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 口コミ削除
  const handleReviewDataDelete = async (review_id) => {
    const confirmation = window.confirm(
      "口コミの削除は取り消せません。\n本当に削除しますか？"
    );
    if (!confirmation) {
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/delete_review/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          review_id: review_id,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }
      alert(data.message);
      fetchReviewsData();
      setIsOpen(false);
      setLayout("4");
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 編集用のフォーム
  const handleEditOpen = (reviewItem) => {
    setEdit(true);
    if (isOpen) {
      if (reviewItem.id === review.id) {
        setIsOpen(false);
        setLayout("4");
        setReview({});
      } else {
        setIsOpen(true);
        setLayout("1");
        setReview({
          id: reviewItem.id,
          content: reviewItem.content,
          reply: reviewItem.reply,
          reviewer_info: reviewItem.reviewer_info,
          is_published: reviewItem.is_published,
        });
      }
    } else {
      setIsOpen(true);
      setLayout("1");
      setReview({
        id: reviewItem.id,
        content: reviewItem.content,
        reply: reviewItem.reply,
        reviewer_info: reviewItem.reviewer_info,
        is_published: reviewItem.is_published,
      });
    }
  };

  // レイアウト
  // レイアウトを設定するためのstate
  const [layout, setLayout] = useState("4");

  const ReviewContent = () => {
    return (
      <div className="reviewcontent">
        <div className="List">
          <div className="gridList">
            {reviews.map((reviewItem) => (
              <ul
                className="listContents"
                onClick={() => {
                  handleEditOpen(reviewItem);
                }}
              >
                <li>
                  <i>
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </i>
                  <span>{reviewItem.reviewer_info}</span>
                </li>
                <li>
                  {reviewItem.content &&
                    parse(reviewItem.content.replace(/\n/g, "<br>"))}
                </li>
                <li>{reviewItem.reply}</li>

                {/*  */}
                {reviewItem.is_published == true && (
                  <li className="published">
                    <i>
                      <FontAwesomeIcon icon={faCheck} />
                    </i>
                    <span>公開</span>
                  </li>
                )}

                {/*  */}
                <li
                  className="delete"
                  onClick={() => {
                    handleReviewDataDelete(reviewItem.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <div className="add">
          <button
            className="type-1"
            onClick={() => {
              setReview({ is_published: false });
              setEdit(false);
              if (isOpen) {
                if (edit === true) {
                  setIsOpen(true);
                  setLayout("1");
                } else {
                  setIsOpen(false);
                  setLayout("4");
                }
              } else {
                setIsOpen(true);
                setLayout("1");
              }
            }}
          >
            口コミを追加
          </button>
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReviewContent />
      </div>
      <div
        className={`content-grid grid-3 layout-type-${layout}`}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <ReviewEditContent
          review={review}
          setReview={setReview}
          handleToggle={handleToggle}
          handleInputChange={handleInputChange}
          handleReviewDataSubmit={handleReviewDataSubmit}
          handleReviewDataUpdate={handleReviewDataUpdate}
          edit={edit}
        />
      </div>
    </>
  );
}

export default ReviewPage;
