import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
} from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import "../../../styles/scss/website/about.scss";
import ImageSelect from "../../../components/website/ImageSelect";
import VideoUploader from "../../../components/website/VideoUploader";
import VideoSelect from "../../../components/website/VideoSelect";
import Iframe from "react-iframe";
import ConceptDescription from "../../../components/website/about/ConceptDescription";

const ForwardedIframe = forwardRef((props, ref) => (
  <Iframe {...props} innerRef={ref} />
));

const AboutEditPage = () => {
  const [pageAboutContent, setPageAboutContent] = useState();
  const [message, setMessage] = useState("");

  const [imageSelectIsOpen, setImageSelectIsOpen] = useState("");
  const [videoSelectIsOpen, setVideoSelectIsOpen] = useState("");
  const [pcVideoSmall, setPcVideoSmall] = useState(null);
  const [pcVideoMedium, setPcVideoMedium] = useState(null);
  const [pcVideoLarge, setPcVideoLarge] = useState(null);
  const [pcVideoExtraLarge, setPcVideoExtraLarge] = useState(null);
  const [spVideoSmall, setSpVideoSmall] = useState(null);
  const [spVideoMedium, setSpVideoMedium] = useState(null);
  const [spVideoLarge, setSpVideoLarge] = useState(null);
  const [spVideoExtraLarge, setSpVideoExtraLarge] = useState(null);
  const [conceptDescription, setConceptDescription] = useState([]);

  // アバウト詳細の取得
  const fetchPageAboutContentData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_page_about_content/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setPageAboutContent(data);
      setPcVideoSmall(data.pc_video_small);
      setPcVideoMedium(data.pc_video_medium);
      setPcVideoLarge(data.pc_video_large);
      setPcVideoExtraLarge(data.pc_video_extra_large);
      setSpVideoSmall(data.mobile_video_small);
      setSpVideoMedium(data.mobile_video_medium);
      setSpVideoLarge(data.mobile_video_large);
      setSpVideoExtraLarge(data.mobile_video_extra_large);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // アバウト詳細の取得
  useEffect(() => {
    fetchPageAboutContentData();
  }, [fetchPageAboutContentData]);

  // inputタグが更新された時にstateを更新する関数
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPageAboutContent((prev) => ({ ...prev, [name]: value }));
  };

  // アバウト詳細を更新
  const handleAboutPageDataSubmit = async () => {
    // 更新するデータ
    const formData = {
      page_about_content: pageAboutContent,
      pc_video_small: pcVideoSmall ? pcVideoSmall.id : null,
      pc_video_medium: pcVideoMedium ? pcVideoMedium.id : null,
      pc_video_large: pcVideoLarge ? pcVideoLarge.id : null,
      pc_video_extra_large: pcVideoExtraLarge ? pcVideoExtraLarge.id : null,
      mobile_video_small: spVideoSmall ? spVideoSmall.id : null,
      mobile_video_medium: spVideoMedium ? spVideoMedium.id : null,
      mobile_video_large: spVideoLarge ? spVideoLarge.id : null,
      mobile_video_extra_large: spVideoExtraLarge ? spVideoExtraLarge.id : null,
    };

    // バリデーション
    const errMsg = formValidation(formData);
    if (errMsg) {
      alert(errMsg);
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/update_page_about_content/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }
      setMessage(data.message);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // バリデーション
  function formValidation(formData) {
    // 必須チェック
    const requiredFields = [];
    if (!formData.pc_video_small) requiredFields.push("PC用動画(720p)");
    if (!formData.pc_video_medium) requiredFields.push("PC用動画(1080p)");
    if (!formData.pc_video_large) requiredFields.push("PC用動画(1440p)");
    if (!formData.pc_video_extra_large) requiredFields.push("PC用動画(2160p)");
    if (!formData.mobile_video_small) requiredFields.push("スマホ用動画(720p)");
    if (!formData.mobile_video_medium)
      requiredFields.push("スマホ用動画(1080p)");
    if (!formData.mobile_video_large)
      requiredFields.push("スマホ用動画(1440p)");
    if (!formData.mobile_video_extra_large)
      requiredFields.push("スマホ用動画(2160p)");
    if (!formData.page_about_content.concept_message)
      requiredFields.push("コンセプトメッセージ");
    console.log(formData.page_about_content.concept_description);
    if (formData.page_about_content.concept_description) {
      formData.page_about_content.concept_description.forEach(
        (description, index) => {
          if (!description.title) requiredFields.push(`タイトル${index + 1}`);
          if (!description.content) requiredFields.push(`説明${index + 1}`);
          if (Object.keys(description.image).length === 0)
            requiredFields.push(`画像${index + 1}`);
        }
      );
    }
    const errMsg =
      requiredFields.length != 0
        ? `${requiredFields.join("、")}を設定してください。`
        : "";
    return errMsg;
  }

  // レイアウト
  // レイアウトを設定するためのstate
  const [layout, setLayout] = useState("2");

  const breadcrumbs = [{ title: "アバウト詳細", path: `/website/about-edit` }];

  const origin = process.env.REACT_APP_ORIGIN;
  const iframeRef = useRef(null);

  // 管理画面 iframe用
  useEffect(() => {
    const iframe = document.querySelector("iframe");
    if (iframe) {
      const updateIframeContent = () => {
        try {
          iframe.contentWindow.postMessage(
            {
              pageAboutContent,
              pcVideoSmall,
              pcVideoMedium,
              pcVideoLarge,
              pcVideoExtraLarge,
              spVideoSmall,
              spVideoMedium,
              spVideoLarge,
              spVideoExtraLarge,
              conceptDescription,
            },
            origin
          );
        } catch (error) {
          console.error("クロスオリジンのエラーが発生しました:", error);
        }
      };

      iframe.onload = updateIframeContent;
      updateIframeContent();
    }
  }, [
    pageAboutContent,
    pcVideoSmall,
    pcVideoMedium,
    pcVideoLarge,
    pcVideoExtraLarge,
    spVideoSmall,
    spVideoMedium,
    spVideoLarge,
    spVideoExtraLarge,
    conceptDescription,
  ]);

  // メッセージを非表示
  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 5000);
  }, [message]);

  // 画像変更
  const handleSetImage = (image, index) => {
    const newConceptDescription = [...pageAboutContent.concept_description];
    newConceptDescription[index].image = image;
    setPageAboutContent((prev) => ({
      ...prev,
      concept_description: newConceptDescription,
    }));
  };

  // ConceptDescriptionに渡す用(フォーカスが外れた時に使用)
  const updateConceptDescription = (newDescriptions) => {
    setPageAboutContent((prev) => ({
      ...prev,
      concept_description: newDescriptions,
    }));
  };

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        {/* サイト表示 */}
        <div className="siteView">
          <ForwardedIframe
            ref={iframeRef}
            url={`${origin}/about`}
            width="100%"
            height="1000px"
          />
        </div>
      </div>
      <div className={`content-grid grid-3 layout-type-${layout}`}>
        <div className="textForm">
          <ul>
            {/* ヒーローヘッダー動画PC */}
            <li className="heroheader">
              <p>ヒーローヘッダー動画PC</p>
              <VideoUploader
                setVideoSelectIsOpen={setVideoSelectIsOpen}
                videoExtraLarge={pcVideoExtraLarge}
                setVideoExtraLarge={setPcVideoExtraLarge}
                videoLarge={pcVideoLarge}
                setVideoLarge={setPcVideoLarge}
                videoMedium={pcVideoMedium}
                setVideoMedium={setPcVideoMedium}
                videoSmall={pcVideoSmall}
                setVideoSmall={setPcVideoSmall}
                openMode={"PC"}
              />
            </li>
            {/* ヒーローヘッダー動画SP */}
            <li className="heroheader">
              <p>ヒーローヘッダー動画SP</p>
              <VideoUploader
                setVideoSelectIsOpen={setVideoSelectIsOpen}
                videoExtraLarge={spVideoExtraLarge}
                setVideoExtraLarge={setSpVideoExtraLarge}
                videoLarge={spVideoLarge}
                setVideoLarge={setSpVideoLarge}
                videoMedium={spVideoMedium}
                setVideoMedium={setSpVideoMedium}
                videoSmall={spVideoSmall}
                setVideoSmall={setSpVideoSmall}
                openMode={"SP"}
              />
            </li>
            <li>
              <p>メッセージ</p>
              <label className="form">
                <textarea
                  name="concept_message"
                  value={pageAboutContent?.concept_message}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            {pageAboutContent && pageAboutContent.concept_description && (
              <ConceptDescription
                conceptDescription={pageAboutContent.concept_description}
                updateConceptDescription={updateConceptDescription}
                setImageSelectIsOpen={setImageSelectIsOpen}
                setConceptDescription={setConceptDescription}
              />
            )}

            <li className="submit">
              <button
                type="button"
                className="submit type-3 type-3"
                onClick={handleAboutPageDataSubmit}
              >
                更新
              </button>
            </li>
          </ul>
        </div>
        {message && (
          <div className="message success">
            <p>{message}</p>
          </div>
        )}
      </div>

      {/* 画像選択オーバーレイ */}
      {videoSelectIsOpen === "PC" && (
        <VideoSelect
          setVideoSelectIsOpen={setVideoSelectIsOpen}
          videoSmall={pcVideoSmall}
          videoMedium={pcVideoMedium}
          videoLarge={pcVideoLarge}
          videoExtraLarge={pcVideoExtraLarge}
          setVideoSmall={setPcVideoSmall}
          setVideoMedium={setPcVideoMedium}
          setVideoLarge={setPcVideoLarge}
          setVideoExtraLarge={setPcVideoExtraLarge}
        />
      )}
      {videoSelectIsOpen === "SP" && (
        <VideoSelect
          setVideoSelectIsOpen={setVideoSelectIsOpen}
          videoSmall={spVideoSmall}
          videoMedium={spVideoMedium}
          videoLarge={spVideoLarge}
          videoExtraLarge={spVideoExtraLarge}
          setVideoSmall={setSpVideoSmall}
          setVideoMedium={setSpVideoMedium}
          setVideoLarge={setSpVideoLarge}
          setVideoExtraLarge={setSpVideoExtraLarge}
        />
      )}
      {pageAboutContent &&
        pageAboutContent.concept_description &&
        pageAboutContent.concept_description.map((description, index) => {
          return (
            imageSelectIsOpen === `image-${index}` && (
              <ImageSelect
                type="single"
                setImageSelectIsOpen={setImageSelectIsOpen}
                selected={description.image}
                setSelected={(e) => {
                  handleSetImage(e, index);
                }}
              />
            )
          );
        })}
    </>
  );
};

export default AboutEditPage;
