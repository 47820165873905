import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-light-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

function ImageUploader({
  formName,
  type,
  selected,
  setSelected,
  setImageSelectIsOpen,
}) {
  const handleDrop = useCallback(async (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length === 1 && files[0].type.startsWith("image/")) {
      try {
        const formData = new FormData();
        formData.append("images", files[0]);

        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/image_upload/`, {
          method: "POST",
          credentials: "include",
          body: formData,
        });

        if (!response.ok) {
          throw new Error("画像のアップロードに失敗しました。");
        }

        const data = await response.json();
        // ここで返されたデータを使用して、必要に応じて状態を更新します。
        console.log("アップロードされた画像:", data);
        setSelected(data.images[0]);
      } catch (error) {
        console.error("アップロード中にエラーが発生しました:", error);
      }
    } else {
      alert("画像は1枚のみアップロード可能です。");
    }
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {/*  画像がないとき */}
      <div className="upload">
        {/* アップロード */}
        <div
          className="upload-area"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {/*  */}
          <FontAwesomeIcon icon={faImage} />
          <p>
            画像/動画をドラッグ&ドロップ
            <br />
            (最大12MB)
          </p>
        </div>
        {/* 画像を選択 */}
        <button
          className="imgSelectBtn"
          onClick={() => {
            setImageSelectIsOpen(formName);
          }}
        >
          画像を選択
        </button>
      </div>

      {/*  画像選択後 */}
      {type === "single" && selected && selected.id && (
        <div className="imgChecked">
          <figure key={selected.id}>
            <img src={selected.url} alt="" />
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="remove-icon"
              onClick={() => {
                setSelected({});
              }}
            />
          </figure>
        </div>
      )}
      {type === "multiple" && selected && selected.length > 0 && (
        <div className="imgChecked">
          {selected.map((image) => (
            <figure key={image.id}>
              <img src={image.url} alt="" />
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="remove-icon"
                onClick={() => {
                  setSelected((prevTempSelected) => {
                    if (Array.isArray(prevTempSelected)) {
                      if (
                        prevTempSelected.some(
                          (selectedImage) => selectedImage.id === image.id
                        )
                      ) {
                        return prevTempSelected.filter(
                          (selectedImage) => selectedImage.id !== image.id
                        );
                      } else {
                        return [...prevTempSelected, image];
                      }
                    } else {
                      return [image];
                    }
                  });
                }}
              />
            </figure>
          ))}
        </div>
      )}
    </>
  );
}

export default React.memo(ImageUploader);
