import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/pro-solid-svg-icons";

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <nav className="breadcrumb">
      <ul>
        <li>
          <FontAwesomeIcon icon={faHome} />
        </li>
        {breadcrumbs.map((breadcrumb, i) => {
          return (
            <li key={i}>
              <Link to={breadcrumb.path}>{breadcrumb.title}</Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default React.memo(Breadcrumbs);
