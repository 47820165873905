import React, { useState, useEffect, useCallback, memo } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Overlay from "../../../components/overlay";
import "../../../styles/scss/website/instructorEdit.scss";
import LibraryWindow from "../../../components/website/LibraryWindow";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemType = "IMAGE";

const Image = ({ id, src, index, moveImage }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { id, index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item) => {
      if (item.index !== index) {
        moveImage(item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <li ref={(node) => ref(drop(node))} style={{ cursor: "move" }}>
      <img
        src={src}
        alt="Studio Image"
        height="125"
        width="125"
        onClick={() => {
          moveImage(index, -1); // -1を指定して削除を表現
        }}
      />
    </li>
  );
};

const InstructorEditContent = memo(
  ({
    instructor,
    handleInputChange,
    isActive,
    setIsActive,
    handleToggle,
    message,
    tabs,
    selectedTab,
    setSelectedTab,
    newMultipleTopImages,
    setNewMultipleTopImages,
    newMultipleImages,
    setNewMultipleImages,
    uploadTopImages,
    setUploadTopImages,
    uploadImages,
    setUploadImages,
    setIsUploaded,
    setIsOpen,
  }) => {
    useEffect(() => {
      handleImageSubmit();
    }, [uploadImages]);

    useEffect(() => {
      handleImageSubmit();
    }, [uploadTopImages]);

    // 画像をAPIに送信（アップロード）
    const handleImageSubmit = async () => {
      if (uploadImages.length === 0 && uploadTopImages.length === 0) {
        return;
      }
      setIsOpen(1);
      const formData = new FormData();
      if (selectedTab.type === "top") {
        uploadTopImages.forEach((image) => {
          formData.append("images", image);
        });
      } else if (selectedTab.type === "multiple") {
        uploadImages.forEach((image) => {
          formData.append("images", image);
        });
      }

      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/image_upload/`, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);

        if (selectedTab.type === "top") {
          setNewMultipleTopImages((prevImages) => [
            ...prevImages,
            ...data.images,
          ]);
        } else if (selectedTab.type === "multiple") {
          setNewMultipleImages((prevImages) => [...prevImages, ...data.images]);
        }

        setIsUploaded(true);
        alert("アップロードが完了しました");
        setIsOpen(0);
      } catch (error) {
        alert("登録に失敗しました");
        setIsOpen(0);
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    // stateに画像をセット
    const handleImageChange = (event) => {
      if (selectedTab.type === "top") {
        setUploadTopImages([...event.target.files]);
      } else if (selectedTab.type === "multiple") {
        setUploadImages([...event.target.files]);
      }
    };

    const moveImage = (fromIndex, toIndex) => {
      if (selectedTab.type === "top") {
        setNewMultipleTopImages((prevImages) => {
          const updatedImages = [...prevImages];
          if (toIndex === -1) {
            // 画像を削除
            updatedImages.splice(fromIndex, 1);
          } else {
            const [movedImage] = updatedImages.splice(fromIndex, 1);
            updatedImages.splice(toIndex, 0, movedImage);
          }
          return updatedImages;
        });
      } else if (selectedTab.type === "multiple") {
        setNewMultipleImages((prevImages) => {
          const updatedImages = [...prevImages];
          if (toIndex === -1) {
            // 画像を削除
            updatedImages.splice(fromIndex, 1);
          } else {
            const [movedImage] = updatedImages.splice(fromIndex, 1);
            updatedImages.splice(toIndex, 0, movedImage);
          }
          return updatedImages;
        });
      }
    };

    return (
      <>
        <div className="List studioEditList">
          {/* フォーム */}
          <ul className="form1">
            {/*  */}
            <li>
              <span>ダンサーネーム</span>
              <label className="form">
                <input
                  type="text"
                  name="dancer_name"
                  value={instructor?.dancer_name}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            {/*  */}
            <li>
              <span>ハッシュタグ</span>
              <label className="form">
                <input
                  type="text"
                  name="username"
                  value={instructor?.username}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            {/*  */}
            <li>
              <span>インスタグラム</span>
              <label className="form">
                <input
                  type="text"
                  name="instagram"
                  value={instructor?.instagram}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            {/*  */}
            <li>
              <span>プロフィール</span>
              <label className="form">
                <textarea
                  name="profile"
                  value={instructor?.profile}
                  onChange={handleInputChange}
                ></textarea>
              </label>
            </li>
            {/*  */}
            <li>
              <span>公開</span>
              {/* アクティブの場合.active */}
              <label className={`form switch${isActive ? " active" : ""}`}>
                <input
                  type="radio"
                  name="is_active"
                  value="true"
                  checked={isActive == true}
                  onClick={() => {
                    setIsActive(!isActive);
                    handleToggle();
                  }}
                />
                <input
                  type="radio"
                  name="is_active"
                  value="false"
                  checked={isActive == false}
                  onClick={() => {
                    setIsActive(!isActive);
                    handleToggle();
                  }}
                />
              </label>
            </li>
          </ul>

          <div className="tabArea" style={{ gridColumn: "span 6" }}>
            <ul className="tabBtn_type1">
              {tabs.map((tab) => (
                <li
                  key={tab.text}
                  className={selectedTab.text === tab.text ? "active" : ""}
                  onClick={() => setSelectedTab(tab)}
                >
                  {tab.text}
                </li>
              ))}
            </ul>

            {/* 画像 */}
            <div className="tab-content tab1">
              <p className="ttl">{selectedTab.text}</p>
              <DndProvider backend={HTML5Backend}>
                <ul className="imgList">
                  {selectedTab.type === "top" &&
                    newMultipleTopImages &&
                    newMultipleTopImages.map((image, index) => (
                      <Image
                        key={image.id}
                        id={image.id}
                        src={image.url}
                        index={index}
                        moveImage={moveImage}
                      />
                    ))}
                  {selectedTab.type === "multiple" &&
                    newMultipleImages &&
                    newMultipleImages.map((image, index) => (
                      <Image
                        key={image.id}
                        id={image.id}
                        src={image.url}
                        index={index}
                        moveImage={moveImage}
                      />
                    ))}
                </ul>
              </DndProvider>

              {/* アップロード */}
              <ul className="btnList">
                <li>
                  <button
                    className="type-2"
                    onClick={() =>
                      document.getElementById("uploadInput").click()
                    }
                  >
                    画像をアップロード
                  </button>
                  <input
                    id="uploadInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                    multiple={true}
                  />
                </li>
              </ul>
            </div>
          </div>

          {/* メッセージ */}
          {message && (
            <div className="message success">
              <p>{message}</p>
              <Link
                to={`https://rudofactory.jp/instructor?instructor=${instructor.username}`}
                target="_blank"
              >
                <button className="type-2">ページを見る</button>
              </Link>
            </div>
          )}
        </div>
      </>
    );
  }
);

function InstructorEditPage() {
  const { instructorId } = useParams();
  const [instructor, setInstructor] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [message, setMessage] = useState("");
  const [newMultipleTopImages, setNewMultipleTopImages] = useState([]);
  const [newMultipleImages, setNewMultipleImages] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [uploadTopImages, setUploadTopImages] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [layout, setLayout] = React.useState("2");
  const [isOpen, setIsOpen] = useState(0); // オーバーレイの表示/非表示
  const [overlayText, setOverlayText] = useState("loading..."); // オーバーレイの文言

  const breadcrumbs = [
    { title: "インストラクター一覧", path: `/website/instructor-list` },
    {
      title: "インストラクター詳細",
      path: `/website/instructor-edit/${instructor.id}`,
    },
  ];

  const tabs = [
    { text: "メイン画像", type: "top" },
    { text: "VISUAL", type: "multiple" },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  // インストラクターデータの取得
  const fetchInstructorData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_instructor/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          instructor_id: instructorId,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      setInstructor(data);
      setNewMultipleTopImages(data.top_images);
      setNewMultipleImages(data.images);
      setIsActive(data.is_active);
    } catch (error) {
      console.error("詳細の取得に失敗しました", error);
    }
  }, [instructorId]);

  useEffect(() => {
    fetchInstructorData();
  }, [fetchInstructorData]);

  // 入力フォーム変更
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") {
      // 半角英数字と半角アンダーバーのみを許可
      const sanitizedValue = value.replace(/[^a-zA-Z0-9_]/g, "");
      if (value !== sanitizedValue) {
        alert(
          "「ハッシュタグ」には半角英数字と半角アンダーバーしか使用できません。"
        );
      }
      setInstructor((prevInstructor) => ({
        ...prevInstructor,
        [name]: sanitizedValue,
      }));
    } else {
      setInstructor((prevInstructor) => ({
        ...prevInstructor,
        [name]: value,
      }));
    }
  };

  // インストラクター情報を更新
  const handleInstructorDataSubmit = async () => {
    const errMsg = [];
    if (!instructor.dancer_name) errMsg.push("ダンサーネーム");
    if (!instructor.username) errMsg.push("ハッシュタグ");
    if (errMsg.length !== 0) {
      alert(`${errMsg.join("、")}を入力してください。`);
      return;
    }
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const imageIds = newMultipleImages
        ? newMultipleImages.map((image) => image.id)
        : null;
      const topImageIds = newMultipleTopImages
        ? newMultipleTopImages.map((image) => image.id)
        : null;
      const response = await fetch(`${apiUrl}/api/update_instructor/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          instructor_id: instructorId,
          username: instructor.username,
          dancer_name: instructor.dancer_name,
          profile: instructor.profile,
          instagram: instructor.instagram,
          top_image_ids: topImageIds,
          image_ids: imageIds,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      setMessage(data.message);
      fetchInstructorData();
      setIsUploaded(true);
    } catch (error) {
      setMessage(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // 公開用トグル
  const handleToggle = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/update_is_active/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          instructor_id: instructorId,
          is_active: !isActive,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setIsActive(!isActive);
      console.log(data);
    } catch (error) {
      alert("更新に失敗しました");
      console.error("更新中にエラーが発生しました:", error);
    }
  };

  return (
    <>
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <div className="add">
          <button
            className="type-1 type-grad"
            onClick={handleInstructorDataSubmit}
          >
            この内容で更新
          </button>
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <InstructorEditContent
          instructor={instructor}
          handleInputChange={handleInputChange}
          isActive={isActive}
          setIsActive={setIsActive}
          handleToggle={handleToggle}
          message={message}
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          newMultipleTopImages={newMultipleTopImages}
          setNewMultipleTopImages={setNewMultipleTopImages}
          newMultipleImages={newMultipleImages}
          setNewMultipleImages={setNewMultipleImages}
          uploadTopImages={uploadTopImages}
          setUploadTopImages={setUploadTopImages}
          uploadImages={uploadImages}
          setUploadImages={setUploadImages}
          setIsUploaded={setIsUploaded}
          setIsOpen={setIsOpen}
        />
      </div>
      <div className={`content-grid grid-3 layout-type-${layout}`}>
        <LibraryWindow
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          newMultipleImages={newMultipleImages}
          setNewMultipleImages={setNewMultipleImages}
          newMultipleTopImages={newMultipleTopImages}
          setNewMultipleTopImages={setNewMultipleTopImages}
          isUploaded={isUploaded}
          setIsUploaded={setIsUploaded}
        />
      </div>
      <Overlay isOpen={isOpen} children={"アップロード中"} />
    </>
  );
}

export default InstructorEditPage;
