import React, { useState, useEffect, useCallback, memo } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import Breadcrumbs from "../../../components/Breadcrumbs";
import "../../../styles/scss/website/faqList.scss";

const FaqEditContent = memo(
  ({ categories, commonQuestion, handleInputChange, handleFaqDataSubmit }) => {
    return (
      <>
        <p className="ttl">新しいよくある質問を追加</p>
        {/* <p className="ttl">よくある質問を編集</p> */}
        <div className="List">
          <ul className="gridList faqEditList">
            <li>カテゴリー</li>
            <li className="category-area">
              <label className="form">
                <input
                  type="text"
                  name="category"
                  placeholder="カテゴリーを追加"
                  value={commonQuestion.category || ""}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li></li>
            <li className="category-area">
              {categories.map((category) => (
                <>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name="category_id"
                      value={category.id}
                      onChange={(e) => {
                        handleInputChange(e);
                        if (e.target.checked) {
                          categories.forEach((cat) => {
                            if (cat.id !== category.id) {
                              document.querySelector(
                                `input[name="category_id"][value="${cat.id}"]`
                              ).checked = false;
                            }
                          });
                        }
                      }}
                    />
                    <p>{category.text}</p>
                  </label>
                </>
              ))}
            </li>
            <li>質問</li>
            <li>
              <label className="form">
                <input
                  type="text"
                  name="question"
                  value={commonQuestion?.question || ""}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li>回答</li>
            <li>
              <label className="form">
                <input
                  type="text"
                  name="answer"
                  value={commonQuestion?.answer || ""}
                  onChange={handleInputChange}
                />
              </label>
            </li>
            <li className="submit">
              <button
                type="submit"
                className="submit type-3 type-3"
                onClick={handleFaqDataSubmit}
              >
                よくある質問を追加する
              </button>
            </li>
          </ul>
        </div>
      </>
    );
  }
);

function FaqListPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [commonQuestion, setCommonQuestion] = useState({});

  const breadcrumbs = [
    { title: "よくある質問一覧", path: `/website/faq-list` },
  ];

  const [layout, setLayout] = useState("4");

  const [categories, setCategories] = useState([]);
  const [commonQuestions, setCommonQuestions] = useState([]);

  // カテゴリーリストの取得
  const fetchCategories = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}/api/get_question_category_list/`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error("カテゴリーの取得に失敗しました", error);
    }
  }, []);

  // よくある質問カテゴリーの取得
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  // よくある質問リストの取得
  const fetchCommonQuestionsData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/get_common_question_list/`);
      if (!response.ok) {
        throw new Error("ネットワークレスポンスが異常です。");
      }
      const data = await response.json();
      setCommonQuestions(data);
    } catch (error) {
      console.error("データの取得中にエラーが発生しました:", error);
    }
  }, []);

  // よくある質問リストの取得
  useEffect(() => {
    fetchCommonQuestionsData();
  }, [fetchCommonQuestionsData]);

  // 入力フォーム変更
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox" && name === "category_id") {
      setCommonQuestion((prevCommonQuestion) => ({
        ...prevCommonQuestion,
        category: "", // category inputを空にする
        [name]: checked ? value : "",
      }));
    } else if (name === "category") {
      setCommonQuestion((prevCommonQuestion) => ({
        ...prevCommonQuestion,
        category_id: "", // category_idのチェックを全て外す
        [name]: value,
      }));
      // 全てのcategory_idチェックボックスのチェックを外す
      document
        .querySelectorAll('input[name="category_id"]')
        .forEach((checkbox) => {
          checkbox.checked = false;
        });
    } else {
      setCommonQuestion((prevCommonQuestion) => ({
        ...prevCommonQuestion,
        [name]: value,
      }));
    }
  };

  // よくある質問の追加
  const handleFaqDataSubmit = async () => {
    if (
      (!commonQuestion.category_id && !commonQuestion.category) ||
      !commonQuestion.question ||
      !commonQuestion.answer
    ) {
      alert("すべてのフィールドに値を入力してください。");
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/create_common_question/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          category: commonQuestion.category,
          category_id: commonQuestion.category_id,
          question: commonQuestion.question,
          answer: commonQuestion.answer,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }

      alert(data.message);
      setCommonQuestion({});
      fetchCategories();
      fetchCommonQuestionsData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // よくある質問の削除
  const handleFaqDataDelete = async (faq_id) => {
    const confirmation = window.confirm("本当に削除しますか？");
    if (!confirmation) {
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/delete_common_question/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          faq_id: faq_id,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`${data.message} status: ${response.status}`);
      }
      alert(data.message);
      fetchCommonQuestionsData();
    } catch (error) {
      alert(error);
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const FaqListContent = () => {
    return (
      <div className="faqListcontent">
        <div className="List">
          <div className="gridList">
            {commonQuestions.map((faq) => (
              <ul className="listContents" key={faq.id}>
                <Link to={`/website/faq-edit/${faq.id}`}>
                  <li>
                    <i>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </i>
                    <span>{faq.question}</span>
                  </li>
                </Link>
                <Link to={`/website/faq-edit/${faq.id}`}>
                  <li>
                    <span>{faq.category}</span>
                  </li>
                </Link>
                <li
                  className="delete"
                  onClick={() => {
                    handleFaqDataDelete(faq.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* 真ん中カラム */}
      <div className={`content-grid grid-2 layout-type-${layout} addButton`}>
        <div className="add">
          <button
            className="type-1"
            onClick={() => {
              setIsOpen(!isOpen);
              setLayout(isOpen ? "4" : "1");
            }}
          >
            よくある質問を追加
          </button>
        </div>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <FaqListContent />
      </div>
      <div
        className={`content-grid grid-3 layout-type-${layout}`}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <FaqEditContent
          categories={categories}
          commonQuestion={commonQuestion}
          handleInputChange={handleInputChange}
          handleFaqDataSubmit={handleFaqDataSubmit}
        />
      </div>
    </>
  );
}

export default FaqListPage;
